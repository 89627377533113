// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetDirectoryOptions } from '@b2x/storefront-api-js-client/src/directories';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useDirectoriesApi = () => {
  const { apiRequest } = useApiRequest();

  const getDirectory = React.useCallback(
    <T, S>(id: string, options?: GetDirectoryOptions, config?: ApiRequestConfig) =>
      apiRequest(api.directories.get<T, S>(id, options), { ...config }),
    [apiRequest]
  );

  const getDirectoryByCode = React.useCallback(
    <T, S>(code: string, options?: GetDirectoryOptions, config?: ApiRequestConfig) =>
      apiRequest(api.directories.getByCode<T, S>(code, options), { ...config }),
    [apiRequest]
  );

  return { getDirectory, getDirectoryByCode };
};
