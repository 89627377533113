import { b2x } from '@b2x/react/src';
import React from 'react';

import { AccountOffcanvasProps, useAccountOffcanvas } from './AccountOffcanvas';
import { CartOffcanvasProps, useCartOffcanvas } from './CartOffcanvas';

interface AppContextInterface {
  AccountOffcanvas: React.FunctionComponentElement<AccountOffcanvasProps>;
  CartOffcanvas: React.FunctionComponentElement<CartOffcanvasProps>;
  footerCopyrightRef: React.RefObject<HTMLDivElement>;
  headerCheckout: boolean;
  headerHeight?: number;
  isFooterCopyrightVisible: boolean;
  isTopBarRowVisible: boolean;
  minimalFooter: boolean;
  topBarDesktopHeight?: number;
  topBarMobileHeight?: number;
  topBarRowRef: React.RefObject<HTMLDivElement>;
}

export const [AppContextProvider, useAppContext] = b2x.createContext<AppContextInterface>('AppContext');

interface AppStaticContextInterface {
  setFooterCopyrightRef: React.RefCallback<HTMLDivElement>;
  setHeaderCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setMinimalFooter: React.Dispatch<React.SetStateAction<boolean>>;
  setTopBarDesktopHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTopBarMobileHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTopBarRowRef: React.RefCallback<HTMLDivElement>;
  showAccountOffcanvas(): void;
  showCartOffcanvas(): void;
}

export const [AppStaticContextProvider, useAppStaticContext] =
  b2x.createContext<AppStaticContextInterface>('AppStaticContext');

interface UseAppContextInitializerProps {}

const useAppContextInitializer = (props: UseAppContextInitializerProps) => {
  const [minimalFooter, setMinimalFooter] = React.useState<boolean>(false);
  const [headerCheckout, setHeaderCheckout] = React.useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = React.useState<number>();
  const [topBarDesktopHeight, setTopBarDesktopHeight] = React.useState<number>();
  const [topBarMobileHeight, setTopBarMobileHeight] = React.useState<number>();

  const [AccountOffcanvas, showAccountOffcanvas] = useAccountOffcanvas();
  const [CartOffcanvas, showCartOffcanvas] = useCartOffcanvas();

  const [footerCopyrightRef, setFooterCopyrightRef, isFooterCopyrightVisible] =
    b2x.useIntersectionObserver<HTMLDivElement>(true);
  const [topBarRowRef, setTopBarRowRef, isTopBarRowVisible] = b2x.useIntersectionObserver<HTMLDivElement>(true);

  const appContext: AppContextInterface = React.useMemo(
    () => ({
      AccountOffcanvas,
      CartOffcanvas,
      footerCopyrightRef,
      headerCheckout,
      headerHeight: headerHeight,
      isFooterCopyrightVisible,
      isTopBarRowVisible,
      minimalFooter,
      topBarDesktopHeight,
      topBarMobileHeight,
      topBarRowRef,
    }),
    [
      AccountOffcanvas,
      CartOffcanvas,
      footerCopyrightRef,
      headerCheckout,
      headerHeight,
      isFooterCopyrightVisible,
      isTopBarRowVisible,
      minimalFooter,
      topBarDesktopHeight,
      topBarMobileHeight,
      topBarRowRef,
    ]
  );

  const appStaticContext: AppStaticContextInterface = React.useMemo(
    () => ({
      setFooterCopyrightRef,
      setHeaderCheckout,
      setHeaderHeight,
      setMinimalFooter,
      setTopBarDesktopHeight,
      setTopBarMobileHeight,
      setTopBarRowRef,
      showAccountOffcanvas,
      showCartOffcanvas,
    }),
    [setFooterCopyrightRef, setTopBarRowRef, showAccountOffcanvas, showCartOffcanvas]
  );

  return {
    AppContextProvider,
    AppStaticContextProvider,
    appContext,
    appStaticContext,
  };
};

export interface AppContextProps extends UseAppContextInitializerProps {
  children:
    | React.ReactNode
    | ((appContext: AppContextInterface, appStaticContext: AppStaticContextInterface) => React.ReactNode);
}

export const AppContext = ({ children, ...otherProps }: AppContextProps) => {
  const appContextInitializer = useAppContextInitializer(otherProps);
  return (
    <appContextInitializer.AppContextProvider value={appContextInitializer.appContext}>
      <appContextInitializer.AppStaticContextProvider value={appContextInitializer.appStaticContext}>
        {typeof children === 'function'
          ? children(appContextInitializer.appContext, appContextInitializer.appStaticContext)
          : children}
      </appContextInitializer.AppStaticContextProvider>
    </appContextInitializer.AppContextProvider>
  );
};
