import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { useAppContext } from './AppContext';
import { Button } from './Button';
import { Col } from './Col';
import { Div, H3, H4, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { ProductReviewsA } from './ProductReviewsA';
import { RatingDistribution } from './reviews/RatingDistribution';
import { RatingStars } from './reviews/RatingStars';
import { ReviewItemList } from './reviews/ReviewItemList';
import { ReviewsActions } from './reviews/ReviewsActions';
import { ReviewsFilters } from './reviews/ReviewsFilter';
import { Row } from './Row';
import { useProductReviews } from './useProductReviews ';
import { percentageOf } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ProductReviewsProps {
  onSignInButtonClick(): void;
  product: ProductApiDto;
  ratingPrecision?: number;
  reviewPerPage?: number;
}

const ProductReviews = ({
  onSignInButtonClick,
  product,
  ratingPrecision = 2,
  reviewPerPage = 3,
}: ProductReviewsProps) => {
  const {
    onChangeFilter,
    onShowMoreButtonClick,
    onSignUpButtonClick,
    onWriteReviewButtonClick,
    reviews,
    showMore,
    summaryReviews,
  } = useProductReviews(product, reviewPerPage);

  const { session } = useAppContext();

  return (
    <Div className="reviews">
      {product.reviews && product.reviews.length > 0 ? (
        <Row style={{ justifyContent: 'space-between' }}>
          {summaryReviews && (
            <Col size={{ lg: 4, md: 5, xs: 12 }}>
              <Div className="summary" marginBottom={{ lg: 0, xs: 3 }}>
                <h3 className="title">
                  <Span marginEnd={2}>{t('reviews.titles.withReviwes')}</Span>
                  <small>({summaryReviews.totCount})</small>
                </h3>
                <Div alignItems={'center'} display={'flex'} marginBottom={3}>
                  <Div alignItems={'center'} display={'flex'} marginEnd={2}>
                    <RatingStars rating={percentageOf(summaryReviews.average, 5, ratingPrecision)} />
                  </Div>
                  <Div className="average">
                    <H4 dynamicMargin={{ bottom: 0 }}>{summaryReviews.average.toFixed(ratingPrecision)}</H4>
                  </Div>
                </Div>
                {summaryReviews.voteDistribution && (
                  <Div marginBottom={4}>
                    <RatingDistribution
                      hiddenCount
                      hiddenPercentage
                      ratingCount={summaryReviews.totCount}
                      ratingDistribution={summaryReviews.voteDistribution}
                      variant="star"
                    />
                  </Div>
                )}
                <div className="summary-footer">
                  <H3>{t('reviews.misc.writeReview')}</H3>
                  <p style={{ marginBottom: 0 }}>{t('reviews.messages.withReviwes')}</p>
                  <p style={{ marginBottom: 0 }}>
                    {session?.userLogged ? t('reviews.messages.customer') : t('reviews.messages.guest')}
                  </p>
                  <ReviewsActions
                    onSignInButtonClick={onSignInButtonClick}
                    onSignUpButtonClick={onSignUpButtonClick}
                    onWriteReviewButtonClick={onWriteReviewButtonClick}
                  />
                </div>
              </Div>
            </Col>
          )}
          <Col size={{ md: 7, xs: 12 }}>
            <Div>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col size={{ lg: 4, sm: 6, xs: 12 }}>
                  <ReviewsFilters onChangeFilter={onChangeFilter} />
                </Col>
              </Row>
            </Div>
            <Div marginBottom={4}>
              <ReviewItemList ratingPrecision={ratingPrecision} reviews={reviews} />
              {showMore && (
                <Button
                  label={t('reviews.buttons.showMore')}
                  onClick={onShowMoreButtonClick}
                  variant="outline-primary"
                />
              )}
            </Div>
          </Col>
        </Row>
      ) : (
        <Div textAlign={'center'}>
          <H3 className="title" marginBottom={3}>
            {t('reviews.titles.withoutReviews')}
          </H3>
          <p style={{ marginBottom: 0 }}>{t('reviews.messages.withoutReviews')}</p>
          <p style={{ marginBottom: 0 }}>
            {session?.userLogged ? t('reviews.messages.customer') : t('reviews.messages.guest')}
          </p>
          <ReviewsActions
            onSignInButtonClick={onSignInButtonClick}
            onSignUpButtonClick={onSignUpButtonClick}
            onWriteReviewButtonClick={onWriteReviewButtonClick}
          />
        </Div>
      )}
    </Div>
  );
};

export type ProductReviewsVariants = 'A';

const ProductReviewsController = (props: PropsWithCustomComponent<ProductReviewsProps>) => (
  <VariantsController<ProductReviewsProps, ProductReviewsVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ProductReviewsA },
      defaultComponent: ProductReviews,
      name: 'ProductReviews',
    }}
  />
);
export { ProductReviewsController as ProductReviews };
