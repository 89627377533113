import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { FooterIcons } from './FooterIcons';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const windowSize = b2x.useWindowSize();
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState<string>(b2x.getCurrentBreakpoint());

  React.useEffect(() => {
    setCurrentBreakpoint(b2x.getCurrentBreakpoint());
  }, [windowSize, setCurrentBreakpoint]);

  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');

  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerMenuSecondRow = b2x.useMenu('MENU_BOTTOM_FOOTER');

  const { minimalFooter } = useAppContext();
  const { setFooterCopyrightRef } = useAppStaticContext();

  return (
    <footer className="footer">
      {!minimalFooter && (
        <>
          <div className="pre-footer-row bg-secondary py-5 py-lg-4 text-white text-center">
            <FooterIcons />
          </div>
          <div className="bg-gray-100">
            <Container>
              <div className="footer-first-row pt-3 pt-lg-5">
                <b2x.Row>
                  <b2x.Col className="footer-newsletter order-lg-2" size={{ lg: 4, xs: 12 }}>
                    <div className="mb-3">
                      <div>
                        <h6
                          className={classnames('fw-bold mb-3 extra-small fw-bold text-uppercase', {
                            h4: 'xs sm md'.includes(currentBreakpoint),
                          })}
                        >
                          {t('misc.newsletterTitle')}
                        </h6>
                        <p className="extra-small">{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</p>
                      </div>
                      <NewsletterForm
                        className="mb-4"
                        ctaContainerClassName="text-start text-lg-end"
                        emailFieldStyle="inputGroup"
                        source="website-footer"
                      />
                    </div>
                  </b2x.Col>
                  <b2x.Col size={{ lg: 8, xs: 12 }}>
                    <AccordionFooterMenu id="first-footer-accordion" menu={footerMenu} />
                    {footerMenuSecondRow && (
                      <div className="footer-second-row pt-lg-3">
                        <b2x.Row>
                          <b2x.Col size={{ lg: 9, xs: 12 }}>
                            <AccordionFooterMenu
                              accordionColClassName="col-lg-4"
                              id="second-footer-accordion"
                              menu={footerMenuSecondRow}
                            />
                          </b2x.Col>
                          {footerContent?.body.greenNumber && (
                            <b2x.Col className="order-lg-first" size={{ lg: 3, xs: 12 }}>
                              <div className="pt-lg-0 pt-4">
                                <h3 className="extra-small fw-bold text-uppercase mb-lg-1">{t('misc.greenNumber')}</h3>
                                <b2x.router.Link
                                  className="text-dark small text-reset text-decoration-none d-block py-1 accordion-sublink"
                                  to={`tel:+39${footerContent.body.greenNumber.replace(/\s+/g, '')}`}
                                >
                                  {footerContent.body.greenNumber}
                                </b2x.router.Link>
                              </div>
                            </b2x.Col>
                          )}
                        </b2x.Row>
                      </div>
                    )}
                  </b2x.Col>
                </b2x.Row>
              </div>

              <div className="footer-fourth-row pt-lg-4 pb-lg-3 d-flex justify-content-between flex-lg-row flex-column">
                <div className="footer-social py-3 p-lg-0 d-flex align-items-center">
                  <h6 className="fw-bold me-3 mb-0 text-uppercase extra-small">{t('misc.payments')}</h6>
                  <div className="d-flex">
                    <b2x.PaymentIcons />
                  </div>
                </div>
                <div className="footer-social py-3 p-lg-0 d-flex align-items-center">
                  <h6 className="fw-bold me-3 mb-0 text-uppercase extra-small">{t('misc.followUs')}</h6>
                  <div className="d-flex">
                    <SocialIcons />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}
      <div className="bg-secondary text-white">
        <Container>
          <div className="footer-third-row extra-small py-3" ref={setFooterCopyrightRef}>
            {footerContent && <p className="extra-small mb-0">{b2x.formatHtml(footerContent.body.copyright)}</p>}
          </div>
        </Container>
      </div>
    </footer>
  );
};

interface AccordionFooterMenuProps {
  accordionColClassName?: string;
  id: string;
  menu?: b2x.MenuApiDto;
}
const AccordionFooterMenu = ({ accordionColClassName, id, menu }: AccordionFooterMenuProps) => {
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();
  return (
    <b2x.Accordion
      accordionColClassName={accordionColClassName}
      columnLayoutFrom="lg"
      id={id}
      itemBodyClassName="small pb-3 p-0"
      itemButtonClassName="bg-transparent fw-bold mb-lg-1 py-lg-0 py-3 p-0 extra-small"
      itemClassName="bg-transparent"
    >
      {menu?.children.map(
        (column) =>
          column.label && (
            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.code} title={column.label}>
              {column.children.map((li) =>
                li.code === 'SITE_CCN_AREA' ? (
                  session?.userLogged ? (
                    li.children.map(
                      (subLi) =>
                        subLi.link && (
                          <div key={subLi.code}>
                            <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={subLi.link}>
                              {subLi.label}
                            </b2x.router.Link>
                          </div>
                        )
                    )
                  ) : (
                    <div key={li.code}>
                      <b2x.BlankButton className="d-block py-1" onClick={showAccountOffcanvas}>
                        {t('account.login')}
                      </b2x.BlankButton>
                    </div>
                  )
                ) : (
                  li.link && (
                    <div key={li.code}>
                      {li.image ? (
                        <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                          <b2x.Image {...li.image} fluid width={100} />
                        </b2x.router.Link>
                      ) : (
                        <b2x.router.Link
                          className="text-reset accordion-sublink text-decoration-none d-block py-1"
                          to={li.link}
                        >
                          {li.label}
                        </b2x.router.Link>
                      )}
                    </div>
                  )
                )
              )}
            </b2x.AccordionItem>
          )
      )}
    </b2x.Accordion>
  );
};
