import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { RegistrationFormContentType } from '../contentTypes';
import { CustomerVetForm } from '../forms/CustomerVetForm';
import { Page } from './Page';

export interface RegistrationVetPageProps {}

export const RegistrationVetPage = (props: RegistrationVetPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_VET_FORM_CONTENT');

  return (
    <Page thingsToLoadBeforePageReady={[content]}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <Box>
              <div className="mb-4 text-center">
                <h1 className="h2 fw-bold mb-2">{b2x.formatHtml(content?.body.title)}</h1>
                <p className="mb-0">{b2x.formatHtml(content?.body.subTitle)}</p>
              </div>
              <CustomerVetForm
                minAge={18}
                successfulRegistrationModalProps={{
                  children: b2x.formatHtml(content?.body.modal?.content),
                  title: content?.body.modal?.title,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
