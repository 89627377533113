import { t } from '../i18n/i18n';
import { ProgressBar } from '../ProgressBar';
import { percentageOf } from '../util';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { RatingStar } from './RatingStar';

export interface RatingDistributionProps {
  compactInfo?: boolean;
  hiddenCount?: boolean;
  hiddenPercentage?: boolean;
  progressBarHeight?: number;
  ratingCount: number;
  ratingDistribution: Record<number, number | undefined>;
  variant?: 'text' | 'star';
}

const RatingDistribution = ({
  hiddenCount,
  hiddenPercentage,
  progressBarHeight,
  ratingCount,
  ratingDistribution,
  variant = 'text',
}: RatingDistributionProps) => {
  return (
    <table className="rating-distribution" width={'100%'}>
      <tbody>
        {Object.entries(ratingDistribution)
          .reverse()
          .map(([star, count]) => (
            <tr key={star}>
              <td className="rating-value" style={{ paddingRight: '0.25rem' }}>
                <small>{star}</small>
              </td>
              <td className="rating-title" style={{ paddingRight: '0.25rem' }}>
                {variant === 'text' ? <small>{t('reviews.misc.star', { count: Number(star) })}</small> : <RatingStar />}
              </td>
              <td className="rating-progress-bar" style={{ width: '100%' }}>
                <ProgressBar
                  current={count ? count : 0}
                  height={progressBarHeight}
                  hiddenCount={hiddenCount}
                  max={ratingCount}
                  min={0}
                />
              </td>
              {!hiddenPercentage && (
                <td className="rating-percentage" style={{ paddingLeft: '0.25rem' }}>
                  <small>{`${percentageOf(count ? count : 0, ratingCount, 2)}%`}</small>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export type RatingDistributionVariants = '';

const RatingDistributionController = (props: PropsWithCustomComponent<RatingDistributionProps>) => (
  <VariantsController<RatingDistributionProps, RatingDistributionVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: RatingDistribution,
      name: 'RatingDistribution',
    }}
  />
);
export { RatingDistributionController as RatingDistribution };
