import { Div } from '../HTMLElement';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { RatingStar } from './RatingStar';

export interface RatingStarsProps {
  color?: string;
  rating: number;
  size?: number;
}

const RatingStars = ({ color = '#000000', rating, size = 16 }: RatingStarsProps) => {
  const starMargin = 3;

  return (
    <Div className="rating-stars">
      <Div display={'inline-block'} style={{ position: 'relative' }}>
        <Div className="stars-empty" style={{ display: 'flex' }}>
          {[...Array(5)].map((e, i) => (
            <div // eslint-disable-next-line react/no-array-index-key
              key={`empty-star-${i}`}
              style={{ marginLeft: i > 0 ? starMargin : undefined }}
            >
              <RatingStar color={color} empty size={size} />
            </div>
          ))}
        </Div>
        <Div
          className="stars"
          style={{ display: 'flex', left: 0, overflow: 'hidden', position: 'absolute', top: 0, width: `${rating}%` }}
        >
          {[...Array(5)].map((e, i) => (
            <div // eslint-disable-next-line react/no-array-index-key
              key={`star-${i}`}
              style={{ marginLeft: i > 0 ? starMargin : undefined }}
            >
              <RatingStar color={color} size={size} />
            </div>
          ))}
        </Div>
      </Div>
    </Div>
  );
};

export type RatingStarsVariants = '';

const RatingStarsController = (props: PropsWithCustomComponent<RatingStarsProps>) => (
  <VariantsController<RatingStarsProps, RatingStarsVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: RatingStars,
      name: 'RatingStars',
    }}
  />
);
export { RatingStarsController as RatingStars };
