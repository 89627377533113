import { ProductApiDto, ReviewApiDto, SummaryReviewsApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useReviewsApi } from './api/useReviewsApi';
import { useAppStaticContext } from './AppContext';
import { useReviewFormModal } from './form/ReviewForm';
import { useNavigate } from './router/router';

export interface UseProductReviewsProps {
  pageSize?: number;
  product: ProductApiDto;
}

export const useProductReviews = (product: ProductApiDto, pageSize = 10) => {
  const { getSummaryReviews, searchReviews } = useReviewsApi();

  const [page, setPage] = React.useState<number>(1); // Numero della pagina corrente (paginazione)
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const [ratingValue, setRatingValue] = React.useState<number | undefined>(); // Filtraggio
  const [reviews, setReviews] = React.useState<Array<ReviewApiDto>>([]); // Array delle review
  const [summaryReviews, setSummaryReviews] = React.useState<SummaryReviewsApiDto | undefined>(product.summaryReviews); // Array delle review

  const navigate = useNavigate();
  const { getFixedPagePath } = useAppStaticContext();
  const showReviewFormModal = useReviewFormModal();

  // useEffect: resetto pagina e review al cambio prodotto
  React.useEffect(() => {
    setReviews([]);
    setPage(1);
  }, [product]);

  // Chiamata alle reviews
  React.useEffect(() => {
    let ignore = false;

    searchReviews({
      ...{
        id: product.id,
        orderBy: 'CREATION_DATE',
        orderingType: 'DESC',
        pageNum: page,
        pageSize: pageSize,
        ratingType: 'FIVE_STARS',
        ratingValue: ratingValue,
        type: 'PRODUCT',
      },
    }).then((response) => {
      if (!ignore) {
        setReviews((prevState) => [...prevState, ...(response.data.items ? response.data.items : [])]);
        setShowMore(response.data.itemsCount > 0 && response.data.pageNumber !== response.data.pagesCount);
      }
    });

    getSummaryReviews(product.id).then((response) => {
      if (!ignore) {
        setSummaryReviews(response.data);
      }
    });

    return () => {
      ignore = true;
    };
  }, [getSummaryReviews, page, pageSize, product.id, ratingValue, searchReviews]);

  // useCallback: svuoto le review, reimposto la pagina a 1 e imposto eventuali ratingValue
  const onChangeFilter = React.useCallback((rating?: number) => {
    setReviews([]);
    setPage(1);
    rating ? setRatingValue(rating) : setRatingValue(undefined);
  }, []);

  // useCallback: setto la nuova pagina
  const onShowMoreButtonClick = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const onSignUpButtonClick = React.useCallback(() => {
    navigate(getFixedPagePath('register'));
  }, [getFixedPagePath, navigate]);

  // useCallBack: mostro il ReviewFormModal al click del bottone per scrivere la recensione
  const onWriteReviewButtonClick = React.useCallback(() => {
    showReviewFormModal({ product: product });
  }, [product, showReviewFormModal]);

  return {
    onChangeFilter,
    onShowMoreButtonClick,
    onSignUpButtonClick,
    onWriteReviewButtonClick,
    reviews,
    showMore,
    summaryReviews,
  };
};
