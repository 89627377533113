import { CustomerApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { useSessionApi } from './api/useSessionApi';
import { useSearchParams } from './router/useSearchParams';
import { useStable } from './util';

export interface UseCustomerRequiredFieldsUpdaterOptions {
  formsMapping(result: Omit<UseCustomerRequiredFieldsUpdaterResult, 'form'>): Record<string, React.ReactElement>;
}

export interface UseCustomerRequiredFieldsUpdaterResult {
  checkFailedCodes: Array<string>;
  customer?: CustomerApiDto;
  form: React.ReactElement | undefined;
  token: string;
}

export const useCustomerRequiredFieldsUpdater = (
  options: UseCustomerRequiredFieldsUpdaterOptions
): UseCustomerRequiredFieldsUpdaterResult => {
  const [customer, setCustomer] = React.useState<CustomerApiDto>();

  const { getCustomerByToken } = useSessionApi();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token') ?? undefined;
  const checkFailedCodes = searchParams.getAll('checkFailedCodes');

  const { formsMapping } = useStable(options);

  if (token === undefined) {
    throw new Error('useCustomerRequiredFieldsUpdater - Missing token');
  }

  if (checkFailedCodes.length === 0) {
    throw new Error('useCustomerRequiredFieldsUpdater - Missing checkFailedCodes');
  }

  // const decodedToken = decodeURIComponent(token);
  // console.log('token', token);
  // console.log('decodedToken', decodedToken);

  React.useEffect(() => {
    // Alla getCustomerByToken mando il decodedToken in quanto poi apiRequest gli fa nuovamente l'encode.
    getCustomerByToken(token, { populate: { children: { additionalProperties: true } } }).then((response) => {
      setCustomer(response.data);
    });
  }, [getCustomerByToken, token]);

  const form = React.useMemo<React.ReactElement>(() => {
    // itero i checkFailedCodes e prendo il primo che è stato mappato dal chiamante tramite formsMapping.
    const formsMappingObject = formsMapping({ checkFailedCodes, customer, token });
    const mappedCode = checkFailedCodes.find((checkFailedCode) =>
      Object.keys(formsMappingObject).includes(checkFailedCode)
    );
    // if (mappedCode) {
    //   return formsMappingObject[mappedCode];
    // } else {
    //   return <CustomerForm customer={customer} updateRequiredFields={{ checkCodes: checkFailedCodes, token: token }} />;
    // }
    if (!mappedCode) {
      throw new Error(`useCustomerRequiredFieldsUpdater - No form found for keys: ${checkFailedCodes.toString()}`);
    }
    return formsMappingObject[mappedCode];
  }, [checkFailedCodes, customer, formsMapping, token]);

  return { checkFailedCodes, customer, form, token };
};
