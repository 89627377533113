import { b2x } from '@b2x/react/src';
import React from 'react';

import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { VetOccupationOptions } from './CustomOptions';
import {
  additionalPropertiesChildren,
  AdditionalPropertiesChildrenFormValues,
  AdditionalPropertiesChildrenValidationSchema,
  childrenValidationSchemaSelector,
  CustomPropertiesChildrenFormValues,
  CustomPropertiesChildrenValidationSchema,
  RegistrationChildrenFieldset,
} from './RegistrationChildrenFieldset';

type AdditionalPropertiesFormValues = {
  EXTERNAL_VET_ID: b2x.formikString;
  OCCUPATION_VET: b2x.formikString;
  PROVINCE_VET: b2x.formikString;
};

type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{
  EXTERNAL_VET_ID: b2x.yup.StringSchema;
  OCCUPATION_VET: b2x.yup.StringSchema;
  PROVINCE_VET: b2x.yup.StringSchema;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

export type CustomerVetFormProps = Omit<
  b2x.CustomerFormProps<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    AdditionalPropertiesChildrenFormValues,
    AdditionalPropertiesChildrenValidationSchema,
    CustomPropertiesChildrenFormValues,
    CustomPropertiesChildrenValidationSchema
  >,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'customPropertiesInitialValues'
  | 'customPropertiesValidationSchema'
  | 'validationSchemaSelector'
  | 'additionalPropertiesChildrenInitialValues'
  | 'additionalPropertiesChildren'
  | 'additionalPropertiesChildrenValidationSchema'
  | 'customPropertiesChildrenInitialValues'
  | 'customPropertiesChildrenValidationSchema'
  | 'validationSchemaChildrenSelector'
>;

export const CustomerVetForm = (props: CustomerVetFormProps) => {
  const misceContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  const { getCountryProvinces } = b2x.useInfoApi();
  const [provinces, setProvinces] = React.useState<Array<b2x.ProvinceApiDto>>();

  const { session } = b2x.useAppContext();

  React.useEffect(() => {
    getCountryProvinces('IT').then((response) => {
      setProvinces(response.data);
    });
  }, [getCountryProvinces]);

  return (
    <b2x.CustomerFormHelper<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >
      {...props}
      additionalProperties={{
        formValues: {
          EXTERNAL_VET_ID: (values) => values.additionalProperties?.EXTERNAL_VET_ID ?? '',
          OCCUPATION_VET: (values) => values.additionalProperties?.OCCUPATION_VET ?? '',
          PROVINCE_VET: (values) => values.additionalProperties?.PROVINCE_VET ?? '',
        },
        initialValues: {
          EXTERNAL_VET_ID: b2x.getInitialString(),
          OCCUPATION_VET: b2x.getInitialString(),
          PROVINCE_VET: b2x.getInitialString(),
        },
        validationSchema: b2x.yup.object({
          EXTERNAL_VET_ID: b2x.yup.string().required(),
          OCCUPATION_VET: b2x.yup.string().required(),
          PROVINCE_VET: b2x.yup.string().required(),
        }),
      }}
      additionalPropertiesChildren={additionalPropertiesChildren}
      initialValues={{
        enableBusinessData: session?.customer ? (session.customer.vatNumber ? 'true' : 'false') : 'true',
        profile: 'BUSINESS',
      }}
      maxChildren={10}
      minChildren={1}
      validationSchemaSelector={{
        birthdate: true,
        children: childrenValidationSchemaSelector,
        name: true,
        phoneNumber: false,
        profile: true,
        sex: false,
        surname: true,
      }}
    >
      {({ content, fieldsHelper, formik, mode }) => (
        <>
          <div className="mb-3 pb-5 border-bottom">
            <b2x.Row gap={3}>
              <b2x.Col size={6}>
                <b2x.FormGroup {...fieldsHelper.name.formGroup} noMarginBottom>
                  <b2x.TextInput {...fieldsHelper.name.textInput} />
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.FormGroup {...fieldsHelper.surname.formGroup} noMarginBottom>
                  <b2x.TextInput {...fieldsHelper.surname.textInput} />
                </b2x.FormGroup>
              </b2x.Col>
              {mode === 'registration' && (
                <>
                  <b2x.Col size={6}>
                    <b2x.FormGroup {...fieldsHelper.email.formGroup} noMarginBottom>
                      <b2x.TextInput {...fieldsHelper.email.textInput} />
                    </b2x.FormGroup>
                  </b2x.Col>
                  <b2x.Col size={6}>
                    <b2x.FormGroup {...fieldsHelper.password.formGroup} noMarginBottom>
                      <b2x.PasswordInput {...fieldsHelper.password.passwordInput} />
                    </b2x.FormGroup>
                  </b2x.Col>
                </>
              )}
              <b2x.Col size={6}>
                <b2x.FormGroup {...fieldsHelper.birthdate.formGroup} noMarginBottom>
                  <b2x.DateInput {...fieldsHelper.birthdate.dateInput} />
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.FormGroup
                  label={t('misc.vet.province.label')}
                  names={['additionalProperties.PROVINCE_VET']}
                  noMarginBottom
                >
                  <b2x.Select includeEmptyOption={false} name="additionalProperties.PROVINCE_VET">
                    {provinces?.map((province, index) => (
                      <b2x.Option
                        key={province.code}
                        label={index === 0 ? t('misc.vet.province.placeholder') : province.name}
                        value={province.code}
                      />
                    ))}
                  </b2x.Select>
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.FormGroup
                  label={t('misc.vet.externalId.label')}
                  names={['additionalProperties.EXTERNAL_VET_ID']}
                  noMarginBottom
                >
                  <b2x.TextInput
                    name="additionalProperties.EXTERNAL_VET_ID"
                    placeholder={t('misc.vet.externalId.placeholder')}
                  />
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.FormGroup
                  label={t('misc.vet.occupation.label')}
                  names={['additionalProperties.OCCUPATION_VET']}
                  noMarginBottom
                >
                  <b2x.Select includeEmptyOption={false} name="additionalProperties.OCCUPATION_VET">
                    <VetOccupationOptions />
                  </b2x.Select>
                </b2x.FormGroup>
              </b2x.Col>
            </b2x.Row>
          </div>
          <div className="mb-5 pb-3 border-bottom">
            <b2x.FormGroup
              className="small"
              labelClassName="mb-2"
              noMarginBottom
              {...fieldsHelper.enableBusinessData.formGroup}
            >
              <b2x.Radios {...fieldsHelper.enableBusinessData.radios} />
            </b2x.FormGroup>
          </div>
          {formik.values.enableBusinessData === 'true' && (
            <div className="mb-3 pb-5 border-bottom">
              <b2x.Row gap={3}>
                <b2x.Col size={6}>
                  <b2x.FormGroup
                    className="small"
                    {...fieldsHelper.businessType.formGroup}
                    labelClassName="mb-2"
                    noMarginBottom
                  >
                    <b2x.Radios {...fieldsHelper.businessType.radios} />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={6}>
                  <b2x.FormGroup {...fieldsHelper.company.formGroup} noMarginBottom>
                    <b2x.TextInput {...fieldsHelper.company.textInput} />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={6}>
                  <b2x.FormGroup {...fieldsHelper.vatNumber.formGroup} noMarginBottom>
                    <b2x.TextInput {...fieldsHelper.vatNumber.textInput} />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={6}>
                  <b2x.FormGroup {...fieldsHelper.taxCode.formGroup} noMarginBottom>
                    <b2x.TextInput {...fieldsHelper.taxCode.textInput} />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={6}>
                  <b2x.FormGroup {...fieldsHelper.uniqueCode.formGroup} noMarginBottom>
                    <b2x.TextInput {...fieldsHelper.uniqueCode.textInput} />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={6}>
                  <b2x.FormGroup {...fieldsHelper.pec.formGroup} noMarginBottom>
                    <b2x.TextInput {...fieldsHelper.pec.textInput} />
                  </b2x.FormGroup>
                </b2x.Col>
              </b2x.Row>
            </div>
          )}
          <RegistrationChildrenFieldset fieldsHelper={fieldsHelper} formikValues={formik.values} />
          {(mode === 'registration' || mode === 'updateRequiredFields') && (
            <b2x.Row className="mb-3">
              {content?.body.newsletter?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.newsletterConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.newsletterConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              {content?.body.profiling?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.profilingConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.profilingConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              {content?.body.marketing?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.marketingConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.marketingConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              <b2x.Col size={12}>
                <b2x.FormGroup
                  {...fieldsHelper.privacyConsent.formGroup}
                  // label={t('form.registrationForm.privacyConsent.label')}
                  noMarginBottom
                >
                  {misceContent?.body.privacyBoxArea && (
                    <div className="privacy-box pt-3 my-3">
                      <p className="px-3 py-1 fw-light extra-small">
                        {b2x.formatHtml(misceContent.body.privacyBoxArea)}
                      </p>
                    </div>
                  )}
                  <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.privacyConsent.checkbox} />
                </b2x.FormGroup>
              </b2x.Col>
            </b2x.Row>
          )}
          {fieldsHelper.buttons.cancel ? (
            <b2x.Row>
              <b2x.Col size={6}>
                <b2x.Div display={'grid'}>
                  <b2x.Button {...fieldsHelper.buttons.cancel} />
                </b2x.Div>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.Div display={'grid'}>
                  <b2x.Button {...fieldsHelper.buttons.submit} />
                </b2x.Div>
              </b2x.Col>
            </b2x.Row>
          ) : (
            <b2x.Div display={'grid'}>
              {Object.keys(formik.errors).length > 0 && (
                <p className="small text-danger">{t('misc.invalid_form_values')}</p>
              )}
              <b2x.Button {...fieldsHelper.buttons.submit} />
            </b2x.Div>
          )}
        </>
      )}
    </b2x.CustomerFormHelper>
  );
};
