import './PopupNewsletterModal.scss';

import { b2x } from '@b2x/react/src';

import { NewsletterContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';

interface PopUpNewsletterModalProps {}

export const PopUpNewsletterModal = (props: PopUpNewsletterModalProps) => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_CONTENT');
  return (
    <div className="popup-newsletter-modal">
      <div className="text-center mb-4">
        <h4 className="fw-bold">{b2x.formatHtml(content?.body.title)}</h4>
        <span>{b2x.formatHtml(content?.body.subTitle)}</span>
      </div>
      <NewsletterForm emailFieldStyle="textInput" source="website-modal" />
    </div>
  );
};
