import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Container } from './Container';
import { ReferralBannerContentType } from './contentTypes';

interface ReferralBannerProps extends ReferralBannerContentType {}

export const ReferralBanner = ({ alignment, img, subTitle, title }: ReferralBannerProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);
  return (
    <div className="referral-banner position-relative">
      {img && (
        <div className="media-container text-center">
          <b2x.ImageFromContentV2 {...img} className="order-1" fluid />
        </div>
      )}
      <div
        className={classNames(
          'text-container',
          { 'position-absolute top-0 start-0': !isMobile },
          'd-flex align-items-center w-100 h-100 py-3 py-lg-5'
        )}
      >
        <Container>
          <b2x.Row
            className={classNames(
              !isMobile && alignment === 'left' && 'justify-content-start',
              !isMobile && alignment === 'center' && 'justify-content-center',
              !isMobile && alignment === 'right' && 'justify-content-end',
              isMobile && 'justify-content-center'
            )}
          >
            <b2x.Col size={{ lg: 8, xs: 12 }}>
              <div
                className={classNames(
                  !isMobile && alignment === 'left' && 'text-start',
                  !isMobile && alignment === 'center' && 'text-center',
                  !isMobile && alignment === 'right' && 'text-end',
                  isMobile && 'text-center'
                )}
              >
                {title && (
                  <h1
                    className={classNames(
                      {
                        h2: isMobile,
                        'text-white display-2': !isMobile,
                      },
                      'fw-bold'
                    )}
                  >
                    {b2x.formatHtml(title)}
                  </h1>
                )}
                {subTitle && (
                  <p className={classNames('mt-1', { 'text-white h1': !isMobile }, { h5: isMobile })}>
                    {b2x.formatHtml(subTitle)}
                  </p>
                )}
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
    </div>
  );
};
