import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface UpdateCustomerRequiredFieldsPageProps {}

export const UpdateCustomerRequiredFieldsPage = (props: UpdateCustomerRequiredFieldsPageProps) => {
  const { customer, form } = b2x.useCustomerRequiredFieldsUpdater({
    formsMapping: (result) => ({
      CHECK_LOGIN_GIGYA: (
        <b2x.CustomerForm
          customer={result.customer}
          updateRequiredFields={{ checkCodes: ['CHECK_LOGIN_GIGYA'], token: result.token }}
        />
      ),
    }),
  });

  return (
    <Page>
      {customer && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <Box>
                <div className="mb-4 text-center">
                  <h1 className="h2 fw-bold mb-2">
                    {customer.name
                      ? t('updateCustomerRequiredFieldsPage.titleWithName', { name: customer.name })
                      : t('updateCustomerRequiredFieldsPage.title')}
                  </h1>
                  <p className="mb-0">{t('updateCustomerRequiredFieldsPage.subtitle')}</p>
                </div>
                <div className="mb-4">{form}</div>
              </Box>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};
