import './LandingTemplateAPage.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { CardsListSection } from '../CardsListSection';
import { Container } from '../Container';
import { LandingTemplateAPageContentType } from '../contentTypes';
import { Page } from './Page';

export const LandingTemplateAPage = () => {
  const page = b2x.usePage<LandingTemplateAPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const body = page?.content?.body;

  return (
    <Page className="landing-template-a-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="d-grid">
        {body?.banner?.img && (
          <div
            className={classNames(`h-100 w-100 bg-${body.banner.backgroundColor}`, {
              [`border-bottom border-${body.banner.border?.color} border-${body.banner.border?.size}`]:
                body.banner.border?.color && body.banner.border.size,
            })}
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <b2x.ImageFromContentV1 {...body.banner.img} className="w-100" fluid />
          </div>
        )}
        <div
          className="g-row-0 d-flex align-items-center justify-content-center text-center flex-column w-100 py-5"
          style={{ background: body?.banner?.backgroundLayer, gridColumn: 1, gridRow: 1, minHeight: '100%' }}
        >
          {body?.banner?.title && (
            <h1 className="text-white fw-bold display-2 mb-0">{b2x.formatHtml(body.banner.title)}</h1>
          )}
          {body?.banner?.subTitle && (
            <b2x.Col size={{ md: 6, xs: 10 }}>
              <h5 className={`text-white fw-normal h${body.banner.subTitleSize} mt-3`}>
                {b2x.formatHtml(body.banner.subTitle)}
              </h5>
            </b2x.Col>
          )}
        </div>
      </div>
      {!!body?.itemList?.length && (
        <Container>
          <b2x.Row className="mt-5">
            {body.itemList.map((item) => (
              <b2x.Col className="text-center" key={item.contentSectionId} size={{ lg: 3, md: 6, xs: 12 }}>
                {item.img && <b2x.ImageFromContentV1 {...item.img} fluid />}
                {item.title && <h2 className="fw-bold mt-4">{b2x.formatHtml(item.title)}</h2>}
                {item.subTitle && <h4 className="mt-2">{b2x.formatHtml(item.subTitle)}</h4>}
              </b2x.Col>
            ))}
          </b2x.Row>
        </Container>
      )}
      {body?.tableData && !!body.tableData.rowList?.length && (
        <Container>
          <div className="mt-5">
            {body.tableData.title && <h2 className="fw-bold">{body.tableData.title}</h2>}
            <div className="p-3 bg-gray-200 mt-4 table-container">
              {body.tableData.rowList.map((row) => (
                <div key={row.contentSectionId}>
                  <div className="p-4">
                    <h2 className="fw-bold m-0">{row.title}</h2>
                  </div>
                  {row.items?.map((item) => (
                    <div className="p-4 text-center" key={item.contentSectionId}>
                      <h4 className="m-0">{item.title}</h4>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Container>
      )}
      {!!body?.rowList?.length && (
        <Container className="overflow-hidden">
          {body.rowList.map((row) => (
            <b2x.Row className="mt-3" gap={5} key={row.contentSectionId}>
              <b2x.Col className={`order-lg-${row.imagePosition === 'left' ? 1 : 2}`} size={{ lg: 6, xs: 12 }}>
                {row.title && <h2 className="fw-bold mb-4 d-lg-none d-block">{b2x.formatHtml(row.title)}</h2>}
                {row.img && <b2x.ImageFromContentV1 {...row.img} className="w-100" fluid />}
              </b2x.Col>
              <b2x.Col className="order-lg-1 d-flex flex-column justify-content-center" size={{ lg: 6, xs: 12 }}>
                {row.title && <h2 className="fw-bold d-lg-block d-none">{b2x.formatHtml(row.title)}</h2>}
                {row.subTitle && <h4 className="mt-2">{b2x.formatHtml(row.subTitle)}</h4>}
                {row.subTitleCta?.label && (
                  <div className="mt-3">
                    <b2x.CtaFromContent
                      {...row.subTitleCta}
                      ctaProps={{
                        button: {
                          className: 'px-0 text-black',
                        },
                      }}
                    />
                  </div>
                )}
                {row.cta?.label && (
                  <div className="mt-5 w-auto">
                    <b2x.CtaFromContent {...row.cta} />
                  </div>
                )}
              </b2x.Col>
            </b2x.Row>
          ))}
        </Container>
      )}
      {!!body?.cardList?.length && (
        <Container className="mt-5">
          <CardsListSection cardsList={body.cardList} />
        </Container>
      )}
    </Page>
  );
};
