import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'smile',
  'coupon-mgm',
  'friends-mgm',
  'account-mgm',
  'share',
  'cibo-secco',
  'cibo-umido',
  'compresse',
  'controllo-del-peso',
  'convalescenza',
  'coupon',
  'credit-card',
  'cucciolo',
  'cuore',
  'customer',
  'delete',
  'delivery',
  'dermatosi',
  'diabete-cane',
  'diabete-gatto',
  'diete',
  'digestione-sensibile',
  'download',
  'email',
  'eta-cane',
  'eta-gatto',
  'exit',
  'facebook',
  'fegato',
  'filter',
  'gattino',
  'gelatina',
  'hidden',
  'high-meat',
  'igiene-orale',
  'info',
  'instagram',
  'kit-puppy',
  'language',
  'large-arrow-left',
  'large-arrow-right',
  'linkedin',
  'liquido',
  'menu',
  'minus',
  'mousse',
  'multipack',
  'obesita',
  'olio',
  'one-column-display',
  'pate',
  'piggy-bank',
  'plus',
  'polvere',
  'privacy',
  'programma-fedelta',
  'quality',
  'reni',
  'return',
  'review',
  'search',
  'secure-payments',
  'selected',
  'senior-cane',
  'senior-gatto',
  'shipping',
  'simple-return',
  'small-arrow-down',
  'small-arrow-left',
  'small-arrow-right',
  'small-arrow-up',
  'snack',
  'snack-premio',
  'subscribe',
  'supplement',
  'taglia-grande-cane',
  'taglia-piccola-cane',
  'taglie-cane',
  'timer',
  'two-column-display',
  'vaschetta',
  'vie-urinarie-cane',
  'vie-urinarie-gatto',
  'visible',
  'wishlist',
  'wishlist-full',
  'youtube',
  'account',
  'adulto-cane-taglia-media',
  'adulto-gatto',
  'allergie',
  'attivita-cognitive',
  'biscotti',
  'brand',
  'busta',
  'call',
  'cart',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 12 | 14 | 16 | 20 | 25 | 30 | 35 | 48 | 72;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
