import { ErrorResponseApiDto, ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useProductsApi } from '../api/useProductsApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { Div } from '../HTMLElement';
import { ResourceKeys, t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { RatingStar } from '../reviews/RatingStar';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatHtml } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radios, RadiosProps } from './fields/RadioCheck';
import { TextArea, TextAreaProps } from './fields/TextArea';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { ReviewFormModalA } from './ReviewFormModalA';

export interface ReviewFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  product: ProductApiDto;
}

interface FormValues {
  message: formikString;
  ratings: formikString;
}

type ValidationSchema = {
  message: yup.StringSchema;
  ratings: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  message: { formGroup: FormGroupProps; textArea: TextAreaProps };
  ratings: { formGroup: FormGroupProps; radios: RadiosProps };
}

export const ReviewFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  product,
  ...otherProps
}: ReviewFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      message: getInitialString(),
      ratings: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      message: yup.string(),
      ratings: yup.string().required(),
    }),
    []
  );

  const { writeReview } = useProductsApi();
  const { session } = useAppContext();
  const { showModal } = useModals();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      writeReview(
        product.id,
        {
          message: values.message,
          nickname: session?.customer?.name,
          rating: Number(values.ratings),
          voteType: 'FIVE_STARS',
        },
        { suppressErrorModal: true }
      )
        .then((response) => {
          showModal({
            children: t('form.reviewForm.outcome.success.body'),
            title: t('form.reviewForm.outcome.success.title'),
          });
        })
        .catch((error: AxiosError<ErrorResponseApiDto>) => {
          showModal({
            children: error.response?.data.key
              ? t(`${error.response.data.key}` as ResourceKeys)
              : t('form.reviewForm.outcome.failure.generic.body'),
            title: t('form.reviewForm.outcome.failure.generic.title'),
          });
        }),
    [product.id, session?.customer?.name, showModal, writeReview]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('review-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.reviewForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.reviewForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          message: {
            formGroup: { label: t('form.reviewForm.fields.message.label'), names: ['message'] },
            textArea: { name: 'message', rows: 5 },
          },
          ratings: {
            formGroup: {
              className: 'rating-form-group',
              label: formatHtml(t('form.reviewForm.fields.rating.label', { productName: product.name })),
              names: ['ratings'],
              omitForAttribute: true,
            },
            radios: {
              radios: [
                {
                  children: <RatingStar empty={1 > Number(formik.values.ratings)} size={32} />,
                  className: 'radio-rating-star',
                  id: 'rating1',
                  inline: true,
                  label: undefined,
                  name: 'ratings',
                  value: '1',
                },
                {
                  children: <RatingStar empty={2 > Number(formik.values.ratings)} size={32} />,
                  className: 'radio-rating-star',
                  id: 'rating2',
                  inline: true,
                  label: undefined,
                  name: 'ratings',
                  value: '2',
                },
                {
                  children: <RatingStar empty={3 > Number(formik.values.ratings)} size={32} />,
                  className: 'radio-rating-star',
                  id: 'rating3',
                  inline: true,
                  label: undefined,
                  name: 'ratings',
                  value: '3',
                },
                {
                  children: <RatingStar empty={4 > Number(formik.values.ratings)} size={32} />,
                  className: 'radio-rating-star',
                  id: 'rating4',
                  inline: true,
                  label: undefined,
                  name: 'ratings',
                  value: '4',
                },
                {
                  children: <RatingStar empty={5 > Number(formik.values.ratings)} size={32} />,
                  className: 'radio-rating-star',
                  id: 'rating5',
                  inline: true,
                  label: undefined,
                  name: 'ratings',
                  value: '5',
                },
              ],
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <Div>
            <FormGroup {...fieldsHelper.ratings.formGroup}>
              <Radios {...fieldsHelper.ratings.radios} />
            </FormGroup>
            <FormGroup {...fieldsHelper.message.formGroup}>
              <TextArea {...fieldsHelper.message.textArea} />
            </FormGroup>
            <Div textAlign={'center'}>
              <Button {...fieldsHelper.buttons.submit} />
            </Div>
          </Div>
        );
      }}
    </HelpedForm>
  );
};

export type ReviewFormVariants = '';

const ReviewFormController = (props: PropsWithCustomComponentWithoutChildren<ReviewFormProps>) => (
  <VariantsController<ReviewFormProps, ReviewFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ReviewFormHelper,
      name: 'ReviewForm',
    }}
  />
);
export { ReviewFormController as ReviewForm };

/////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ReviewFormModalProps extends ReviewFormProps {}

const ReviewFormModal = (props: ReviewFormModalProps) => {
  return <ReviewFormController {...props} />;
};

export type ReviewFormModalVariants = 'A';

const ReviewFormModalController = (props: PropsWithCustomComponentWithoutChildren<ReviewFormModalProps>) => (
  <VariantsController<ReviewFormModalProps, ReviewFormModalVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ReviewFormModalA },
      defaultComponent: ReviewFormModal,
      name: 'ReviewFormModal',
    }}
  />
);
export { ReviewFormModalController as ReviewFormModal };

/////////////////////////////////////////////////////////////////////////////////////////////////////

export const useReviewFormModal = (modalProps?: UseFormModalProps) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: ReviewFormModalProps) => {
      showModal({
        children: <ReviewFormModalController {...formProps} />,
        className: 'review-form-modal',
        fullscreen: 'sm',
        size: 'large',
        title: t('form.reviewForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};
