import './BannerSlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { SliderContentSection } from '../contentTypes';
import { SliderButton } from './SliderButton';

export interface BannerSliderProps extends SliderContentSection {
  titleClassName?: string;
}

export const BannerSlider = ({ sliderList, titleClassName }: BannerSliderProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  return (
    <div className="banner-slider position-relative">
      <b2x.EqualHeight timeout={200}>
        <div className="slider">
          <Swiper
            autoplay={{ delay: 11000 }}
            modules={[Pagination, Navigation, Autoplay]}
            navigation={{ nextEl: '.banner-slider .swiper-button-next', prevEl: '.banner-slider .swiper-button-prev' }}
            pagination={{
              clickable: true,
              el: '.banner-slider .pagination',
              type: 'bullets',
            }}
            slidesPerView={1}
            spaceBetween={0}
          >
            {sliderList &&
              sliderList.map((slide) => (
                <SwiperSlide key={slide.contentSectionId}>
                  {(slideData) => (
                    <div className={'slide'}>
                      <div className="background position-relative h-100 text-center">
                        <b2x.ConditionalWrapper
                          condition={slide.cta !== undefined}
                          wrapper={
                            <b2x.router.Link
                              to={
                                slide.cta?.to?.href
                                  ? slide.cta.to.href
                                  : slide.cta?.to?.code && getPagePath(slide.cta.to.code)
                              }
                            />
                          }
                        >
                          <b2x.EqualHeightElement name="swiper-equal-height">
                            {slide.video?.src && slide.video.src.length > 1 ? (
                              <b2x.DeprecatedVideoAsBackgroundFromContent {...slide.video} />
                            ) : (
                              <b2x.ImageFromContentV1 {...slide.img} fluid />
                            )}
                            {/* {slide.cta && <b2x.DeprecatedCta className="stretched-link" cta={slide.cta} />} */}
                          </b2x.EqualHeightElement>
                        </b2x.ConditionalWrapper>
                      </div>

                      <div className="message">
                        <Container>
                          <div
                            className={classnames(
                              'd-lg-flex',
                              'pt-4 pt-lg-0',
                              'justify-content-start',
                              {
                                'justify-content-center': slide.copy?.alignment === 'center',
                                'text-center': slide.copy?.alignment === 'center',
                              },
                              {
                                'justify-content-end': slide.copy?.alignment === 'right',
                                'text-end': slide.copy?.alignment === 'right',
                              }
                            )}
                          >
                            <div className="col-lg-7 col-12">
                              {slide.copy?.title && (
                                <h4 className={classnames('title display-4', titleClassName)}>
                                  <span
                                    className={`d-lg-inline d-none bg-${slide.copy.titleBackgroundColor} text-${slide.copy.titleColor} lh-sm`}
                                  >
                                    {b2x.formatHtml(slide.copy.title)}
                                  </span>
                                  <span className={'d-lg-none d-block'}>{b2x.formatHtml(slide.copy.title)}</span>
                                </h4>
                              )}
                              {slide.copy?.content && (
                                <h5 className={classnames('h2', titleClassName)}>
                                  <span
                                    className={`d-lg-inline d-none bg-${slide.copy.contentBackgroundColor} text-${slide.copy.contentColor} lh-sm`}
                                  >
                                    {b2x.formatHtml(slide.copy.content)}
                                  </span>
                                  <span className={'d-lg-none d-block'}>{b2x.formatHtml(slide.copy.content)}</span>
                                </h5>
                              )}
                              {slide.ctaSection?.firstCta?.label && (
                                <div className="mt-4 d-md-inline-block">
                                  <b2x.DeprecatedCta
                                    className={classnames('btn px-5', `btn-${slide.ctaSection.firstCta.variant}`)}
                                    cta={slide.ctaSection.firstCta}
                                  >
                                    {b2x.formatHtml(slide.ctaSection.firstCta.label)}
                                  </b2x.DeprecatedCta>
                                </div>
                              )}
                              {slide.ctaSection?.secondCta?.label && (
                                <div className="d-md-inline-block mt-3 mt-md-0 mx-md-4 mt-4">
                                  <b2x.DeprecatedCta
                                    className={classnames('btn px-5', `btn-${slide.ctaSection.secondCta.variant}`)}
                                    cta={slide.ctaSection.secondCta}
                                  >
                                    {b2x.formatHtml(slide.ctaSection.secondCta.label)}
                                  </b2x.DeprecatedCta>
                                </div>
                              )}
                              <div className="d-lg-none mb-4"></div>
                              {/*                               {slide.cta && slide.cta.label && (
                                <div className="mt-4">
                                  <b2x.DeprecatedCta
                                    className={classnames('btn', `btn-${slide.cta.variant}`)}
                                    cta={slide.cta}
                                  >
                                    {b2x.formatHtml(slide.cta.label)}
                                  </b2x.DeprecatedCta>
                                </div>
                              )}
 */}
                              {slide.copy?.disclaimer && (
                                <p className="disclaimer mt-3 mt-lg-2 small">
                                  <span className={`d-lg-inline d-none text-${slide.copy.disclaimerColor} lh-sm`}>
                                    {b2x.formatHtml(slide.copy.disclaimer)}
                                  </span>
                                  <span className="d-lg-none d-block">{b2x.formatHtml(slide.copy.disclaimer)}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        </Container>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="slider-navigation">
          <b2x.EqualHeightElement name="swiper-equal-height">
            <SliderButton direction="left" size="large" />
            <SliderButton direction="right" size="large" />
          </b2x.EqualHeightElement>
        </div>
        {/*         <div className="slider-pagination">
          <b2x.EqualHeightElement name="swiper-equal-height">
            <div className="pagination"></div>
          </b2x.EqualHeightElement>
        </div>
 */}
        <div className="slider-pagination d-xl-none">
          <div className="pagination"></div>
        </div>
      </b2x.EqualHeight>
    </div>
  );
};
