import './AssembledProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext } from '../AppContext';
import { AssembledProductTile } from '../AssembledProductTile';
import { Button } from '../Button';
import { Container } from '../Container';
import { PickAndMixGourmetAtelierProductContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { Page } from './Page';

export interface AssembledProductPageProps {}

export const AssembledProductPage = (props: AssembledProductPageProps) => {
  const gourmetDirectory = b2x.useDirectory('FACE_GOURMET', { populate: { children: { products: true } } });

  const _product = b2x.useProduct();

  const { addToCart, assembledProduct, product, searchResult } = b2x.useProductAssembler({
    lonelySkuAutomaticAddition: true,
    productId: _product?.id,
  });

  const content = b2x.useContent<PickAndMixGourmetAtelierProductContentType>(
    'PICK_AND_MIX_GOURMET_ATELIER_PRODUCT_CONTENT'
  );

  const componentSkuPrice = React.useMemo(() => {
    let minQty = product?.assembledComponents?.at(0)?.minQty;
    minQty = minQty && minQty;

    let price = product?.skus?.at(0)?.price?.value;
    price = price && minQty && price / minQty;

    let crossedOutPrice = product?.skus?.at(0)?.price?.crossedOutValue;
    crossedOutPrice = crossedOutPrice && minQty && crossedOutPrice / minQty;

    return { crossedOutPrice, price };
  }, [product?.assembledComponents, product?.skus]);

  return (
    <Page
      className="assembled-product-page pb-0 pb-lg-5"
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[assembledProduct]}
    >
      {assembledProduct && (
        <React.Fragment>
          {content?.body.header?.asset && (
            <section className="header-section text-center">
              <b2x.AssetV1 {...content.body.header.asset} fluid />
            </section>
          )}
          <Container>
            <div className="pt-3 pt-md-5">
              <b2x.Row>
                <b2x.Col size={{ lg: 8, xs: 12 }}>
                  {(content?.body.copy?.title || content?.body.copy?.description) && (
                    <div className="copy-container mb-4">
                      {content.body.copy.title && (
                        <h1 className="text-gold mb-3">{b2x.formatHtml(content.body.copy.title)}</h1>
                      )}
                      {content.body.copy.description && <div>{b2x.formatHtml(content.body.copy.description)}</div>}
                    </div>
                  )}
                  <Filters searchResult={searchResult} />
                  <ProductsTilesSection
                    assembledProduct={assembledProduct}
                    componentSkuCrossedOutPrice={componentSkuPrice.crossedOutPrice}
                    componentSkuPrice={componentSkuPrice.price}
                    content={content}
                    gourmetDirectory={gourmetDirectory}
                    productUrl={product?.url}
                    searchResult={searchResult}
                  />
                </b2x.Col>
                <b2x.Col className="d-none d-lg-block" size={4}>
                  <Summary
                    addToCart={addToCart}
                    assembledProduct={assembledProduct}
                    componentSkuCrossedOutPrice={componentSkuPrice.crossedOutPrice}
                    componentSkuPrice={componentSkuPrice.price}
                    product={product}
                  />
                </b2x.Col>
              </b2x.Row>
            </div>
          </Container>
          <StickyFooter
            addToCart={addToCart}
            assembledProduct={assembledProduct}
            componentSkuCrossedOutPrice={componentSkuPrice.crossedOutPrice}
            componentSkuPrice={componentSkuPrice.price}
            product={product}
          />
        </React.Fragment>
      )}
    </Page>
  );
};

interface FiltersProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const Filters = ({ searchResult }: FiltersProps) => {
  const _excludeFacets = [
    'BISOGNI_SPECIFICI',
    'TIPO_UMIDO',
    'FACE_ETA_CANE',
    'FACE_ETA_GATTO',
    'FORMATO',
    'FACE_TAGLIA',
    'FACE_SNACK_GATTO',
    'FACE_SNACK_CANE',
    'FACE_ANIMALE',
    'FACE_SUPPLEMENTS_GATTO',
    'FACE_DIETE',
    'FACE_SUPPLEMENTS_CANE',
    'BRAND',
    'CATEGORIE',
  ];

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    excludeFacets: _excludeFacets,
    searchResult: searchResult,
  });

  return (
    <React.Fragment>
      <div className="mb-4">
        <Button
          className="px-2 d-block d-md-none"
          onClick={showMobileSearchFiltersOffcanvas}
          type="button"
          variant="secondary"
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="me-3">
              <Icon className="me-2" name="filter" size={20} />
              {t('misc.filterAndOrderBy')}
            </div>
          </div>
        </Button>
      </div>
      <div className="filters-sticky position-sticky bg-white d-none d-md-block" style={{ zIndex: 90 }}>
        <b2x.SearchFormHelper basePath={window.location.pathname} searchResult={searchResult} submitOnChange>
          {({ fieldsHelper }) => {
            const filters = fieldsHelper.filters.filter(
              ({ filter }) =>
                filter.code === 'TEXTURE' || filter.code === 'INGREDIENTI' || filter.code === 'FACE_GOURMET'
            );

            return (
              <div className="assembled-product-filters mb-5">
                <b2x.Row>
                  {filters.map(({ children, filter }) => (
                    <b2x.Col key={filter.code} size={4}>
                      <div className="assembled-product-filter">
                        <div className="dropdown d-grid py-2">
                          <Button
                            aria-expanded="false"
                            className="border-bottom fw-bold px-0 py-2"
                            data-bs-toggle="dropdown"
                            iconEnd={{ name: 'plus', size: 16 }}
                            id={filter.code}
                            justifyContent="between"
                            label={filter.name}
                            variant="blank"
                          />
                          <ul aria-labelledby={filter.code} className="dropdown-menu w-100">
                            {children.map(({ checkbox }) => (
                              <li key={checkbox.id}>
                                <b2x.Checkbox {...checkbox} />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </b2x.Col>
                  ))}
                  {fieldsHelper.activeFilters.length > 0 && (
                    <b2x.Col size={12}>
                      <div className="hstack flex-wrap gap-2 pb-2">
                        {fieldsHelper.activeFilters.map((item) => (
                          <Button
                            className="extra-small fw-normal gap-4 px-3"
                            iconEnd={{ name: 'delete', size: 12 }}
                            justifyContent="between"
                            key={item.filter.id}
                            label={item.filter.name}
                            onClick={item.handleClick}
                            variant={'gray-200'}
                          />
                        ))}
                      </div>
                    </b2x.Col>
                  )}
                </b2x.Row>
              </div>
            );
          }}
        </b2x.SearchFormHelper>
        {MobileSearchFiltersOffcanvas}
      </div>
    </React.Fragment>
  );
};

interface ProductsTilesSectionProps {
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  content?: b2x.ContentApiDto<PickAndMixGourmetAtelierProductContentType>;
  gourmetDirectory?: b2x.DirectoryApiDto<unknown>;
  productUrl?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const ProductsTilesSection = ({
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  content,
  gourmetDirectory,
  productUrl,
  searchResult,
}: ProductsTilesSectionProps) => {
  const [goldProductLimit, setGoldProductLimit] = React.useState<number>(6);
  const [perleProductLimit, setPerleProductLimit] = React.useState<number>(6);

  // Gourmet - products
  const gourmetProducts = React.useMemo(() => {
    // Genero un array contenente tutti i codici prodotto
    const goldProductsCode = gourmetDirectory?.children
      .filter((child) => child.code === 'FACE_GRM_GOLD')
      .at(0)
      ?.products?.map((goldProduct) => goldProduct.code);

    // Filtro dall'assembledComponent.at(0) tutti i prodotti appartenenti alla faccetta FACE_GRM_GOLD
    let goldProduts = assembledProduct.assembledComponents
      ?.at(0)
      ?.componentSkus?.filter((componentSku) => goldProductsCode?.includes(componentSku.sku?.product?.code))
      .filter((componentSku) => componentSku.inCurrentFilter);

    const goldProductsLength = goldProduts?.length ?? 0;

    goldProduts = goldProduts?.slice(0, goldProductLimit);

    // Genero un array contenente tutti i codici prodotto
    const perleProductsCode = gourmetDirectory?.children
      .filter((child) => child.code === 'FACE_GRM_PERLE')
      .at(0)
      ?.products?.map((perlaProduct) => perlaProduct.code);

    // Filtro dall'assembledComponent.at(0) tutti i prodotti appartenenti alla faccetta FACE_GRM_PERLE
    let perlaProduts = assembledProduct.assembledComponents
      ?.at(0)
      ?.componentSkus?.filter((componentSku) => perleProductsCode?.includes(componentSku.sku?.product?.code))
      .filter((componentSku) => componentSku.inCurrentFilter);

    const perleProductsLength = perlaProduts?.length ?? 0;

    perlaProduts = perlaProduts?.slice(0, perleProductLimit);

    return { goldProductsLength, goldProduts, perlaProduts, perleProductsLength };
  }, [assembledProduct.assembledComponents, goldProductLimit, perleProductLimit, gourmetDirectory?.children]);

  // Show more gold products
  const goldShowMoreRef = React.useRef<HTMLDivElement>(null);

  const handleShowMoreGoldButtonClick = React.useCallback(() => {
    gourmetProducts.goldProductsLength && setGoldProductLimit(gourmetProducts.goldProductsLength);
    goldShowMoreRef.current?.remove();
  }, [gourmetProducts.goldProductsLength]);

  // Show more perle products
  const perleShowMoreRef = React.useRef<HTMLDivElement>(null);

  const handleShowMorePerleButtonClick = React.useCallback(() => {
    gourmetProducts.perleProductsLength && setPerleProductLimit(gourmetProducts.perleProductsLength);
    perleShowMoreRef.current?.remove();
  }, [gourmetProducts.perleProductsLength]);

  return (
    <div className="assembled-product-assembled-list">
      {gourmetProducts.goldProduts && gourmetProducts.goldProduts.length > 0 && (
        <div className="mb-5">
          <div
            className="py-2 px-3 text-center mb-4"
            style={{
              background: content?.body.productsSections?.gold?.sectionTitle?.background,
              color: content?.body.productsSections?.gold?.sectionTitle?.color,
            }}
          >
            <h3 className="m-0">{b2x.formatHtml(content?.body.productsSections?.gold?.sectionTitle?.title)}</h3>
          </div>
          <div className="hstack justify-content-between extra-small mb-2">
            <div>{t('misc.searchItemResult', { count: gourmetProducts.goldProductsLength })}</div>
            <div>
              <SortingOptionsDropdown className="d-none d-lg-block" searchResult={searchResult} />
            </div>
          </div>
          <div className="mb-4">
            <ProductsTiles
              componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
              componentSkuPrice={componentSkuPrice}
              productUrl={productUrl}
              products={gourmetProducts.goldProduts}
              productsPerRow={{ md: 3, xs: 2 }}
            />
          </div>
          {gourmetProducts.goldProductsLength > 5 && (
            <div className="text-center" ref={goldShowMoreRef}>
              <Button
                className="text-reset fw-bold"
                label={content?.body.productsSections?.gold?.sectionTitle?.cta}
                onClick={handleShowMoreGoldButtonClick}
                variant="link"
              />
            </div>
          )}
        </div>
      )}
      {gourmetProducts.perlaProduts && gourmetProducts.perlaProduts.length > 0 && (
        <div className="mb-5">
          <div
            className="py-2 px-3 text-center mb-4"
            style={{
              background: content?.body.productsSections?.perle?.sectionTitle?.background,
              color: content?.body.productsSections?.perle?.sectionTitle?.color,
            }}
          >
            <h3 className="m-0">{b2x.formatHtml(content?.body.productsSections?.perle?.sectionTitle?.title)}</h3>
          </div>
          <div className="hstack justify-content-between extra-small mb-2">
            <div>{t('misc.searchItemResult', { count: gourmetProducts.perleProductsLength })}</div>
            <div>
              <SortingOptionsDropdown className="d-none d-lg-block" searchResult={searchResult} />
            </div>
          </div>
          <div className="mb-4">
            <ProductsTiles
              componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
              componentSkuPrice={componentSkuPrice}
              productUrl={productUrl}
              products={gourmetProducts.perlaProduts}
              productsPerRow={{ md: 3, xs: 2 }}
            />
          </div>
          {gourmetProducts.perleProductsLength > 5 && (
            <div className="text-center" ref={perleShowMoreRef}>
              <Button
                className="text-reset fw-bold"
                label={content?.body.productsSections?.perle?.sectionTitle?.cta}
                onClick={handleShowMorePerleButtonClick}
                variant="link"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type ProductsPerRow = 1 | 2 | 3 | 4;

interface ProductsTilesProps {
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  productUrl?: string;
  products: Array<b2x.AssembledComponentSku>;
  productsPerRow: Partial<Record<b2x.Breakpoint, ProductsPerRow>>;
}

interface ProductTilesWrapperProps extends ProductsTilesProps {
  equalHeightContext: b2x.EqualHeightContextInterface;
  tempProductPerRow: number;
}

const ProductTilesWrapper = ({
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  equalHeightContext,
  productUrl,
  products,
  productsPerRow,
  tempProductPerRow,
}: ProductTilesWrapperProps) => {
  const windowSize = b2x.useWindowSize();
  const windowHeight = windowSize.height;
  const windowWidth = windowSize.width;

  const equalHeightStable = b2x.useStable(equalHeightContext);

  React.useEffect(() => {
    equalHeightStable.updateTrigger();
  }, [tempProductPerRow, equalHeightStable, windowWidth, windowHeight]);

  return (
    <b2x.Row cols={productsPerRow} gap={{ md: 4, xs: 3 }}>
      {products.map((componentSku, index) => (
        <b2x.Col key={componentSku.code}>
          <AssembledProductTile
            componentSku={componentSku}
            componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
            componentSkuPrice={componentSkuPrice}
            productUrl={productUrl}
            productsPerRow={Math.floor((index + 0) / tempProductPerRow)}
          />
        </b2x.Col>
      ))}
    </b2x.Row>
  );
};

const ProductsTiles = ({ productUrl, productsPerRow, ...otherProps }: ProductsTilesProps) => {
  const breakpoint = b2x.useBreakpoint();
  let tempProductPerRow = 3;

  if (breakpoint === 'xs') {
    tempProductPerRow = productsPerRow.xs ? productsPerRow.xs : tempProductPerRow;
  }
  if (breakpoint === 'sm') {
    tempProductPerRow = productsPerRow.sm ? productsPerRow.sm : tempProductPerRow;
  }
  if (breakpoint === 'md') {
    tempProductPerRow = productsPerRow.md ? productsPerRow.md : tempProductPerRow;
  }
  if (breakpoint === 'lg') {
    tempProductPerRow = productsPerRow.lg ? productsPerRow.lg : tempProductPerRow;
  }
  if (breakpoint === 'xl') {
    tempProductPerRow = productsPerRow.xl ? productsPerRow.xl : tempProductPerRow;
  }
  if (breakpoint === 'xxl') {
    tempProductPerRow = productsPerRow.xxl ? productsPerRow.xxl : tempProductPerRow;
  }

  return (
    <b2x.EqualHeight timeout={50}>
      {(equalHeightContext) => (
        <ProductTilesWrapper
          equalHeightContext={equalHeightContext}
          productUrl={productUrl}
          productsPerRow={productsPerRow}
          tempProductPerRow={tempProductPerRow}
          {...otherProps}
        />
      )}
    </b2x.EqualHeight>
  );
};

interface StickyFooterProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  product?: b2x.ProductApiDto;
}

const StickyFooter = ({
  addToCart,
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  product,
}: StickyFooterProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [SummaryOffcanvas, showSummaryOffcanvas] = useSummaryOffcanvas({
    addToCart: addToCart,
    assembledProduct: assembledProduct,
    className: 'assembled-product-summary-offcanvas',
    componentSkuCrossedOutPrice: componentSkuCrossedOutPrice,
    componentSkuPrice: componentSkuPrice,
    placement: 'bottom',
    product: product,
  });

  const assembledComponent = assembledProduct.assembledComponents?.at(0);

  return (
    <div
      className="assembled-product-sticky-footer position-sticky d-grid bottom-0 start-0 w-100 d-block d-lg-none"
      ref={ref}
      style={{ zIndex: 1000 }}
    >
      <Button className="bg-primary text-white py-3" onClick={showSummaryOffcanvas} variant="blank">
        <Container>
          <b2x.Row gap={2}>
            <b2x.Col className="d-flex align-items-center" size={'auto'}>
              <Icon name="cart" size={25} />
            </b2x.Col>
            <b2x.Col className="small text-start lh-sm" size={''}>
              <div>{t('assembledProduct.summary.subtitle')}</div>
              {assembledComponent && (
                <div className="fw-normal">
                  {t('assembledProduct.summary.status', {
                    maxProduct: Math.floor(assembledComponent.maxQty),
                    productAdded: Math.floor(assembledComponent.curQty),
                  })}
                </div>
              )}
            </b2x.Col>
            <b2x.Col size={'auto'}>
              {assembledComponent && componentSkuPrice && (
                <div className="extra-small fw-normal">
                  {b2x.formatCurrency(Math.floor(assembledComponent.curQty) * componentSkuPrice)}
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        </Container>
      </Button>
      {SummaryOffcanvas}
    </div>
  );
};

interface SummaryProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  product?: b2x.ProductApiDto;
}

const Summary = ({
  addToCart,
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  product,
}: SummaryProps) => {
  const { headerHeight } = useAppContext();

  const assembledComponent = assembledProduct.assembledComponents?.at(0);

  return (
    <div
      className="assembled-product-summary position-sticky sticky-top"
      style={{ height: `calc(100vh - ${headerHeight}px - 7.5rem)` }}
    >
      <h3 className="fw-bold text-center">{t('assembledProduct.summary.title')}</h3>
      {assembledComponent && (
        <div>
          <div className="assembled-progress text-center mb-3">
            <div className="d-flex justify-content-center mb-4">
              <b2x.CircleProgressBar
                label={t('assembledProduct.summary.yourAssembly')}
                size={230}
                strokeLineCap="square"
                strokeWidth={20}
                valueCurrent={Math.floor(assembledComponent.curQty)}
                valueFrom={Math.floor(assembledComponent.curQty)}
                valueTo={Math.floor(assembledComponent.maxQty)}
              />
            </div>
          </div>
          <div className="summary-item-list">
            <div className="text-center mb-2">
              <h3 className="fw-bold mb-0">{t('assembledProduct.summary.subtitle')}</h3>
              <p className="m-0">{product?.name}</p>
            </div>
            {assembledComponent.componentSkus
              ?.filter((componentSku) => componentSku.quantity > 0)
              .map((componentSku) => (
                <SummaryItem
                  componentSku={componentSku}
                  componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
                  componentSkuPrice={componentSkuPrice}
                  key={componentSku.code}
                />
              ))}
          </div>
        </div>
      )}
      {assembledComponent && componentSkuPrice && (
        <div className="bg-white position-sticky bottom-0">
          <div className="hstack justify-content-between fw-bold py-4">
            <div>{t('assembledProduct.summary.total')}</div>
            <div>{b2x.formatCurrency(Math.floor(assembledComponent.curQty) * componentSkuPrice)}</div>
          </div>
          <div className="d-grid">
            <Button
              disabled={!assembledProduct.completed}
              label={
                assembledProduct.completed ? t('assembledProduct.addToCart') : t('assembledProduct.completeTheAssembly')
              }
              onClick={addToCart}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface SummaryItemProps {
  componentSku: b2x.AssembledComponentSku;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
}

const SummaryItem = ({ componentSku, componentSkuCrossedOutPrice, componentSkuPrice }: SummaryItemProps) => {
  const productImage = componentSku.sku?.product?.attributes?.find(
    (attribute) => attribute.typeCode === 'PUR_PICKMIX_FOTO'
  )?.value;

  const pricePerUnit = componentSkuPrice && componentSkuPrice / componentSku.multiplier;

  return (
    <div className="summary-item">
      <div className="border-bottom py-3" key={componentSku.sku?.id}>
        <b2x.Row gap={0}>
          <b2x.Col size={'auto'}>
            {productImage ? (
              <b2x.Image fluid height={108} src={{ xs: productImage }} width={108} />
            ) : (
              <b2x.Image fluid {...componentSku.sku?.image} height={108} width={108} />
            )}
          </b2x.Col>
          <b2x.Col size={''}>
            <div className="small ps-3">
              <p className="fw-bold mb-0">
                {componentSku.sku?.product?.name} ({componentSku.multiplier}x{componentSku.sku?.name})
              </p>
              <p className="mb-0">{componentSku.sku?.name}</p>
              {pricePerUnit && (
                <div className="text-gray-400 extra-small">
                  ({t('misc.pricePerShape', { price: b2x.formatCurrency(pricePerUnit) })})
                </div>
              )}
            </div>
          </b2x.Col>
          <b2x.Col size={12}>
            <div className="d-flex justify-content-between pt-3">
              <div>
                <div className="component-sku-action">
                  <div className="d-flex">
                    <Button
                      className="btn-sm p-2"
                      disabled={!componentSku.removeEnabled}
                      iconStart={{ name: 'minus', size: 16 }}
                      onClick={componentSku.removeQuantity}
                    />
                    <div
                      className="border-top border-bottom w-100 d-flex align-items-center justify-content-center"
                      style={{ minWidth: 40 }}
                    >
                      <div>{componentSku.quantity}</div>
                    </div>
                    <Button
                      className="btn-sm p-2"
                      disabled={!componentSku.addEnabled}
                      iconStart={{ name: 'plus', size: 16 }}
                      onClick={componentSku.addQuantity}
                    />
                  </div>
                </div>
              </div>
              <div className="text-end">
                {componentSku.price && componentSkuPrice && (
                  <b2x.PriceBlock
                    hiddenDiscountPercentage
                    priceHelper={{
                      //crossedOutPrice: componentSkuCrossedOutPrice
                      //  ? componentSkuCrossedOutPrice * componentSku.quantity
                      //  : undefined,
                      crossedOutPrice: componentSku.price.crossedOutValue
                        ? componentSku.price.crossedOutValue * componentSku.multiplier * componentSku.quantity
                        : undefined,
                      discountPercentage: componentSku.price.discountPercentage,
                      isPriceInRange: false,
                      price: componentSkuPrice * componentSku.quantity,
                    }}
                  />
                )}
                <Button
                  className="extra-small text-gray-400 text-decoration-underline fw-normal"
                  label={t('assembledProduct.removeFromAssembly')}
                  onClick={componentSku.remove}
                  variant="blank"
                />
              </div>
            </div>
          </b2x.Col>
        </b2x.Row>
      </div>
    </div>
  );
};

interface SummaryOffcanvasProps extends b2x.OffcanvasProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  product?: b2x.ProductApiDto;
}

const SummaryOffcanvas = ({
  addToCart,
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  product,
  ...offcanvasProps
}: SummaryOffcanvasProps) => {
  const assembledComponent = assembledProduct.assembledComponents?.at(0);

  return (
    <b2x.Offcanvas {...offcanvasProps}>
      {({ close }) => (
        <>
          <b2x.OffcanvasHeader smallPadding title="Riepilogo del tuo menu">
            {assembledComponent && (
              <div>
                {t('assembledProduct.summary.status', {
                  maxProduct: Math.floor(assembledComponent.maxQty),
                  productAdded: Math.floor(assembledComponent.curQty),
                })}
              </div>
            )}
          </b2x.OffcanvasHeader>
          <b2x.OffcanvasBody className="p-3 pb-0">
            <div className="footer-mobile-offcanvas">
              <h3 className="fw-bold text-center">{t('assembledProduct.summary.title')}</h3>
              {assembledComponent && (
                <div>
                  <div className="assembled-progress text-center mb-3">
                    <div className="d-flex justify-content-center">
                      <b2x.CircleProgressBar
                        label={t('assembledProduct.summary.yourAssembly')}
                        size={180}
                        strokeLineCap="square"
                        strokeWidth={15}
                        valueCurrent={Math.floor(assembledComponent.curQty)}
                        valueFrom={Math.floor(assembledComponent.curQty)}
                        valueTo={Math.floor(assembledComponent.maxQty)}
                      />
                    </div>
                  </div>
                  <div className="summary-item-list">
                    {assembledComponent.componentSkus
                      ?.filter((componentSku) => componentSku.quantity > 0)
                      .map((componentSku) => (
                        <SummaryItem
                          componentSku={componentSku}
                          componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
                          componentSkuPrice={componentSkuPrice}
                          key={componentSku.code}
                        />
                      ))}
                  </div>
                </div>
              )}
              <div className="bg-white position-sticky bottom-0">
                {assembledComponent && componentSkuPrice && (
                  <div className="hstack justify-content-between fw-bold py-4">
                    <div>{t('assembledProduct.summary.total')}</div>
                    <div>{b2x.formatCurrency(Math.floor(assembledComponent.curQty) * componentSkuPrice)}</div>
                  </div>
                )}
                <div className="d-grid pb-3">
                  <Button
                    disabled={!assembledProduct.completed}
                    label={
                      assembledProduct.completed
                        ? t('assembledProduct.addToCart')
                        : t('assembledProduct.completeTheAssembly')
                    }
                    onClick={addToCart}
                  />
                </div>
              </div>
            </div>
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

export const useSummaryOffcanvas = (props?: SummaryOffcanvasProps) =>
  b2x.useStaticModal(SummaryOffcanvas, 'offcanvas', props);

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm extra-small fw-normal"
          className={classnames('order-dropdown ps-3', className)}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: <b>${sortingOptions.find((sortingItem) => sortingItem.active)?.label}</b>`
          )}
          variant="blank"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};
