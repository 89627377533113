import './BlogPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ArticleTile } from '../ArticleTile';
import { Container } from '../Container';
import { ArticlePageContentType, BlogPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface BlogPageProps {}

export const BlogPage = (props: BlogPageProps) => {
  const { page, searchContentsResult } = b2x.useContentsSearch<BlogPageContentType, ArticlePageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: true,
        content: true,
      },
    },
    populate: { items: { pages: true } },
    type: 'ARTICLE_PAGE_CONTENT_TYPE',
  });

  const categories = b2x.useMenu('MENU_BLOG_CATEGORIES');

  return (
    <>
      {searchContentsResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="blog-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
          <section className="blog-header text-center bg-gray-100 pt-3 pb-5 py-lg-5 mb-3">
            <Container>
              <b2x.Div>
                {page?.content?.body.pageTitleImage && page.content.body.pageTitleImage.src?.xs ? (
                  <b2x.ImageFromContentV1 {...page.content.body.pageTitleImage} fluid />
                ) : (
                  <h1 className="fw-bold">
                    {page?.content?.body.pageTitle ? b2x.formatHtml(page.content.body.pageTitle) : page?.name}
                  </h1>
                )}
                {page?.content?.body.pageSubtitle && (
                  <h3 className="mb-0 mt-3 h1 ff-purina">{b2x.formatHtml(page.content.body.pageSubtitle)}</h3>
                )}
              </b2x.Div>
            </Container>
          </section>

          <Container>
            <b2x.Row className="justify-content-center my-3 my-lg-5">
              <b2x.Col className="text-center" size={{ md: 10, xs: 12 }}>
                {page?.metaData?.title && <h1 className="mb-3 fw-bold">{b2x.formatHtml(page.metaData.title)}</h1>}
                {page?.metaData?.description && <div className="h4">{b2x.formatHtml(page.metaData.description)}</div>}
              </b2x.Col>
            </b2x.Row>
            {categories && (
              <b2x.Row className="my-3 my-md-5">
                <b2x.Col size={{ xs: 12 }}>
                  <div className="d-flex flex-nowrap justify-content-center first-level-category">
                    {page?.breadcrumb && page.breadcrumb.length > 1 && (
                      <b2x.router.Link
                        className={classnames('align-self-start', {
                          active: page.id === page.breadcrumb[1].id,
                        })}
                        to={page.breadcrumb[1].fullPath}
                      >
                        {t('misc.blog.allArticles')}
                      </b2x.router.Link>
                    )}
                    {categories.children.map((firstLevelCategory) => (
                      <b2x.router.Link
                        className={classnames('align-self-start', {
                          active:
                            page?.breadcrumb &&
                            page.breadcrumb.length > 3 &&
                            firstLevelCategory.id === page.breadcrumb[3].id,
                        })}
                        key={firstLevelCategory.id}
                        to={firstLevelCategory.link}
                      >
                        {b2x.formatHtml(firstLevelCategory.label)}
                      </b2x.router.Link>
                    ))}
                  </div>
                </b2x.Col>
              </b2x.Row>
            )}
          </Container>

          <Container>
            <b2x.Row>
              <b2x.EqualHeight>
                {searchContentsResult &&
                  searchContentsResult.items &&
                  searchContentsResult.items.map((article) => (
                    <div className="col-lg-4 col-md-6 col-sm-6" key={article.id}>
                      <ArticleTile
                        classname="mb-5"
                        cta={article.url}
                        imagePreview={article.body.imagePreview}
                        onlyOneFormatForImagePreview
                        pages={article.pages}
                        publicationDate={article.body.publicationDate}
                        summary={article.body.summary}
                        title={article.body.title}
                      />
                    </div>
                  ))}
              </b2x.EqualHeight>
            </b2x.Row>

            {searchContentsResult && (
              <b2x.Pagination
                alignment="right"
                currentPage={searchContentsResult.pageNumber}
                pageOffset={2}
                showDots
                singleStepNavigation
                totalPages={searchContentsResult.pagesCount}
              />
            )}
          </Container>
        </Page>
      )}
    </>
  );
};
