import { PageApiDto, ProductApiDto, TopSuggestionsApiDto } from '@b2x/storefront-api-js-client/src';
import { ProductPopulate } from '@b2x/storefront-api-js-client/src/products';
import { GetTopSuggestionsOptions } from '@b2x/storefront-api-js-client/src/suggestions';
import _ from 'lodash';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { usePagesApi } from './api/usePagesApi';
import { useProductsApi } from './api/useProductsApi';
import { useSuggestionsApi } from './api/useSuggestionsApi';
import { useStable, wait } from './util';

export interface UseTopSuggestionsOptions extends Omit<GetTopSuggestionsOptions, 'simpleSearch'> {
  debounce?: number;
  productPopulate?: ProductPopulate;
}

export interface UseTopSuggestionsResult {
  directories?: Array<PageApiDto>;
  products?: Array<ProductApiDto>;
  ref: React.RefObject<HTMLInputElement>;
  refetch(): void;
  topSuggestions?: TopSuggestionsApiDto;
}

export const useTopSuggestions = (
  options?: UseTopSuggestionsOptions,
  config?: ApiRequestConfig
): UseTopSuggestionsResult => {
  const [topSuggestions, setTopSuggestions] = React.useState<TopSuggestionsApiDto>();
  const [products, setProducts] = React.useState<Array<ProductApiDto>>();
  const [directories, setDirectories] = React.useState<Array<PageApiDto>>();

  const { getTopSuggestions } = useSuggestionsApi();
  const { searchProducts } = useProductsApi();
  const { searchPages } = usePagesApi();

  const {
    debounce = 250,
    directory,
    maxDirectories,
    maxPopularSearches,
    maxProducts,
    productPopulate,
  } = useStable(options) ?? {};
  config = useStable(config);

  const ref = React.useRef<HTMLInputElement>(null);

  const handleInputChange = React.useCallback(() => {
    const simpleSearch = ref.current?.value;
    if (simpleSearch) {
      log('handleInputChange', simpleSearch);
      getTopSuggestions(
        { directory, maxDirectories, maxPopularSearches, maxProducts, simpleSearch },
        { suppressErrorModal: true, ...config }
      )
        .then((response) => {
          // Non so bene perché, ma questo wait(0) evita un bug per il quale non si riesce bene a cancellare i caratteri dall'input (prende un backspace su due).
          wait(0).then(() => {
            setTopSuggestions(response.data);
            if (
              response.data.topSuggestionsByType?.PRODUCT?.length &&
              response.data.topSuggestionsByType.PRODUCT.length > 0
            ) {
              searchProducts(
                undefined,
                {
                  populate: { items: productPopulate },
                  products: response.data.topSuggestionsByType.PRODUCT.map(({ resource }) => resource.id),
                },
                { silent: true }
              ).then((searchProductsResponse) => {
                setProducts(searchProductsResponse.data.items);
              });
            } else {
              setProducts(undefined);
            }
            if (
              response.data.topSuggestionsByType?.DIRECTORY?.length &&
              response.data.topSuggestionsByType.DIRECTORY.length > 0
            ) {
              searchPages(
                response.data.topSuggestionsByType.DIRECTORY.map(({ resource }) => resource.id),
                undefined,
                { silent: true }
              ).then((searchPagesResponse) => {
                setDirectories(searchPagesResponse.data);
              });
            } else {
              setDirectories(undefined);
            }
          });
        })
        .catch(() => {
          // Non faccio nulla, sopprimo l'eccezione.
        });
    } else {
      setTopSuggestions(undefined);
      setProducts(undefined);
      setDirectories(undefined);
    }
  }, [
    config,
    directory,
    getTopSuggestions,
    maxDirectories,
    maxPopularSearches,
    maxProducts,
    productPopulate,
    searchPages,
    searchProducts,
  ]);

  const refetch = React.useCallback(handleInputChange, [handleInputChange]);

  React.useEffect(refetch, [refetch]);

  React.useEffect(() => {
    log('useEffect');
    if (ref.current) {
      ref.current.oninput = _.debounce(handleInputChange, debounce);
    }
  }, [handleInputChange, debounce]);

  return { directories, products, ref, refetch, topSuggestions };
};

const log = (...message: Array<unknown>) => {
  console.log('Top suggestions', message);
};
