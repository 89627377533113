import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface FooterIconsProps {}

export const FooterIcons = (props: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <>
      {footerIconsContent?.body.iconsList && (
        <Container>
          <b2x.Row gap={3}>
            {footerIconsContent.body.iconsList.map((item) => (
              <b2x.Col key={item.contentSectionId} size={{ md: 3, xs: 6 }}>
                <b2x.router.Link className="text-decoration-none text-white" to={item.cta?.to?.href}>
                  {isIconName(item.cta?.icon) && (
                    <>
                      <Icon className="mb-3" fontSize={72} name={item.cta?.icon as IconName} />
                      <h6 className="small">{item.cta?.label}</h6>
                    </>
                  )}
                </b2x.router.Link>
              </b2x.Col>
            ))}
          </b2x.Row>
        </Container>
      )}
    </>
  );
};
