import classnames from 'classnames';

import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { TextInput } from '../form/fields/Input';
import { FormGroup } from '../form/FormGroup';
import { SimpleSearchFormHelper, SimpleSearchFormProps } from '../form/SimpleSearchForm';
import { Div } from '../HTMLElement';
import { Icon } from '../Icon';
import { Row } from '../Row';
import { TopSuggestionsBox } from '../TopSuggestionsBox';
import { useTopSuggestions } from '../useTopSuggestions';
import { renderUI, uiClassName } from '../util';

export interface TopSuggestionsProps extends SimpleSearchFormProps {
  className?: string;
  clearIconName?: string;
  submitIconName?: string;
}

export const TopSuggestions = ({ className, clearIconName, submitIconName, ...otherProps }: TopSuggestionsProps) => {
  const { directories, products, ref, refetch, topSuggestions } = useTopSuggestions({
    maxPopularSearches: 6,
    maxProducts: 6,
    productPopulate: {
      priceRange: true,
    },
  });

  return renderUI({
    bs5: (
      <Div className={classnames('top-suggestions', className)}>
        <Container>
          <SimpleSearchFormHelper {...otherProps}>
            {({ fieldsHelper, formik, onSuccess }) => (
              <>
                <Row className="justify-content-center">
                  <Col size={{ lg: 8, xs: 12 }}>
                    <FormGroup
                      {...fieldsHelper.text.formGroup}
                      className="d-flex align-items-center border"
                      label={undefined}
                      noMarginBottom
                    >
                      <TextInput
                        {...fieldsHelper.text.textInput}
                        autoFocus
                        className={uiClassName({ bs5: 'border-0' })}
                        innerRef={ref}
                      />
                      {submitIconName &&
                        clearIconName &&
                        (formik.values.text ? (
                          <Button
                            className={uiClassName({ bs5: 'px-3 align-self-stretch' })}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              if (ref.current?.value) {
                                ref.current.value = '';
                                formik.setFieldValue('text', '');
                                refetch();
                              }
                            }}
                            variant="blank"
                          >
                            <Icon name={clearIconName} />
                          </Button>
                        ) : (
                          <Div paddingX={3}>
                            <Icon name={submitIconName} opacity={0.4} />
                          </Div>
                        ))}
                    </FormGroup>
                  </Col>
                </Row>
                {topSuggestions && (
                  <TopSuggestionsBox
                    directories={directories}
                    // eslint-disable-next-line react/jsx-no-bind
                    onSuccess={() => {
                      if (ref.current?.value) {
                        ref.current.value = '';
                        formik.setFieldValue('text', '');
                        refetch();
                      }
                      onSuccess && onSuccess();
                    }}
                    products={products}
                    simpleSearch={formik.values.text}
                    topSuggestions={topSuggestions}
                  />
                )}
              </>
            )}
          </SimpleSearchFormHelper>
        </Container>
      </Div>
    ),
  });
};
