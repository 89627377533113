import { ReviewApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { Review } from './Review';

export interface ReviewItemListProps {
  ratingPrecision?: number;
  reviews?: Array<ReviewApiDto>;
}

const ReviewItemList = ({ ratingPrecision = 2, reviews }: ReviewItemListProps) => {
  return (
    <Div className="review-item-list">
      {reviews && reviews.length > 0
        ? reviews.map((review, index) => (
            <React.Fragment key={review.id}>
              <Review ratingPrecision={ratingPrecision} review={review} />
              <hr />
            </React.Fragment>
          ))
        : t('reviews.misc.emptyList')}
    </Div>
  );
};

export type ReviewItemListVariants = '';

const ReviewItemListController = (props: PropsWithCustomComponent<ReviewItemListProps>) => (
  <VariantsController<ReviewItemListProps, ReviewItemListVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ReviewItemList,
      name: 'ReviewItemList',
    }}
  />
);
export { ReviewItemListController as ReviewItemList };
