import classnames from 'classnames';

import { PropsWithCustomComponent, VariantsController } from '../VariantsController';

export interface RatingStarProps {
  color?: string;
  empty?: boolean;
  size?: number;
}

const RatingStar = ({ color = '#000000', empty, size = 16 }: RatingStarProps) => {
  return (
    <div className={classnames({ 'star-empty': empty }, { star: !empty })} style={{ display: 'block' }}>
      {empty ? (
        <svg
          height={size}
          style={{ display: 'block' }}
          version="1.1"
          viewBox="0 0 20 20"
          width={size}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="M10,1.78l2.67,5.41l5.98,0.87l-4.32,4.21l1.02,5.95L10,15.41l-5.35,2.81l1.02-5.95
	L1.35,8.06l5.98-0.87L10,1.78z"
            fill={'transparent'}
            stroke={color}
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
        </svg>
      ) : (
        <svg
          height={size}
          style={{ display: 'block' }}
          version="1.1"
          viewBox="0 0 20 20"
          width={size}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="M10,1.78l2.67,5.41l5.98,0.87l-4.32,4.21l1.02,5.95L10,15.41l-5.35,2.81l1.02-5.95
	L1.35,8.06l5.98-0.87L10,1.78z"
            fill={color}
            stroke={color}
            strokeMiterlimit={10}
            strokeWidth={1.75}
          />
        </svg>
      )}
    </div>
  );
};

export type RatingStarVariants = '';

const RatingStarController = (props: PropsWithCustomComponent<RatingStarProps>) => (
  <VariantsController<RatingStarProps, RatingStarVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: RatingStar,
      name: 'RatingStar',
    }}
  />
);
export { RatingStarController as RatingStar };
