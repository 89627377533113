import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { Icon } from './Icon';
import logoSmall from './images/logo-mobile.svg';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';

export interface MobileHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ content, innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();

  return (
    <>
      {MobileNavigationOffcanvas}
      <b2x.Sticky className="mobile-header">
        {({ isSticky }) => (
          <div
            className={classnames(`sticky-top MobileHeader d-block d-${visibleUntil}-none border-bottom`)}
            ref={innerRef}
          >
            <Container>
              <b2x.Row className="py-2">
                <b2x.Col className="d-flex align-item-center" size={''}>
                  <Button
                    className="menu-button"
                    iconEnd={{ name: 'menu', size: 25 }}
                    justifyContent="start"
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                  <b2x.router.Link className="logo" to="/">
                    <b2x.Image onLoad={recalculateHeaderHeight} src={logoSmall} />
                  </b2x.router.Link>
                </b2x.Col>
                <b2x.Col size="auto">
                  <Toggles />
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};

interface TogglesProps {}

const Toggles = (props: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <div className="toggles">
      <b2x.Row gap={0}>
        {session?.customer ? (
          <b2x.Col size={'auto'}>
            <b2x.router.Link
              className="toggle-item toggle-account text-decoration-none"
              to={getPagePath('SITE_ACCOUNT')}
            >
              <Button
                className="btn-sm text-primary fw-normal"
                iconStart={{ name: 'account', size: 25 }}
                variant="blank"
              />
            </b2x.router.Link>
          </b2x.Col>
        ) : (
          <b2x.Col size={'auto'}>
            <Button
              className="btn-sm toggle-item toggle-account fw-normal"
              iconStart={{ name: 'account', size: 25 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          </b2x.Col>
        )}
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.Col size={'auto'}>
              <b2x.router.Link className="toggle-item text-black" to="/account/area/wishlist">
                <span className="position-relative">
                  <Icon className="wishlist-icon" name={'wishlist'} size={25} />
                  {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                    (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                    <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                      {(session.wishlist.products ? session.wishlist.products.length : 0) +
                        (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                    </span>
                  )}
                </span>
              </b2x.router.Link>
            </b2x.Col>
          ) : (
            <b2x.Col size={'auto'}>
              <Button
                className="toggle-item wishlist-icon"
                iconEnd={{ name: 'wishlist', size: 25 }}
                onClick={showAccountOffcanvas}
                variant="blank"
              />
            </b2x.Col>
          ))}
        <b2x.Col size={'auto'}>
          <Button className="toggle-item" onClick={showCartOffcanvas} variant="blank">
            <span className="position-relative">
              <Icon name={'cart'} size={25} />
              {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                  {session.cart.itemsNumber}
                </span>
              )}
            </span>
          </Button>
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};
