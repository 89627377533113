import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import { deepFilterMenuByCustomerType } from './util';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  firstRowRef,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  toggleLocaleBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { session } = b2x.useAppContext();
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menuToBefilteredByCustomerType = b2x.useMenu('MENU_HEADER_DESKTOP', {
    // deepFilter: (_menu) => deepFilterMenuByCustomerType(_menu, session),
    populate: { content: true },
  });

  const menu = React.useMemo<b2x.MenuApiDto | undefined>(
    () =>
      menuToBefilteredByCustomerType
        ? deepFilterMenuByCustomerType(menuToBefilteredByCustomerType, session)
        : undefined,
    [menuToBefilteredByCustomerType, session]
  );

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, [setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  return (
    <b2x.Sticky className="desktop-header position-relative">
      {({ isSticky }) => (
        <FirstRow
          activeCategory={activeCategory}
          innerRef={firstRowRef}
          localeBoxVisible={localeBoxVisible}
          menu={menu}
          recalculateHeaderHeight={recalculateHeaderHeight}
          searchBoxVisible={searchBoxVisible}
          setActiveCategory={setActiveCategory}
          toggleSearchBoxVisible={toggleSearchBoxVisible}
          visibleFrom={visibleFrom}
        />
      )}
    </b2x.Sticky>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  innerRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  innerRef,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('header-first-row-wrapper bg-white shadow-sm', `d-none d-${visibleFrom}-block`)}
      ref={innerRef}
    >
      <div className={classnames('header-first-row bg-white')}>
        <Container>
          <b2x.Row className="justify-content-center align-items-start flex-nowrap">
            <b2x.Col className="d-flex" size={'auto'}>
              <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
            </b2x.Col>
            <b2x.Col className="d-flex align-items-center justify-content-center">
              <Menu
                activeCategory={activeCategory}
                menu={menu}
                setActiveCategory={setActiveCategory}
                subMenuDropdownRef={subMenuDropdownRef}
              />
            </b2x.Col>
            <b2x.Col className="d-flex justify-content-end" size={'auto'}>
              <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
    </div>
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className="toggles">
      <b2x.Row gap={0}>
        <b2x.Col size={'auto'}>
          <Button
            className="toggle-item"
            iconEnd={{ name: 'search', size: 25 }}
            onClick={toggleSearchBoxVisible}
            variant="blank"
          />
        </b2x.Col>
        {session?.customer ? (
          <b2x.Col size={'auto'}>
            <b2x.router.Link
              className="toggle-item toggle-account px-xl-3 mx-xl-2 text-decoration-none"
              to={getPagePath('SITE_ACCOUNT')}
            >
              <Button
                alignItems="end"
                className="btn-sm text-primary fw-normal lh-sm"
                iconStart={{ name: 'account', size: 25 }}
                label={b2x.untilBreakpoint('lg', currentBreakpoint) ? undefined : t('misc.account')}
                variant="blank"
              />
            </b2x.router.Link>
          </b2x.Col>
        ) : (
          <b2x.Col size={'auto'}>
            <Button
              alignItems="end"
              className="btn-sm toggle-item toggle-account px-xl-3 mx-xl-2 fw-normal lh-sm"
              iconStart={{ name: 'account', size: 25 }}
              label={b2x.untilBreakpoint('lg', currentBreakpoint) ? undefined : t('account.login')}
              onClick={showAccountOffcanvas}
              style={{ paddingBottom: '10px' }}
              variant="blank"
            />
          </b2x.Col>
        )}
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.Col size={'auto'}>
              <b2x.router.Link className="toggle-item text-black" to="/account/area/wishlist">
                <span className="position-relative">
                  <Icon name={'wishlist'} size={25} />
                  {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                    (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                    <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                      {(session.wishlist.products ? session.wishlist.products.length : 0) +
                        (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                    </span>
                  )}
                </span>
              </b2x.router.Link>
            </b2x.Col>
          ) : (
            <b2x.Col size={'auto'}>
              <Button
                className="toggle-item"
                iconEnd={{ name: 'wishlist', size: 25 }}
                onClick={showAccountOffcanvas}
                variant="blank"
              />
            </b2x.Col>
          ))}
        <b2x.Col size={'auto'}>
          <Button className="toggle-item" onClick={showCartOffcanvas} variant="blank">
            <span className="position-relative">
              <Icon name={'cart'} size={25} />
              {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                  {session.cart.itemsNumber}
                </span>
              )}
            </span>
          </Button>
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  small?: boolean;
}

const Logo = ({ recalculateHeaderHeight, small }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image className="logo" onLoad={recalculateHeaderHeight} src={logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item position-relative', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      {firstLevelCategory.link ? (
        <b2x.router.Link
          className={classnames('d-block text-decoration-none text-black px-2 px-xl-3 fw-normal fw-bold', {
            active: activeCategory && activeCategory.id === firstLevelCategory.id,
          })}
          onClick={onActivateCategory}
          onMouseEnter={onActivateCategory}
          onMouseLeave={onDeactivateCategory}
          to={firstLevelCategory.link}
        >
          {firstLevelCategory.label}
        </b2x.router.Link>
      ) : (
        <Button
          className={classnames('d-block text-decoration-none text-black px-2 px-xl-3 fw-normal fw-bold', {
            active: activeCategory && activeCategory.id === firstLevelCategory.id,
          })}
          label={firstLevelCategory.label}
          onClick={onActivateCategory}
          onMouseEnter={onActivateCategory}
          onMouseLeave={onDeactivateCategory}
          variant="blank"
        />
      )}
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'd-block text-decoration-none text-black px-2 px-xl-3 fw-bold',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  },
                  { 'ff-purina': firstLevelCategory.tags?.includes('purinaScript') },
                  { 'text-gold': firstLevelCategory.tags?.includes('gold') }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <>
      <div
        className={classnames({ mounted: isMounted }, 'sub-menu-dropdown position-absolute w-100')}
        ref={subMenuDropdownRef}
      >
        <Container>
          <b2x.Row gap={0}>
            {activeCategory.children.map((secondLevelCategory, index) => (
              <b2x.Col
                className={classnames(
                  { 'bg-gray-200': secondLevelCategory.tags?.includes('dark') },
                  { 'bg-gray-100': !secondLevelCategory.tags?.includes('dark') },
                  { 'double-column': secondLevelCategory.tags?.includes('double') }
                )}
                key={secondLevelCategory.id}
              >
                <div className={classnames('inner-container', 'h-100 py-4')}>
                  <div
                    className={classnames('h-100 px-4', {
                      'border-1 border-start border-gray-300': index > 0 && !secondLevelCategory.tags?.includes('dark'),
                    })}
                  >
                    {secondLevelCategory.content ? (
                      <>
                        {/* Caso in cui la voce di menu ha un contenuto associato */}
                        {b2x.typedContent<HeaderMenuItemContentType>(secondLevelCategory.content, (content) => (
                          <>
                            <h6 className="fw-bold mb-2 py-2">{content.body.title}</h6>
                            {content.body.bannerList && content.body.bannerList.length > 0 && (
                              <b2x.Row className="justify-content-between">
                                {content.body.bannerList.map((slide) => (
                                  <b2x.Col
                                    className="d-flex justify-content-center align-items-center"
                                    key={slide.contentSectionId}
                                    size={content.body.bannerList && content.body.bannerList.length > 1 ? 4 : 12}
                                  >
                                    <div className="position-relative text-center">
                                      {slide.img?.src?.xs ? (
                                        <b2x.ImageFromContentV1 fluid {...slide.img} />
                                      ) : (
                                        slide.icon?.name && (
                                          <div>
                                            {isIconName(slide.icon.name) && (
                                              <Icon
                                                className="bg-white rounded-circle p-3 mb-2"
                                                name={slide.icon.name}
                                                size={72}
                                              />
                                            )}
                                            <h6 className="fw-bold">{slide.icon.label}</h6>
                                          </div>
                                        )
                                      )}
                                      {(slide.cta?.to?.href || slide.cta?.to?.code) && (
                                        <b2x.DeprecatedCta
                                          className={classnames('stretched-link', {
                                            'd-block py-3 px-2 bg-white small fw-bold text-black': slide.cta.label,
                                          })}
                                          cta={slide.cta}
                                        >
                                          {slide.cta.label}
                                        </b2x.DeprecatedCta>
                                      )}
                                    </div>
                                  </b2x.Col>
                                ))}
                              </b2x.Row>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* Caso in cui alla voce di menu non è associato un contenuto */}
                        {secondLevelCategory.children.map((thirdLevelCategory) => (
                          <div key={thirdLevelCategory.id}>
                            {thirdLevelCategory.children.length === 0 && thirdLevelCategory.link ? (
                              <>
                                {/* Caso in cui la voce di menu non ha figli */}
                                <h5 className="fw-bold">
                                  <b2x.router.Link
                                    className="text-black text-decoration-none"
                                    onClick={close}
                                    to={thirdLevelCategory.link}
                                  >
                                    {thirdLevelCategory.label}
                                  </b2x.router.Link>
                                </h5>
                              </>
                            ) : (
                              <>
                                {/* Resto dei casi, la voce di menu ha dei figli */}
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  {thirdLevelCategory.iconName && isIconName(thirdLevelCategory.iconName) && (
                                    <div
                                      className="bg-white rounded-circle d-flex align-items-center justify-content-center me-2"
                                      style={{ height: '30px', width: '30px' }}
                                    >
                                      <Icon name={thirdLevelCategory.iconName} size={20} />
                                    </div>
                                  )}
                                  <h6 className="fw-bold py-2 m-0">{thirdLevelCategory.label}</h6>
                                </div>
                                <ul className="list-unstyled small ms-2" style={{ paddingLeft: '30px' }}>
                                  {thirdLevelCategory.children.map((fourLevelCategory) => (
                                    <li className="mb-1" key={fourLevelCategory.id}>
                                      <b2x.router.Link
                                        className={'text-black text-decoration-none submenu-link'}
                                        onClick={close}
                                        to={fourLevelCategory.link}
                                      >
                                        {fourLevelCategory.label}
                                      </b2x.router.Link>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </b2x.Col>
            ))}
          </b2x.Row>
        </Container>
      </div>
    </>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => (
  <Box onCloseButtonClick={toggleSearchBoxVisible}>
    <b2x.template.TopSuggestions clearIconName="delete" onSuccess={toggleSearchBoxVisible} submitIconName="search" />
    {/* <div className="row justify-content-center">
        <div className="col-12">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div> */}
  </Box>
);

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="position-absolute w-100 bg-white box py-5 shadow" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end mb-4">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
      </Container>
      {children}
    </div>
  );
};
