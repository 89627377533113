import './CustomerForm.scss';

import { b2x } from '@b2x/react/src';

// import { t } from '../i18n/i18n';
import { CustomerDefaultForm } from './CustomerDefaultForm';
import { CustomerEmployeeForm } from './CustomerEmployeeForm';
import { CustomerVetForm } from './CustomerVetForm';
import {
  AdditionalPropertiesChildrenFormValues,
  AdditionalPropertiesChildrenValidationSchema,
  CustomPropertiesChildrenFormValues,
  CustomPropertiesChildrenValidationSchema,
} from './RegistrationChildrenFieldset';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

export type CustomerFormProps = Omit<
  b2x.CustomerFormProps<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    AdditionalPropertiesChildrenFormValues,
    AdditionalPropertiesChildrenValidationSchema,
    CustomPropertiesChildrenFormValues,
    CustomPropertiesChildrenValidationSchema
  >,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'customPropertiesInitialValues'
  | 'customPropertiesValidationSchema'
  | 'validationSchemaSelector'
  | 'additionalPropertiesChildrenInitialValues'
  | 'additionalPropertiesChildren'
  | 'additionalPropertiesChildrenValidationSchema'
  | 'customPropertiesChildrenInitialValues'
  | 'customPropertiesChildrenValidationSchema'
  | 'validationSchemaChildrenSelector'
>;

const minChildren = 1;
const maxChildren = 10;
const minPhoneNumbers = 2;
const maxPhoneNumbers = 2;

export const CustomerForm = ({ customer, ...otherProps }: CustomerFormProps) => {
  return (
    <>
      {customer?.qualifiers?.find((value) => value === 'Vet') ? (
        <CustomerVetForm
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
          customer={customer}
          maxChildren={maxChildren}
          maxPhoneNumbers={maxPhoneNumbers}
          minChildren={minChildren}
          minPhoneNumbers={minPhoneNumbers}
        />
      ) : customer?.qualifiers?.find((value) => value === 'Employee') ? (
        <CustomerEmployeeForm
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
          customer={customer}
          maxChildren={maxChildren}
          maxPhoneNumbers={maxPhoneNumbers}
          minChildren={minChildren}
          minPhoneNumbers={minPhoneNumbers}
        />
      ) : (
        <CustomerDefaultForm
          {...otherProps}
          customer={customer}
          maxChildren={maxChildren}
          maxPhoneNumbers={maxPhoneNumbers}
          minChildren={minChildren}
          minPhoneNumbers={minPhoneNumbers}
        />
      )}
    </>
  );
};
