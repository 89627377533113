import './PriceBlock.scss';

import { b2x } from '@b2x/react/src';

export type PriceBlockProps = b2x.PriceBlockProps;

export const PriceBlock = (props: PriceBlockProps) => {
  return (
    <b2x.PriceBlock
      {...props}
      classNames={{
        discountPercentage: 'text-white d-flex align-items-center',
        price: 'fw-bold',
        specialPrice: 'fw-bold',
      }}
    />
  );
};
