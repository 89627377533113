import './CustomerForm.scss';

import { b2x } from '@b2x/react/src';

// import { t } from '../i18n/i18n';
import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import {
  additionalPropertiesChildren,
  AdditionalPropertiesChildrenFormValues,
  AdditionalPropertiesChildrenValidationSchema,
  childrenValidationSchemaSelector,
  CustomPropertiesChildrenFormValues,
  CustomPropertiesChildrenValidationSchema,
  RegistrationChildrenFieldset,
} from './RegistrationChildrenFieldset';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

export type CustomerDefaultFormProps = Omit<
  b2x.CustomerFormProps<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    AdditionalPropertiesChildrenFormValues,
    AdditionalPropertiesChildrenValidationSchema,
    CustomPropertiesChildrenFormValues,
    CustomPropertiesChildrenValidationSchema
  >,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'customPropertiesInitialValues'
  | 'customPropertiesValidationSchema'
  | 'validationSchemaSelector'
  | 'additionalPropertiesChildrenInitialValues'
  | 'additionalPropertiesChildren'
  | 'additionalPropertiesChildrenValidationSchema'
  | 'customPropertiesChildrenInitialValues'
  | 'customPropertiesChildrenValidationSchema'
  | 'validationSchemaChildrenSelector'
>;

export const CustomerDefaultForm = ({ maxChildren, minChildren, ...otherProps }: CustomerDefaultFormProps) => {
  const misceContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  return (
    <b2x.CustomerFormHelper<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >
      additionalPropertiesChildren={additionalPropertiesChildren}
      maxChildren={10}
      minChildren={1}
      validationSchemaSelector={{
        birthdate: true,
        children: childrenValidationSchemaSelector,
        name: true,
        phoneNumber: false,
        profile: false,
        sex: false,
        surname: true,
      }}
      {...otherProps}
    >
      {({ content, fieldsHelper, formik, mode }) => (
        <>
          <div className="mb-3 pb-5 border-bottom">
            <b2x.Row gap={3}>
              <b2x.Col size={{ xl: 6, xs: 12 }}>
                <b2x.FormGroup {...fieldsHelper.name.formGroup} noMarginBottom>
                  <b2x.TextInput {...fieldsHelper.name.textInput} />
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={{ xl: 6, xs: 12 }}>
                <b2x.FormGroup {...fieldsHelper.surname.formGroup} noMarginBottom>
                  <b2x.TextInput {...fieldsHelper.surname.textInput} />
                </b2x.FormGroup>
              </b2x.Col>
              <b2x.Col size={12}>
                <b2x.FormGroup {...fieldsHelper.birthdate.formGroup} noMarginBottom>
                  <b2x.DateInput {...fieldsHelper.birthdate.dateInput} />
                </b2x.FormGroup>
              </b2x.Col>
              {mode === 'registration' && (
                <>
                  <b2x.Col size={{ xl: 6, xs: 12 }}>
                    <b2x.FormGroup {...fieldsHelper.email.formGroup} noMarginBottom>
                      <b2x.TextInput {...fieldsHelper.email.textInput} />
                    </b2x.FormGroup>
                  </b2x.Col>
                  <b2x.Col size={{ xl: 6, xs: 12 }}>
                    <b2x.FormGroup {...fieldsHelper.password.formGroup} noMarginBottom>
                      <b2x.PasswordInput {...fieldsHelper.password.passwordInput} />
                    </b2x.FormGroup>
                  </b2x.Col>
                </>
              )}
            </b2x.Row>
          </div>
          <RegistrationChildrenFieldset fieldsHelper={fieldsHelper} formikValues={formik.values} />
          {(mode === 'registration' || mode === 'updateRequiredFields') && (
            <b2x.Row className="mb-3">
              {content?.body.newsletter?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.newsletterConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.newsletterConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              {content?.body.profiling?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.profilingConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.profilingConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              {content?.body.marketing?.enabled && (
                <b2x.Col size={12}>
                  <b2x.FormGroup {...fieldsHelper.marketingConsent.formGroup} noMarginBottom>
                    <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.marketingConsent.checkbox} />
                  </b2x.FormGroup>
                </b2x.Col>
              )}
              <b2x.Col size={12}>
                <b2x.FormGroup
                  {...fieldsHelper.privacyConsent.formGroup}
                  // label={t('form.CustomerForm.privacyConsent.label')}
                  noMarginBottom
                >
                  {misceContent?.body.privacyBoxArea && (
                    <div className="privacy-box pt-3 my-3">
                      <p className="px-3 py-1 fw-light extra-small">
                        {b2x.formatHtml(misceContent.body.privacyBoxArea)}
                      </p>
                    </div>
                  )}
                  <b2x.Checkbox labelClassName="extra-small" {...fieldsHelper.privacyConsent.checkbox} />
                </b2x.FormGroup>
              </b2x.Col>
            </b2x.Row>
          )}
          {fieldsHelper.buttons.cancel ? (
            <b2x.Row>
              <b2x.Col size={6}>
                <b2x.Div display={'grid'}>
                  <b2x.Button {...fieldsHelper.buttons.cancel} />
                </b2x.Div>
              </b2x.Col>
              <b2x.Col size={6}>
                <b2x.Div display={'grid'}>
                  <b2x.Button {...fieldsHelper.buttons.submit} />
                </b2x.Div>
              </b2x.Col>
            </b2x.Row>
          ) : (
            <b2x.Div display={'grid'}>
              {Object.keys(formik.errors).length > 0 && (
                <p className="small text-danger">{t('misc.invalid_form_values')}</p>
              )}
              <b2x.Button {...fieldsHelper.buttons.submit} />
            </b2x.Div>
          )}
        </>
      )}
    </b2x.CustomerFormHelper>
  );
};
