import {
  AssembledComponentApiDto,
  AssembledComponentSkuApiDto,
  AssembledSkuToCartRequestApiDto,
  ProductApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCartApi } from './api/useCartApi';
import { useProductsApi } from './api/useProductsApi';
import { useAppStaticContext } from './AppContext';
import { useSearch } from './useSearch';

export interface UseProductAssemblerProps {
  lonelySkuAutomaticAddition?: boolean;
  productId: string | undefined;
}

export type AssembledProduct = {
  assembledComponents?: Array<AssembledComponent>;
  completed: boolean;
  defaultQty?: number;
  maxQty?: number;
  minQty?: number;
  totalCrossedOutPrice?: number;
  totalDiscount?: number;
  totalPrice: number;
};

export type AssembledComponent = Omit<AssembledComponentApiDto, 'componentSkus'> & {
  completed: boolean;
  componentSkus?: Array<AssembledComponentSku>;
  curQty: number;
};

export type AssembledComponentSku = AssembledComponentSkuApiDto & {
  addEnabled: boolean;
  addQuantity(): void;
  inCurrentFilter: boolean;
  quantity: number;
  remove(): void;
  removeEnabled: boolean;
  removeQuantity(): void;
};

export const useProductAssembler = ({ lonelySkuAutomaticAddition, productId }: UseProductAssemblerProps) => {
  const [product, setProduct] = React.useState<ProductApiDto>();

  const { getProduct } = useProductsApi();
  const { onAddToCartSuccess } = useAppStaticContext();

  React.useEffect(() => {
    productId &&
      getProduct(productId, {
        populate: {
          assembledComponents: {
            componentSkus: {
              price: true,
              sku: {
                price: true,
                product: { attributes: true, brand: true },
              },
            },
          },
          attributes: true,
          brand: true,
          skus: { price: true },
        },
      }).then((response) => {
        setProduct(response.data);
      });
  }, [getProduct, productId]);

  const { addAssembledSku } = useCartApi();

  type Selection = Record<
    string, // component id
    | Array<{
        id: string;
        quantity: number;
      }>
    | undefined
  >;

  const [selection, setSelection] = React.useState<Selection>({});

  React.useEffect(() => {
    console.log('selection', selection);
  }, [selection]);

  const getComponentCurrentQuantity = React.useCallback(
    (
      selectionForThisComponent?: Array<{
        id: string;
        quantity: number;
      }>
    ) => {
      return selectionForThisComponent?.reduce<number>((prev, curr) => prev + curr.quantity, 0) ?? 0;
    },
    []
  );

  // const getComponentSku = React.useCallback(
  //   (assembledComponentId: string, skuId: string) => {
  //     const componentSku = product?.assembledComponents
  //       ?.find((assembledComponent) => assembledComponent.id === assembledComponentId)
  //       ?.componentSkus?.find((_componentSku) => _componentSku.sku?.id === skuId);
  //     if (!componentSku) {
  //       throw new Error(`ComponentSku non trovato per assembledComponentId ${assembledComponentId} e skuId ${skuId}`);
  //     }
  //     return componentSku;
  //   },
  //   [product?.assembledComponents]
  // );

  const addSkuQuantity = React.useCallback(
    (component: AssembledComponentApiDto, skuId: string) => {
      setSelection((prevState) => {
        let selectionForThisComponent = prevState[component.id];
        if (selectionForThisComponent === undefined) {
          selectionForThisComponent = [];
        }
        const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);
        if (currentQuantityForThisComponent === component.maxQty) {
          throw new Error('useProductAssembler, impossibile aggiungere un altro sku, si è già raggiunta la maxQty.');
        }
        const skuInSelection = selectionForThisComponent.find(({ id }) => id === skuId);
        const skuAlreadyInSelection = skuInSelection !== undefined;
        const newState = {
          ...prevState,
          [component.id]: skuAlreadyInSelection
            ? selectionForThisComponent.map(({ id, quantity }) => ({
                id: id,
                quantity: id === skuId ? quantity + 1 : quantity,
              }))
            : selectionForThisComponent.concat({ id: skuId, quantity: 1 }),
        };
        return newState;
      });
    },
    [getComponentCurrentQuantity]
  );

  const removeSkuQuantity = React.useCallback((component: AssembledComponentApiDto, skuId: string) => {
    setSelection((prevState) => {
      const selectionForThisComponent = prevState[component.id];
      if (selectionForThisComponent === undefined) {
        throw new Error('useProductAssembler, impossibile rimuovere uno sku da un component senza selezioni');
      }
      const skuInSelection = selectionForThisComponent.find(({ id }) => id === skuId);
      if (skuInSelection === undefined) {
        throw new Error(
          'useProductAssembler, impossibile rimuovere uno sku che non è stato precedentemente selezionato'
        );
      }
      const isNewQuantityZero = skuInSelection.quantity - 1 === 0;

      const newState = {
        ...prevState,
        [component.id]: isNewQuantityZero
          ? selectionForThisComponent.filter(({ id }) => id !== skuId)
          : selectionForThisComponent.map(({ id, quantity }) => ({
              id: id,
              quantity: id === skuId ? quantity - 1 : quantity,
            })),
      };
      return newState;
    });
  }, []);

  const removeSku = React.useCallback((component: AssembledComponentApiDto, skuId: string) => {
    setSelection((prevState) => {
      const selectionForThisComponent = prevState[component.id];
      if (selectionForThisComponent === undefined) {
        throw new Error('useProductAssembler, impossibile rimuovere uno sku da un component senza selezioni');
      }
      const newState = {
        ...prevState,
        [component.id]: selectionForThisComponent.filter(({ id }) => id !== skuId),
      };
      return newState;
    });
  }, []);

  const productsIds = React.useMemo(
    () =>
      product?.assembledComponents?.reduce<Array<string>>((curr, prev) => {
        prev.componentSkus?.map(
          (componentSkus) => componentSkus.sku?.product?.id && curr.push(componentSkus.sku.product.id)
        );
        return curr;
      }, []),
    [product?.assembledComponents]
  );

  const { searchResult } = useSearch({
    basePath: window.location.pathname,
    defaultPageSize: productsIds?.length ?? 0,
    populate: {
      filters: true,
      items: true,
    },
    products: productsIds,
    thingsToLoadBeforeSearch: [productsIds],
  });

  const assembledProduct = React.useMemo<AssembledProduct | undefined>(() => {
    const totalCrossedOutPrice =
      product?.assembledComponents?.reduce<number>((acc, assembledComponent) => {
        const selectionForThisComponent = selection[assembledComponent.id];
        return (
          acc +
          (assembledComponent.componentSkus?.reduce<number>((acc2, componentSku) => {
            const quantity = selectionForThisComponent?.find(({ id }) => componentSku.sku?.id === id)?.quantity ?? 0;
            return acc2 + quantity * componentSku.multiplier * (componentSku.price?.crossedOutValueUnrounded ?? 0);
          }, 0) ?? 0)
        );
      }, 0) ?? 0;

    const totalPrice =
      product?.assembledComponents?.reduce<number>((acc, assembledComponent) => {
        const selectionForThisComponent = selection[assembledComponent.id];
        return (
          acc +
          (assembledComponent.componentSkus?.reduce<number>((acc2, componentSku) => {
            const quantity = selectionForThisComponent?.find(({ id }) => componentSku.sku?.id === id)?.quantity ?? 0;
            return acc2 + quantity * componentSku.multiplier * (componentSku.price?.valueUnrounded ?? 0);
          }, 0) ?? 0)
        );
      }, 0) ?? 0;

    return product && searchResult
      ? {
          assembledComponents: product.assembledComponents?.map((assembledComponent) => {
            const selectionForThisComponent = selection[assembledComponent.id];
            const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);

            return {
              ...assembledComponent,
              completed: currentQuantityForThisComponent >= assembledComponent.minQty,
              componentSkus: assembledComponent.componentSkus?.map((componentSku) => {
                if (componentSku.sku === undefined) {
                  throw new Error('componentSku.sku undefined');
                }
                const quantity =
                  selectionForThisComponent?.find(({ id }) => componentSku.sku?.id === id)?.quantity ?? 0;
                return {
                  ...componentSku,
                  addEnabled: currentQuantityForThisComponent < assembledComponent.maxQty,
                  addQuantity: () => {
                    componentSku.sku && addSkuQuantity(assembledComponent, componentSku.sku.id);
                  },
                  inCurrentFilter: searchResult.items?.some(({ id }) => id === componentSku.sku?.product?.id) ?? true,
                  quantity: quantity,
                  remove: () => {
                    componentSku.sku && removeSku(assembledComponent, componentSku.sku.id);
                  },
                  removeEnabled: quantity > 0,
                  removeQuantity: () => {
                    componentSku.sku && removeSkuQuantity(assembledComponent, componentSku.sku.id);
                  },
                };
              }),
              curQty: currentQuantityForThisComponent,
              selectedComponentSkus: [],
            };
          }),
          completed:
            product.assembledComponents?.reduce<boolean>((prev, curr) => {
              const selectionForThisComponent = selection[curr.id];
              const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);
              const isThisComponentCompleted = currentQuantityForThisComponent >= curr.minQty;
              return prev && isThisComponentCompleted;
            }, true) ?? false,
          defaultQty: product.assembledComponents?.reduce<number>((acc, assembledComponent) => {
            acc = acc + assembledComponent.defaultQty;
            return acc;
          }, 0),
          maxQty: product.assembledComponents?.reduce<number>((acc, assembledComponent) => {
            acc = acc + assembledComponent.maxQty;
            return acc;
          }, 0),
          minQty: product.assembledComponents?.reduce<number>((acc, assembledComponent) => {
            acc = acc + assembledComponent.minQty;
            return acc;
          }, 0),
          totalCrossedOutPrice: totalCrossedOutPrice,
          totalDiscount: totalCrossedOutPrice - totalPrice,
          totalPrice: totalPrice,
        }
      : undefined;
  }, [addSkuQuantity, getComponentCurrentQuantity, product, removeSku, removeSkuQuantity, searchResult, selection]);

  const prices: Record<string, number> | undefined = React.useMemo(() => {
    const result: Record<string, number> = {};
    product?.assembledComponents?.forEach((assembledComponent) => {
      assembledComponent.componentSkus?.forEach((componentSku) => {
        if (componentSku.sku && componentSku.price?.value) {
          result[componentSku.sku.id] = componentSku.price.value * componentSku.multiplier;
        }
      });
    });
    return result;
  }, [product?.assembledComponents]);

  const addToCart = React.useCallback(() => {
    product?.skus &&
      product.skus[0]?.id &&
      assembledProduct &&
      addAssembledSku({
        componentSkus: Object.entries(selection).reduce<Array<AssembledSkuToCartRequestApiDto>>(
          (prev, [assembledComponentId, skus]) =>
            prev.concat(
              skus
                ? skus.map<AssembledSkuToCartRequestApiDto>(({ id, quantity }) => {
                    // const componentSku = getComponentSku(assembledComponentId, id);
                    return {
                      assembledComponentId: assembledComponentId,
                      id: id,
                      price: prices[id] * quantity,
                      quantity: quantity,
                    };
                  })
                : []
            ),
          []
        ),
        id: product.skus[0].id,
        price: assembledProduct.totalPrice,
        quantity: 1,
      }).then(() => {
        onAddToCartSuccess && onAddToCartSuccess();
      });
  }, [addAssembledSku, assembledProduct, onAddToCartSuccess, prices, product?.skus, selection]);

  React.useEffect(() => {
    if (lonelySkuAutomaticAddition) {
      product?.assembledComponents?.forEach((assembledComponent) => {
        if (
          assembledComponent.componentSkus?.length === 1 &&
          assembledComponent.defaultQty === 1 &&
          assembledComponent.minQty === 1 &&
          assembledComponent.maxQty === 1
        ) {
          const componentSkuId = assembledComponent.componentSkus.at(0)?.sku?.id;

          componentSkuId && addSkuQuantity(assembledComponent, componentSkuId);
        }
      });
    }
  }, [addSkuQuantity, lonelySkuAutomaticAddition, product?.assembledComponents]);

  return { addToCart, assembledProduct, product, searchResult };
};
