import './CartPage.scss';

import { b2x } from '@b2x/react/src';

import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export type CartPageProps = b2x.CartPageProps;

export const CartPage = (props: CartPageProps) => {
  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <Page>
      <div className="cart-page">
        <h1 className="text-center h2 mb-4 fw-bold">{t('cart.title')}</h1>

        {miscellaneousContent && (
          <b2x.CartPage
            {...props}
            cartSkusToMoveCampaignsCodes={
              miscellaneousContent.body.cartPage?.cartSkusToMoveCampaignsCodes?.map(
                ({ cartSkusToMoveCampaignCode }) => cartSkusToMoveCampaignCode ?? ''
              ) ?? undefined
            }
            enableContinueShopping
          />
        )}
      </div>
    </Page>
  );
};
