import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { useAppContext } from './AppContext';

export interface UseStickersProductProps {
  fixedTags?: Array<string>; // utile per "forzare" degli stickers, anche senza che il prodotto abbia quel TAG.
  product?: ProductApiDto;
}

export const useStickersProduct = ({ fixedTags, product }: UseStickersProductProps) => {
  const { stickersProductContent } = useAppContext();

  const stickersProduct = stickersProductContent?.body.stickers?.filter(
    (sticker) => sticker.code && (product?.tags?.includes(sticker.code) || fixedTags?.includes(sticker.code))
  );

  const stickersProductWithImage = stickersProduct?.filter((filteredSticker) => filteredSticker.image?.src?.xs);
  const stickersProductWithoutImage = stickersProduct?.filter((filteredSticker) => !filteredSticker.image?.src?.xs);

  return { stickersProduct, stickersProductWithImage, stickersProductWithoutImage };
};
