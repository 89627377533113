import { b2x } from '@b2x/react/src';

import { MiscellaneousContentType } from './contentTypes';

export type ServiceContactsSubpageProps = b2x.ServiceContactsSubpageProps;

export const ServiceContactsSubpage = (props: ServiceContactsSubpageProps) => {
  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  return (
    <>
      <b2x.ServiceContactsSubpage {...props} contactUs={{ disableSatisfiedForm: true, showSatisfiedForm: false }} />
      <div className="pt-3">
        {b2x.formatHtml(miscellaneousContent?.body.contactUsPage?.notFoundLabel)}
        {/* {miscellaneousContent?.body.contactUsPage?.notFoundCta && (
          <b2x.CtaFromContent
            ctaProps={{
              button: {
                className: 'ps-1 fw-normal btn-link',
              },
            }}
            {...miscellaneousContent.body.contactUsPage.notFoundCta}
          />
        )} */}
      </div>
    </>
  );
};
