import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { t } from '../i18n/i18n';
import { PetBreedOptions, PetNutritionOptions, PetSizeCodeOptions, PetWetFoodBrandOptions } from './CustomOptions';

interface AdditionalPropertiesFormValues {}

interface CustomPropertiesFormValues {}

export type AdditionalPropertiesChildrenFormValues = {
  PET_BREED: b2x.formikString; // Razza (Select che cambia in base a Cane / Gatto)
  PET_FUR_TYPE: b2x.formikString; // Tipologia pelo (Radio - Gatto lungo o corto)
  PET_INDOOR: b2x.formikBooleanAsString; // Dove vive (Radio - Gatto - in casa / aperto e/o fuori)
  PET_NEUTERED: b2x.formikBooleanAsString; // Sterelizzato (Radio - Gatto - Si / No)
  PET_NUTRITION: b2x.formikString; // Alimentazione preferita (Select che cambia in base a Cane / Gatto)
  PET_SIZE_CODE: b2x.formikString; // Taglia (Select - solo cane)
  PET_TYPE_CODE: b2x.formikEnum<'CAT' | 'DOG'>; // Radio (Cane / Gatto)
  PET_WET_FOOD_BRAND: b2x.formikString; // Brand purina preferito (Select che cambia in base a Cane / Gatto)
};

export type AdditionalPropertiesChildrenValidationSchema = b2x.yup.ObjectSchema<{
  PET_BREED: b2x.yup.StringSchema;
  PET_FUR_TYPE: b2x.yup.StringSchema;
  PET_INDOOR: b2x.yup.BooleanSchema;
  PET_NEUTERED: b2x.yup.BooleanSchema;
  PET_NUTRITION: b2x.yup.StringSchema;
  PET_SIZE_CODE: b2x.yup.StringSchema;
  PET_TYPE_CODE: b2x.yup.StringSchema;
  PET_WET_FOOD_BRAND: b2x.yup.StringSchema;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type CustomPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type CustomPropertiesChildrenValidationSchema = b2x.yup.ObjectSchema<{}>;

export interface RegistrationChildrenFieldsetProps {
  fieldsHelper: b2x.CustomerFormFieldsHelper<
    AdditionalPropertiesChildrenFormValues,
    CustomPropertiesChildrenFormValues
  >;
  formikValues: b2x.CustomerFormValues<
    AdditionalPropertiesFormValues,
    CustomPropertiesFormValues,
    AdditionalPropertiesChildrenFormValues,
    CustomPropertiesChildrenFormValues
  >;
}

export const childrenValidationSchemaSelector = {
  birthdate: true,
  name: true,
  sex: false,
  surname: false,
};

export const additionalPropertiesChildren = {
  formValues: {
    PET_BREED: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_BREED ?? '',
    PET_FUR_TYPE: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_FUR_TYPE ?? '',
    PET_INDOOR: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_INDOOR ?? '',
    PET_NEUTERED: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_NEUTERED ?? '',
    PET_NUTRITION: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_NUTRITION ?? '',
    PET_SIZE_CODE: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_SIZE_CODE ?? '',
    PET_TYPE_CODE: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_TYPE_CODE ?? '',
    PET_WET_FOOD_BRAND: (
      values: b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
    ) => values.additionalProperties?.PET_WET_FOOD_BRAND ?? '',
  },
  initialValues: {
    PET_BREED: b2x.getInitialString(),
    PET_FUR_TYPE: b2x.getInitialString(),
    PET_INDOOR: b2x.getInitialBooleanAsString(),
    PET_NEUTERED: b2x.getInitialBooleanAsString(),
    PET_NUTRITION: b2x.getInitialString(),
    PET_SIZE_CODE: b2x.getInitialString(),
    PET_TYPE_CODE: b2x.getInitialEnum<'CAT' | 'DOG'>(),
    PET_WET_FOOD_BRAND: b2x.getInitialString(),
  },
  validationSchema: b2x.yup.object({
    PET_BREED: b2x.yup.string().when('PET_TYPE_CODE', {
      is: (petType: string) => petType,
      then: (schema) => schema,
    }),
    PET_FUR_TYPE: b2x.yup.string().when('PET_TYPE_CODE', {
      is: 'CAT',
      then: (schema) => schema,
    }),
    PET_INDOOR: b2x.yup.boolean().when('PET_TYPE_CODE', {
      is: 'CAT',
      then: (schema) => schema,
    }),
    PET_NEUTERED: b2x.yup.boolean().when('PET_TYPE_CODE', {
      is: 'CAT',
      then: (schema) => schema,
    }),
    PET_NUTRITION: b2x.yup.string().when('PET_TYPE_CODE', {
      is: (petType: string) => petType,
      then: (schema) => schema,
    }),
    PET_SIZE_CODE: b2x.yup.string().when('PET_TYPE_CODE', {
      is: 'DOG',
      then: (schema) => schema.required(),
    }),
    PET_TYPE_CODE: b2x.yup.string().required(),
    PET_WET_FOOD_BRAND: b2x.yup.string().when('PET_TYPE_CODE', {
      is: (petType: string) => petType,
      then: (schema) => schema,
    }),
  }),
};

export const RegistrationChildrenFieldset = ({ fieldsHelper, formikValues }: RegistrationChildrenFieldsetProps) => {
  return (
    <div className="mb-3 pb-5">
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup {...fieldsHelper.enableChildren.formGroup} noMarginBottom>
            <b2x.Radios {...fieldsHelper.enableChildren.radios} />
          </b2x.FormGroup>
        </b2x.Col>
        {formikValues.enableChildren === 'true' && (
          <b2x.Col size={12}>
            <b2x.FieldArray<
              b2x.CustomerChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
            >
              {...fieldsHelper.children.fieldArray}
            >
              {(arrayHelpers) => (
                <b2x.FormGroup {...fieldsHelper.children.formGroup} className="mt-3" label={undefined} noMarginBottom>
                  {fieldsHelper.children.fields.map(({ birthdate, name, removeButton }, index) => (
                    <div className="bg-gray-100 p-3 mb-3 position-relative" key={name.textInput.name}>
                      <b2x.Row gap={3}>
                        <b2x.Col size={6}>
                          <b2x.FormGroup {...name.formGroup} noMarginBottom>
                            <b2x.TextInput {...name.textInput} />
                          </b2x.FormGroup>
                        </b2x.Col>
                        <b2x.Col size={6}>
                          <b2x.FormGroup {...birthdate.formGroup} noMarginBottom>
                            <b2x.DateInput {...birthdate.dateInput} />
                          </b2x.FormGroup>
                        </b2x.Col>
                        <b2x.Col size={12}>
                          <b2x.FormGroup
                            label={t('misc.PET_TYPE_CODE.label')}
                            labelClassName="mb-2"
                            names={[`children[${index}].additionalProperties.PET_TYPE_CODE`]}
                            noMarginBottom
                          >
                            <b2x.Radio
                              className="small"
                              id={`children[${index}].PET_TYPE_CODE_CAT`}
                              inline
                              label={t('misc.PET_TYPE_CODE.options.cat')}
                              name={`children[${index}].additionalProperties.PET_TYPE_CODE`}
                              value={'CAT'}
                            />
                            <b2x.Radio
                              className="small"
                              id={`children[${index}].PET_TYPE_CODE_DOG`}
                              inline
                              label={t('misc.PET_TYPE_CODE.options.dog')}
                              name={`children[${index}].additionalProperties.PET_TYPE_CODE`}
                              value="DOG"
                            />
                          </b2x.FormGroup>
                        </b2x.Col>
                        {formikValues.children[index].additionalProperties?.PET_TYPE_CODE === 'CAT' ? (
                          <>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_BREED.label')}
                                names={[`children[${index}].additionalProperties.PET_BREED`]}
                                noMarginBottom
                              >
                                <b2x.Select
                                  includeEmptyOption={false}
                                  name={`children[${index}].additionalProperties.PET_BREED`}
                                >
                                  <PetBreedOptions type="cat" />
                                </b2x.Select>
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_NUTRITION.label')}
                                names={[`children[${index}].additionalProperties.PET_NUTRITION`]}
                                noMarginBottom
                              >
                                <b2x.Select
                                  includeEmptyOption={false}
                                  name={`children[${index}].additionalProperties.PET_NUTRITION`}
                                >
                                  <PetNutritionOptions />
                                </b2x.Select>
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_WET_FOOD_BRAND.label')}
                                names={[`children[${index}].additionalProperties.PET_WET_FOOD_BRAND`]}
                                noMarginBottom
                              >
                                <b2x.Select
                                  includeEmptyOption={false}
                                  name={`children[${index}].additionalProperties.PET_WET_FOOD_BRAND`}
                                >
                                  <PetWetFoodBrandOptions type="cat" />
                                </b2x.Select>
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_NEUTERED.label')}
                                labelClassName="mb-2"
                                names={[`children[${index}].additionalProperties.PET_NEUTERED`]}
                                noMarginBottom
                              >
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_NEUTERED_YES`}
                                  inline
                                  label={t('misc.PET_NEUTERED.options.yes')}
                                  name={`children[${index}].additionalProperties.PET_NEUTERED`}
                                  value={'true'}
                                />
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_NEUTERED_NO`}
                                  inline
                                  label={t('misc.PET_NEUTERED.options.no')}
                                  name={`children[${index}].additionalProperties.PET_NEUTERED`}
                                  value="false"
                                />
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_FUR_TYPE.label')}
                                labelClassName="mb-2"
                                names={[`children[${index}].additionalProperties.PET_FUR_TYPE`]}
                                noMarginBottom
                              >
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_FUR_TYPE_SHORT`}
                                  inline
                                  label={t('misc.PET_FUR_TYPE.options.short')}
                                  name={`children[${index}].additionalProperties.PET_FUR_TYPE`}
                                  value={'Corto'}
                                />
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_FUR_TYPE_LONG`}
                                  inline
                                  label={t('misc.PET_FUR_TYPE.options.long')}
                                  name={`children[${index}].additionalProperties.PET_FUR_TYPE`}
                                  value="Lungo"
                                />
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col size={6}>
                              <b2x.FormGroup
                                label={t('misc.PET_INDOOR.label')}
                                labelClassName="mb-2"
                                names={[`children[${index}].additionalProperties.PET_INDOOR`]}
                                noMarginBottom
                              >
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_INDOOR_IN`}
                                  inline
                                  label={t('misc.PET_INDOOR.options.indoor')}
                                  name={`children[${index}].additionalProperties.PET_INDOOR`}
                                  value={'true'}
                                />
                                <b2x.Radio
                                  className="small"
                                  id={`children[${index}].PET_INDOOR_OUT`}
                                  inline
                                  label={t('misc.PET_INDOOR.options.outdoor')}
                                  name={`children[${index}].additionalProperties.PET_INDOOR`}
                                  value="false"
                                />
                              </b2x.FormGroup>
                            </b2x.Col>
                          </>
                        ) : (
                          formikValues.children[index].additionalProperties?.PET_TYPE_CODE === 'DOG' && (
                            <>
                              <b2x.Col size={6}>
                                <b2x.FormGroup
                                  label={t('misc.PET_BREED.label')}
                                  names={[`children[${index}].additionalProperties.PET_BREED`]}
                                  noMarginBottom
                                >
                                  <b2x.Select
                                    includeEmptyOption={false}
                                    name={`children[${index}].additionalProperties.PET_BREED`}
                                  >
                                    <PetBreedOptions type="dog" />
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </b2x.Col>
                              <b2x.Col size={6}>
                                <b2x.FormGroup
                                  label={t('misc.PET_SIZE_CODE.label')}
                                  names={[`children[${index}].additionalProperties.PET_SIZE_CODE`]}
                                  noMarginBottom
                                  required
                                >
                                  <b2x.Select
                                    includeEmptyOption={false}
                                    name={`children[${index}].additionalProperties.PET_SIZE_CODE`}
                                  >
                                    <PetSizeCodeOptions />
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </b2x.Col>
                              <b2x.Col size={6}>
                                <b2x.FormGroup
                                  label={t('misc.PET_NUTRITION.label')}
                                  names={[`children[${index}].additionalProperties.PET_NUTRITION`]}
                                  noMarginBottom
                                >
                                  <b2x.Select
                                    includeEmptyOption={false}
                                    name={`children[${index}].additionalProperties.PET_NUTRITION`}
                                  >
                                    <PetNutritionOptions />
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </b2x.Col>
                              <b2x.Col size={6}>
                                <b2x.FormGroup
                                  label={t('misc.PET_WET_FOOD_BRAND.label')}
                                  names={[`children[${index}].additionalProperties.PET_WET_FOOD_BRAND`]}
                                  noMarginBottom
                                >
                                  <b2x.Select
                                    includeEmptyOption={false}
                                    name={`children[${index}].additionalProperties.PET_WET_FOOD_BRAND`}
                                  >
                                    <PetWetFoodBrandOptions type="dog" />
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </b2x.Col>
                            </>
                          )
                        )}
                      </b2x.Row>

                      {fieldsHelper.children.fieldArray.min && index >= fieldsHelper.children.fieldArray.min && (
                        <div className="position-absolute end-0 top-0 px-3 pt-1">
                          <Button
                            {...removeButton}
                            className="extra-small fw-normal text-decoration-underline"
                            disabled={arrayHelpers.removeDisabled}
                            iconEnd={{ name: 'delete', size: 12 }}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            variant="blank"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  {fieldsHelper.children.fieldArray.max &&
                    formikValues.children.length < fieldsHelper.children.fieldArray.max && (
                      <div className="d-grid bg-gray-100 px-3 py-2">
                        <Button
                          {...fieldsHelper.children.addButton}
                          className="fw-normal"
                          disabled={arrayHelpers.pushDisabled}
                          iconEnd={{ name: 'plus', size: 14 }}
                          justifyContent="between"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => {
                            arrayHelpers.push({
                              additionalProperties: {
                                PET_BREED: b2x.getInitialString(),
                                PET_FUR_TYPE: b2x.getInitialString(),
                                PET_INDOOR: b2x.getInitialBooleanAsString(),
                                PET_NEUTERED: b2x.getInitialBooleanAsString(),
                                PET_NUTRITION: b2x.getInitialString(),
                                PET_SIZE_CODE: b2x.getInitialString(),
                                PET_TYPE_CODE: b2x.getInitialEnum(),
                                PET_WET_FOOD_BRAND: b2x.getInitialString(),
                              },
                              birthdate: b2x.getInitialDate(),
                              id: b2x.getInitialString('0'),
                              name: b2x.getInitialString(),
                              sex: b2x.getInitialEnum(),
                              surname: b2x.getInitialString(),
                            });
                          }}
                          variant="blank"
                        />
                      </div>
                    )}
                </b2x.FormGroup>
              )}
            </b2x.FieldArray>
          </b2x.Col>
        )}
      </b2x.Row>
    </div>
  );
};
