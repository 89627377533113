// Styles must use direct files imports
import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss/pagination'; // Pagination module
import 'swiper/scss'; // core Swiper
import './Slider.scss'; // b2x customization

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
// Core modules imports are same as usual
import SwiperInterface, { Autoplay, Navigation, Pagination } from 'swiper';
// Direct React component imports
import { Swiper } from 'swiper/react';

import { SliderButton } from './SliderButton';

export interface SliderProps {
  autoHeight?: boolean;
  centerInsufficientSlides?: boolean;
  centeredSlides?: boolean;
  children?: React.ReactNode;
  className?: string;
  loop?: boolean;
  navigation?: boolean;
  navigationButtonSize?: 'large' | 'small';
  onAfterInit?(swiper: SwiperInterface): void;
  onSwiper?(swiper: SwiperInterface): void;
  pagination?: boolean;
  responsive?: Partial<Record<SliderBreakpoint, SliderResponsiveProps>>;
  slideContentCentered?: boolean;
  sliderAutoPlay?: number;
  slidesPerView: number | 'auto';
  spaceBetween?: number;
  speed?: number;
  vertical?: boolean;
  watchSlidesProgress?: boolean;
}
export type SliderBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type SliderResponsiveProps = Pick<SliderProps, 'slidesPerView'>;

export const Slider = ({
  autoHeight,
  centerInsufficientSlides,
  centeredSlides,
  children,
  className,
  loop,
  navigation,
  navigationButtonSize = 'large',
  onAfterInit,
  onSwiper,
  pagination,
  responsive,
  slideContentCentered,
  sliderAutoPlay,
  slidesPerView,
  spaceBetween,
  speed,
  vertical,
  watchSlidesProgress,
}: SliderProps): React.ReactElement => {
  const breakpoint = b2x.useBreakpoint();

  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className={className} id={sliderUUID}>
      <Swiper
        autoHeight={autoHeight ? true : false}
        autoplay={sliderAutoPlay ? { delay: sliderAutoPlay } : false}
        centerInsufficientSlides={centerInsufficientSlides ? true : false}
        /* breakpoints sembra essere buggato, se da browser fai ingrandisci/rimpicciolisci la finestra varie volte
         provocando un cambio di breakpoint, alla lunga si rallenta tutto.
      */
        // breakpoints={}
        centeredSlides={centeredSlides ? true : false}
        className={classnames(
          slidesPerView === 'auto' && 'auto-width',
          slideContentCentered && 'slide-content-centered'
        )}
        direction={vertical ? 'vertical' : 'horizontal'}
        loop={loop ? true : false}
        modules={[Pagination, Navigation, Autoplay]}
        navigation={
          navigation
            ? { nextEl: `#${sliderUUID} .swiper-button-next`, prevEl: `#${sliderUUID} .swiper-button-prev` }
            : false
        }
        onAfterInit={onAfterInit}
        onSwiper={onSwiper}
        pagination={pagination ? { clickable: true } : false}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween ? spaceBetween : 0}
        speed={speed ? speed : undefined}
        watchSlidesProgress={watchSlidesProgress}
        {...(breakpoint === 'sm' && { ...responsive?.sm })}
        {...(breakpoint === 'md' && { ...responsive?.sm, ...responsive?.md })}
        {...(breakpoint === 'lg' && { ...responsive?.sm, ...responsive?.md, ...responsive?.lg })}
        {...(breakpoint === 'xl' && {
          ...responsive?.sm,
          ...responsive?.md,
          ...responsive?.lg,
          ...responsive?.xl,
        })}
        {...(breakpoint === 'xxl' && {
          ...responsive?.sm,
          ...responsive?.md,
          ...responsive?.lg,
          ...responsive?.xl,
          ...responsive?.xxl,
        })}
      >
        {children}
      </Swiper>
      {navigation && (
        <div className="slider-navigation">
          <SliderButton direction="left" size={navigationButtonSize} />
          <SliderButton direction="right" size={navigationButtonSize} />
        </div>
      )}
    </div>
  );
};
