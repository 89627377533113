import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { ReviewsActionsProps } from './ReviewsActions';

export const ReviewsActionsA = ({ onSignInButtonClick, onWriteReviewButtonClick }: ReviewsActionsProps) => {
  const { session } = useAppContext();

  return (
    <Div className="reviews-actions">
      <Div className="buttons" marginTop={3}>
        <Button
          label={t('reviews.buttons.writeReview')}
          onClick={session?.userLogged ? onWriteReviewButtonClick : onSignInButtonClick}
        />
      </Div>
    </Div>
  );
};
