// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { SearchReviewsOptions } from '@b2x/storefront-api-js-client/src/reviews';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useReviewsApi = () => {
  const { apiRequest } = useApiRequest();

  const searchReviews = React.useCallback(
    (options?: SearchReviewsOptions, config?: ApiRequestConfig) =>
      apiRequest(api.reviews.search(options), { ...config }),
    [apiRequest]
  );

  const getSummaryReviews = React.useCallback(
    (id: string, config?: ApiRequestConfig) => apiRequest(api.reviews.getSummary(id), { ...config }),
    [apiRequest]
  );

  return { getSummaryReviews, searchReviews };
};
