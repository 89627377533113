import './ProductSlider.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { ProductTile } from '../ProductTile';
import { Slider } from './Slider';

export interface ProductSliderProps {
  products: Array<b2x.ProductApiDto>;
}

export const ProductSlider = ({ products }: ProductSliderProps) => {
  return (
    <div className="product-slider position-relative">
      <b2x.EqualHeight>
        <Slider
          navigation
          responsive={{
            lg: {
              slidesPerView: 3.4,
            },
            sm: {
              slidesPerView: 2.4,
            },
            xl: {
              slidesPerView: 4,
            },
          }}
          slidesPerView={1.4}
          spaceBetween={20}
        >
          {products.map((product, index) => (
            <SwiperSlide key={product.id}>
              <ProductTile product={product} productsPerRow={products.length} />
            </SwiperSlide>
          ))}
        </Slider>
      </b2x.EqualHeight>
    </div>
  );
};
