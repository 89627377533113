import { SummaryReviewsApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';

import { useReviewsApi } from './api/useReviewsApi';
import { Button } from './Button';
import { Col } from './Col';
import { t } from './i18n/i18n';
import { RatingStars } from './reviews/RatingStars';
import { Row } from './Row';
import { percentageOf } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface SummaryReviewsPreviewProps {
  className?: string;
  productId: string;
  productSummaryReviews?: SummaryReviewsApiDto;
  scrollTo?(): void;
}

const SummaryReviewsPreview = ({
  className,
  productId,
  productSummaryReviews,
  scrollTo,
}: SummaryReviewsPreviewProps) => {
  const { getSummaryReviews } = useReviewsApi();
  const [summaryReviews, setSummaryReviews] = React.useState<SummaryReviewsApiDto | undefined>(productSummaryReviews);

  React.useEffect(() => {
    setSummaryReviews(productSummaryReviews);
  }, [productSummaryReviews]);

  React.useEffect(() => {
    let ignore = false;

    if (productSummaryReviews === undefined) {
      getSummaryReviews(productId).then((response) => {
        if (!ignore) {
          setSummaryReviews(response.data);
        }
      });
    }

    return () => {
      ignore = true;
    };
  }, [getSummaryReviews, productId, productSummaryReviews]);

  return (
    <>
      {(scrollTo || (summaryReviews && summaryReviews.totCount > 0)) && (
        <div className={classnames('reviews-summary-preview', className)}>
          {scrollTo ? (
            <Button
              className={classnames(
                { 'btn-to-review': summaryReviews && summaryReviews.totCount > 0 },
                { 'btn-review-first': summaryReviews && summaryReviews.totCount === 0 }
              )}
              onClick={scrollTo}
              variant={summaryReviews && summaryReviews.totCount > 0 ? 'blank' : undefined}
            >
              {summaryReviews && summaryReviews.totCount > 0 ? (
                <Row gap={1}>
                  <Col size={'auto'}>
                    <RatingStars rating={percentageOf(summaryReviews.average, 5, 2)} />
                  </Col>
                  <Col className="reviews-count" size={'auto'}>{`(${summaryReviews.totCount})`}</Col>
                </Row>
              ) : (
                <>{t('reviews.buttons.reviewForFirst')}</>
              )}
            </Button>
          ) : (
            summaryReviews &&
            summaryReviews.totCount > 0 && (
              <Row gap={1}>
                <Col size={'auto'}>
                  <RatingStars rating={percentageOf(summaryReviews.average, 5, 2)} />
                </Col>
                <Col className="reviews-count" size={'auto'}>{`(${summaryReviews.totCount})`}</Col>
              </Row>
            )
          )}
        </div>
      )}
    </>
  );
};

export type SummaryReviewsPreviewVariants = '';

const SummaryReviewsPreviewController = (props: PropsWithCustomComponent<SummaryReviewsPreviewProps>) => (
  <VariantsController<SummaryReviewsPreviewProps, SummaryReviewsPreviewVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: SummaryReviewsPreview,
      name: 'SummaryReviewsPreview',
    }}
  />
);
export { SummaryReviewsPreviewController as SummaryReviewsPreview };
