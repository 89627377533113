import './Header.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-mobile.svg';
import { MobileHeader } from './MobileHeader';
import { VerticalSlider } from './slider/VerticalSlider';
import { getContentSectionByCustomerType } from './util';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderSecondRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderSecondRowRef.current) {
        setHeaderHeight(desktopHeaderSecondRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderSecondRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {!headerCheckout ? (
        <>
          <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          <MobileHeader
            content={content}
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="lg"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            content={content}
            firstRowRef={desktopHeaderSecondRowRef}
            localeBoxVisible={localeBoxVisible}
            recalculateHeaderHeight={recalculateHeaderHeight}
            searchBoxVisible={searchBoxVisible}
            setActiveCategory={setActiveCategory}
            setLocaleBoxVisible={setLocaleBoxVisible}
            setSearchBoxVisible={setSearchBoxVisible}
            toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            visibleFrom="lg"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          logo={logo}
          logoSmall={logoSmall}
          mobileFrom={'md'}
          recalculateHeaderHeight={recalculateHeaderHeight}
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
}

const TopBar = ({ content, toggleLocaleBoxVisible }: TopBarProps) => {
  const { topBarRowRef } = useAppContext();
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  const { session } = b2x.useAppContext();

  const slider = React.useMemo(
    () => getContentSectionByCustomerType(content?.body.firstRow?.centerCol?.verticalSliders, session)?.slider,
    [content?.body.firstRow?.centerCol?.verticalSliders, session]
  );

  return (
    <div className={'top-bar extra-small'} ref={setTopBarRowRef}>
      <Container>
        <b2x.Row>
          <b2x.Col className="d-none d-lg-flex align-items-center" size={4}>
            <span className={content?.body.firstRow?.leftCol?.className}>
              {content?.body.firstRow?.leftCol?.cta && (
                <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.firstRow.leftCol.cta}>
                  {content.body.firstRow.leftCol.cta.icon && isIconName(content.body.firstRow.leftCol.cta.icon) && (
                    <Icon className="me-1" name={content.body.firstRow.leftCol.cta.icon} size={12} />
                  )}
                  {content.body.firstRow.leftCol.cta.label}
                </b2x.DeprecatedCta>
              )}
            </span>
          </b2x.Col>
          <b2x.Col className="d-flex align-items-center text-center" size={{ lg: 4, sm: 12 }}>
            {slider ? <VerticalSlider itemList={slider} /> : <span>{t('welcome')}</span>}
          </b2x.Col>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-end" size={4}>
            {content?.body.firstRow?.rightCol?.cta && (
              <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.firstRow.rightCol.cta}>
                {content.body.firstRow.rightCol.cta.icon && isIconName(content.body.firstRow.rightCol.cta.icon) && (
                  <Icon className="me-1" name={content.body.firstRow.rightCol.cta.icon} size={16} />
                )}
                {content.body.firstRow.rightCol.cta.label}
              </b2x.DeprecatedCta>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
