import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { ReviewsActionsA } from './ReviewsActionsA';

export interface ReviewsActionsProps {
  onSignInButtonClick(): void;
  onSignUpButtonClick(): void;
  onWriteReviewButtonClick(): void;
}

const ReviewsActions = ({
  onSignInButtonClick,
  onSignUpButtonClick,
  onWriteReviewButtonClick,
}: ReviewsActionsProps) => {
  const { session } = useAppContext();

  return (
    <Div className="reviews-actions">
      <Div className="buttons" marginTop={3}>
        {session?.userLogged ? (
          <Button label={t('reviews.buttons.writeReview')} onClick={onWriteReviewButtonClick} />
        ) : (
          <Row style={{ justifyContent: 'center' }}>
            <Col size={'auto'}>
              <Button label={t('reviews.buttons.signIn')} onClick={onSignInButtonClick} variant="outline-primary" />
            </Col>
            <Col size={'auto'}>
              <Button label={t('reviews.buttons.signUp')} onClick={onSignUpButtonClick} />
            </Col>
          </Row>
        )}
      </Div>
    </Div>
  );
};

export type ReviewsActionsVariants = 'A';

const ReviewsActionsController = (props: PropsWithCustomComponent<ReviewsActionsProps>) => (
  <VariantsController<ReviewsActionsProps, ReviewsActionsVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ReviewsActionsA },
      defaultComponent: ReviewsActions,
      name: 'ReviewsActions',
    }}
  />
);
export { ReviewsActionsController as ReviewsActions };
