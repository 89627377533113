import { ContentApiDto } from '@b2x/storefront-api-js-client/src';
import { GetContentOptions } from '@b2x/storefront-api-js-client/src/contents';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useContentsApi } from './api/useContentsApi';
import { useAppContext } from './AppContext';
import { useStable } from './util';

export interface UseContentBypath extends GetContentOptions {}

export const useContentByPath = <ContentType,>(options?: UseContentBypath, config?: ApiRequestConfig) => {
  const [content, setContent] = React.useState<ContentApiDto<ContentType>>();

  const { getContentByPath } = useContentsApi();

  const { routeInfo } = useAppContext();

  options = useStable(options);
  config = useStable(config);

  React.useEffect(() => {
    routeInfo?.pathParam &&
      getContentByPath<ContentType>(routeInfo.pathParam, options, config).then((response) => {
        setContent(response.data);
      });
  }, [getContentByPath, options, routeInfo?.pathParam, config, content]);

  return content;
};
