import React from 'react';

import { ImageContentSectionV2 } from './contentTypes';
import { Exclude2x, Image, ImageProps } from './Image';
import { AspectRatio } from './ImagePlaceholder';
import { ImageThronFromContent } from './ImageThronFromContent';
import { getItemFromSwitch } from './util';

export interface ImageFromContentPropsV2 extends ImageContentSectionV2, Omit<ImageProps, 'exclude2x' | 'src'> {}

export const ImageFromContentV2 = ({ fluid = true, provider, ...otherProps }: ImageFromContentPropsV2) => {
  const _provider = getItemFromSwitch(provider);

  const aspectRatio = React.useMemo<AspectRatio>(() => {
    const result: AspectRatio = {};

    if (_provider?.bcom?.xs?.url) {
      if (_provider.bcom.xs.width !== undefined && _provider.bcom.xs.height !== undefined) {
        result.xs = _provider.bcom.xs.width / _provider.bcom.xs.height;
      }
      if (_provider.bcom.sm?.width !== undefined && _provider.bcom.sm.height !== undefined) {
        result.sm = _provider.bcom.sm.width / _provider.bcom.sm.height;
      }
      if (_provider.bcom.md?.width !== undefined && _provider.bcom.md.height !== undefined) {
        result.md = _provider.bcom.md.width / _provider.bcom.md.height;
      }
      if (_provider.bcom.lg?.width !== undefined && _provider.bcom.lg.height !== undefined) {
        result.lg = _provider.bcom.lg.width / _provider.bcom.lg.height;
      }
      if (_provider.bcom.xl?.width !== undefined && _provider.bcom.xl.height !== undefined) {
        result.xl = _provider.bcom.xl.width / _provider.bcom.xl.height;
      }
      if (_provider.bcom.xxl?.width !== undefined && _provider.bcom.xxl.height !== undefined) {
        result.xxl = _provider.bcom.xxl.width / _provider.bcom.xxl.height;
      }
    }
    return result;
  }, [_provider]);

  const exclude2x = React.useMemo<Exclude2x>(() => {
    const result: Exclude2x = {};

    if (_provider?.bcom?.xs?.url) {
      if (_provider.bcom.xs.density !== undefined) {
        result.xs = _provider.bcom.xs.density === 1;
      } else {
        result.xs = true;
      }
      if (_provider.bcom.sm?.density !== undefined) {
        result.sm = _provider.bcom.sm.density === 1;
      } else {
        result.sm = true;
      }
      if (_provider.bcom.md?.density !== undefined) {
        result.md = _provider.bcom.md.density === 1;
      } else {
        result.md = true;
      }
      if (_provider.bcom.lg?.density !== undefined) {
        result.lg = _provider.bcom.lg.density === 1;
      } else {
        result.lg = true;
      }
      if (_provider.bcom.xl?.density !== undefined) {
        result.xl = _provider.bcom.xl.density === 1;
      } else {
        result.xl = true;
      }
      if (_provider.bcom.xxl?.density !== undefined) {
        result.xxl = _provider.bcom.xxl.density === 1;
      } else {
        result.xxl = true;
      }
    }
    return result;
  }, [_provider]);

  return (
    <>
      {_provider?.bcom?.xs?.url ? (
        <Image
          {...otherProps}
          aspectRatio={aspectRatio}
          exclude2x={exclude2x}
          fluid={fluid}
          src={{
            lg: _provider.bcom.lg?.url,
            md: _provider.bcom.md?.url,
            sm: _provider.bcom.sm?.url,
            xl: _provider.bcom.xl?.url,
            xs: _provider.bcom.xs.url,
            xxl: _provider.bcom.xxl?.url,
          }}
        />
      ) : _provider?.thron?.xs?.url ? (
        <ImageThronFromContent style={otherProps.style} {..._provider.thron} fluid={fluid} />
      ) : (
        <></>
      )}
    </>
  );
};
