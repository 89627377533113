import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { Container } from '../Container';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface LoginPageProps {}

export const LoginPage = (props: LoginPageProps) => {
  const { session } = b2x.useAppContext();
  const { getFixedPagePath } = b2x.useAppStaticContext();
  const navigate = b2x.router.useNavigate();

  React.useEffect(() => {
    if (session?.userLogged) {
      navigate(getFixedPagePath('account'));
    }
  }, [getFixedPagePath, navigate, session?.userLogged]);

  const loginContent = b2x.useContent<b2x.contentTypes.AccountOffcanvasContentType>('ACCOUNT_OFFCANVAS_CONTENT');
  //const content = useContent<AccountOffcanvasContentType>('ACCOUNT_OFFCANVAS_CONTENT');

  const handleLoginSuccess = React.useCallback(() => {
    navigate(getFixedPagePath('account'));
  }, [getFixedPagePath, navigate]);

  return (
    <Page className="login-page">
      {!session?.userLogged && (
        <Container>
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
              <div className="h-100 pb-3 pb-md-0">
                <Box fullHeight>
                  <div className="text-center">
                    <h3 className="fw-bold mb-3">{b2x.formatHtml(loginContent?.body.title)}</h3>
                    <div className="mb-4">{b2x.formatHtml(loginContent?.body.login?.body)}</div>
                  </div>
                  <b2x.LoginForm onSuccess={handleLoginSuccess} />
                </Box>
              </div>
            </b2x.Col>
            <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
              <Box fullHeight>
                <div className="mb-4">
                  <div className="text-center mb-3">
                    <h3 className="fw-bold mb-3">{b2x.formatHtml(loginContent?.body.registration?.title)}</h3>
                    <div className="mb-4">
                      {loginContent?.body.registration?.body && (
                        <p>{b2x.formatHtml(loginContent.body.registration.body)}</p>
                      )}
                    </div>
                  </div>
                  <div className="d-grid">
                    <b2x.router.Link className="btn btn-primary" to={getFixedPagePath('register')}>
                      {t('misc.register')}
                    </b2x.router.Link>
                  </div>
                </div>
                {loginContent?.body.additionalRegistrationOptions && (
                  <div className="py-2 px-1">
                    {loginContent.body.additionalRegistrationOptions.map((additionalRegistrationOption) => (
                      <div className="mt-2" key={additionalRegistrationOption.contentSectionId}>
                        {additionalRegistrationOption.cta?.to && (
                          <div className="d-flex align-items-center pb-2 mb-2 border-bottom ">
                            <div>{b2x.formatHtml(additionalRegistrationOption.title)}</div>
                            <div className="ms-auto">
                              <b2x.CtaFromContent
                                {...additionalRegistrationOption.cta}
                                ctaProps={{
                                  button: { className: 'text-decoration-underline text-promo fw-bold' },
                                  link: { className: '', closeModal: true },
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </Page>
  );
};
