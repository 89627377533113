import './AccountPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { MiscellaneousContentType } from '../contentTypes';
import { Page } from './Page';

export interface AccountPageProps extends b2x.AccountPageProps {}

export const AccountPage = (props: AccountPageProps) => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();
  const navigate = b2x.router.useNavigate();

  React.useEffect(() => {
    if (!session?.userLogged) {
      const loginPagePath = getPagePath('SITE_LOGIN');
      if (loginPagePath) {
        navigate(loginPagePath);
      }
    }
  }, [getPagePath, navigate, session?.userLogged]);

  return (
    <Page className="account-page" noPaddingTop>
      <b2x.AccountPage {...props} />
      {content?.body.accountDisclaimer && (
        <div className="container">
          <div className="col-12">
            <div className="text-center my-3 small">{b2x.formatHtml(content.body.accountDisclaimer)}</div>
          </div>
        </div>
      )}
    </Page>
  );
};
