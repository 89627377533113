import './CustomRadio.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface CustomRadioProps {
  className?: string;
  imageUrl?: string;
  isSquare?: boolean;
  label?: string;
}

export const CustomRadio = ({ className, imageUrl, isSquare, label, ...props }: CustomRadioProps) => {
  return (
    <div
      className={classnames(
        'CustomRadio bg-lighter text-nowrap',
        isSquare ? 'rectangular-custom-radio px-2 py-1 lh-lg small' : 'rounded-circle'
      )}
    >
      {imageUrl ? <b2x.Image alt="" fluid format={64} src={imageUrl} /> : label}
    </div>
  );
};
