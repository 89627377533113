import { PageApiDto, queryClient, SearchContentsApiDto } from '@b2x/storefront-api-js-client/src';
import { SearchContentsOptions } from '@b2x/storefront-api-js-client/src/contents';
import { GetPageOptions } from '@b2x/storefront-api-js-client/src/pages';
import _ from 'lodash';
import React from 'react';

import { b2x } from '.';
import { ApiRequestConfig } from './api/useApiRequest';
import { useContentsApi } from './api/useContentsApi';
import { usePagesApi } from './api/usePagesApi';
import { useLocation, useSearchParams } from './router/router';
import { storage } from './storage';
import { isFriendlySearch, useStable } from './util';

export interface UseContentsSearch extends SearchContentsOptions {
  defaultPageSize: number;
  pageOptions?: GetPageOptions;
}

export const useContentsSearch = <PageContentType, SearchContentsContentType>(
  options?: UseContentsSearch,
  config?: ApiRequestConfig
) => {
  const [searchContentsResult, setSearchContentsResult] =
    React.useState<SearchContentsApiDto<SearchContentsContentType>>();
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<PageApiDto<PageContentType>>();

  const { searchContents } = useContentsApi();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { getPage, getPageByPath } = usePagesApi();

  config = useStable(config);

  const { defaultPageSize = 20, populate, type } = options ?? {};

  const queryKey = React.useRef<Array<unknown>>();

  // Faccio partire la search ogni volta che cambia quacosa nella path
  React.useEffect(() => {
    const storedPageSize = storage.getNumber('searchPageSize');
    const pageSize = searchParams.get('pageSize') ?? undefined;
    const pageNum = searchParams.get('pageNum') ?? undefined;
    const orderBy = (searchParams.get('orderBy') as b2x.SearchContentOrderApiType | undefined) ?? undefined;
    const orderingType = (searchParams.get('orderingType') as b2x.OrderingApiType | undefined) ?? undefined;

    const searchContentsOptions: SearchContentsOptions = {
      category: searchParams.get('category') ?? undefined,
      friendlyUrl: location.pathname,
      orderBy: orderBy,
      orderingType: orderingType,
      pageNum: pageNum ? parseInt(pageNum) : undefined,
      pageSize: pageSize ? parseInt(pageSize) : storedPageSize ? storedPageSize : defaultPageSize,
      populate: populate,
      simpleSearch: searchParams.get('simpleSearch') ?? undefined,
      type: type,
    };

    const newQueryKey = ['searchContents', searchContentsOptions];
    queryKey.current &&
      !_.isEqual(queryKey.current, newQueryKey) &&
      queryClient.cancelQueries({ queryKey: queryKey.current });
    queryKey.current = newQueryKey;

    setFetching(true);
    searchContents<SearchContentsContentType>(
      queryKey.current,
      {
        ...searchContentsOptions,
      },
      config
    )
      .then((response) => {
        setSearchContentsResult(response.data);
        if (response.data.items && response.data.items.length > 0) {
          queryKey.current = ['searchContents', { ...searchContentsOptions }];

          searchContents<SearchContentsContentType>(
            queryKey.current,
            { ...searchContentsOptions },
            { ...config, silent: true }
          ).then((secondStepResponse) => {
            setSearchContentsResult((prevState) =>
              prevState ? { ...prevState, items: secondStepResponse.data.items } : undefined
            );
          });
        }
      })
      .finally(() => {
        setFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let { pageOptions } = options ?? {};

  pageOptions = useStable(pageOptions);

  // Prendo la pagina di appartenenza per mostrare un'eventuale breadcrumb
  React.useEffect(() => {
    if (isFriendlySearch(location)) {
      getPageByPath<PageContentType>(location.pathname, pageOptions).then((response) => {
        setPage(response.data);
      });
    } else if (searchParams.get('category')) {
      const singleCategoryId = searchParams.get('category');
      singleCategoryId &&
        getPage<PageContentType>(singleCategoryId, pageOptions).then((response) => {
          setPage(response.data);
        });
    } else {
      setPage(undefined);
    }
  }, [getPage, getPageByPath, location, pageOptions, searchParams]);

  return { fetching, page, searchContentsResult };
};
