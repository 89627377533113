import { ReviewApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { Div, H6 } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { formatDate, formatHtml, percentageOf } from '../util';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { RatingStars } from './RatingStars';

export interface ReviewProps {
  ratingPrecision: number;
  review: ReviewApiDto;
}

const Review = ({ ratingPrecision, review }: ReviewProps) => {
  return (
    <Div className="review">
      {review.nickname && <H6 className="name">{review.nickname}</H6>}
      {review.rating && (
        <Div alignItems={'center'} className="rating" display={'flex'}>
          <RatingStars rating={percentageOf(review.rating, 5, ratingPrecision)} />
        </Div>
      )}
      <Div className="review-info">
        {!review.orderDate && <Div className="unverified">{t('reviews.review.notVerified')}</Div>}
        {review.orderDate && (
          <Div className="purchased-on">{t('reviews.review.purchasedOn', { date: formatDate(review.orderDate) })}</Div>
        )}
        {review.date && (
          <Div className="reviewed-on">{t('reviews.review.reviewedOn', { date: formatDate(review.date) })}</Div>
        )}
      </Div>
      {review.message && <Div className="message">{formatHtml(review.message)}</Div>}
    </Div>
  );
};

export type ReviewVariants = '';

const ReviewController = (props: PropsWithCustomComponent<ReviewProps>) => (
  <VariantsController<ReviewProps, ReviewVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Review,
      name: 'Review',
    }}
  />
);
export { ReviewController as Review };
