// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetTopSuggestionsOptions } from '@b2x/storefront-api-js-client/src/suggestions';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useSuggestionsApi = () => {
  const { apiRequest } = useApiRequest();

  const getTopSuggestions = React.useCallback(
    (options: GetTopSuggestionsOptions, config?: ApiRequestConfig) =>
      apiRequest(api.suggestions.getTopSuggestions(options), config),
    [apiRequest]
  );

  return {
    getTopSuggestions,
  };
};
