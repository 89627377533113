import { useAppContext } from './AppContext';
import { Button } from './Button';
import { Col } from './Col';
import { Div, H3, H4, H5 } from './HTMLElement';
import { t } from './i18n/i18n';
import { ProductReviewsProps } from './ProductReviews';
import { RatingDistribution } from './reviews/RatingDistribution';
import { RatingStars } from './reviews/RatingStars';
import { ReviewItemList } from './reviews/ReviewItemList';
import { ReviewsActions } from './reviews/ReviewsActions';
import { ReviewsFilters } from './reviews/ReviewsFilter';
import { Row } from './Row';
import { useProductReviews } from './useProductReviews ';
import { percentageOf } from './util';

export const ProductReviewsA = ({
  onSignInButtonClick,
  product,
  ratingPrecision = 2,
  reviewPerPage = 3,
}: ProductReviewsProps) => {
  const {
    onChangeFilter,
    onShowMoreButtonClick,
    onSignUpButtonClick,
    onWriteReviewButtonClick,
    reviews,
    showMore,
    summaryReviews,
  } = useProductReviews(product, reviewPerPage);

  const { session } = useAppContext();

  return (
    <Div className="reviews">
      {product.reviews && product.reviews.length > 0 ? (
        <Row style={{ justifyContent: 'space-between' }}>
          <Col size={12}>
            <Div display={'flex'} justifyContent={'spaceBetween'} marginBottom={4}>
              <Div>
                <H3 className="title" margin={0}>
                  {t('reviews.titles.withReviwes')}
                </H3>
              </Div>
              <Div display={{ md: 'block', xs: 'none' }}>
                <ReviewsFilters onChangeFilter={onChangeFilter} />
              </Div>
            </Div>
          </Col>
          {summaryReviews && (
            <Col size={{ md: 'auto', xs: 12 }}>
              <Div className="summary" marginBottom={{ lg: 0, xs: 4 }}>
                <Div className="summary-info" marginBottom={4} padding={{ lg: 5, xs: 4 }}>
                  <H4 className="average" dynamicMargin={{ bottom: 2 }}>
                    {summaryReviews.average.toFixed(ratingPrecision)}
                  </H4>
                  <Div dynamicMargin={{ bottom: 2 }}>
                    <RatingStars rating={percentageOf(summaryReviews.average, 5, ratingPrecision)} />
                  </Div>
                  <Div dynamicMargin={{ bottom: 2 }}>
                    {summaryReviews.totCount > 0 && (
                      <div className="reviews-count">
                        {t('reviews.misc.review', { count: summaryReviews.totCount })}
                      </div>
                    )}
                  </Div>
                  {summaryReviews.voteDistribution && (
                    <Div>
                      <RatingDistribution
                        hiddenCount
                        hiddenPercentage
                        progressBarHeight={12}
                        ratingCount={summaryReviews.totCount}
                        ratingDistribution={summaryReviews.voteDistribution}
                        variant="star"
                      />
                    </Div>
                  )}
                </Div>
                <Div className="summary-action" padding={4}>
                  <H5 marginBottom={3}>{t('reviews.messages.withReviwes')}</H5>
                  <p style={{ marginBottom: 0 }}>
                    {session?.userLogged ? t('reviews.messages.customer') : t('reviews.messages.guest')}
                  </p>
                  <ReviewsActions
                    onSignInButtonClick={onSignInButtonClick}
                    onSignUpButtonClick={onSignUpButtonClick}
                    onWriteReviewButtonClick={onWriteReviewButtonClick}
                  />
                </Div>
              </Div>
            </Col>
          )}
          <Col uiClassName={{ bs5: 'd-block d-md-none' }}>
            <Div marginBottom={4}>
              <ReviewsFilters onChangeFilter={onChangeFilter} />
            </Div>
          </Col>
          <Col size={{ md: '', xs: 12 }}>
            <Div dynamicPadding={{ start: { xl: 5 } }} marginBottom={4}>
              <ReviewItemList ratingPrecision={ratingPrecision} reviews={reviews} />
              {showMore && (
                <Button
                  label={t('reviews.buttons.showMore')}
                  onClick={onShowMoreButtonClick}
                  variant="outline-primary"
                />
              )}
            </Div>
          </Col>
        </Row>
      ) : (
        <Div textAlign={'center'}>
          <H3 className="title" marginBottom={3}>
            {t('reviews.titles.withoutReviews')}
          </H3>
          <p style={{ marginBottom: 0 }}>{t('reviews.messages.withoutReviews')}</p>
          <p style={{ marginBottom: 0 }}>
            {session?.userLogged ? t('reviews.messages.customer') : t('reviews.messages.guest')}
          </p>
          <ReviewsActions
            onSignInButtonClick={onSignInButtonClick}
            onSignUpButtonClick={onSignUpButtonClick}
            onWriteReviewButtonClick={onWriteReviewButtonClick}
          />
        </Div>
      )}
    </Div>
  );
};
