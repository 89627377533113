import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { InviteFriendPageContentType } from '../contentTypes';
import { ReferralBanner } from '../ReferralBanner';
import { Page } from './Page';

export const InviteFriendPage = () => {
  const page = b2x.usePage<InviteFriendPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const body = page?.content?.body;
  const referralBox = body?.referralBox;

  return (
    <Page className="invite-friend-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <ReferralBanner {...body?.banner} />
      <Container>
        <b2x.Div marginY={{ lg: 5, xs: 0 }}>
          <b2x.ReferralBox {...referralBox} />
        </b2x.Div>
      </Container>
    </Page>
  );
};
