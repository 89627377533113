import { b2x } from '@b2x/react/src';

import { t } from '../i18n/i18n';

export interface VetOccupationOptionsProps {}

export const VetOccupationOptions = (props: VetOccupationOptionsProps) => {
  return (
    <>
      <b2x.Option label={t('misc.vet.occupation.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.vet.occupation.options.hoUnaStruttura')} value={'hounastruttura'} />
      <b2x.Option
        label={t('misc.vet.occupation.options.lavoroPressoUnaStruttura')}
        value={'lavoropressounastruttura'}
      />
      <b2x.Option label={t('misc.vet.occupation.options.sonoUnFreeLance')} value={'sonounfreelance'} />
      <b2x.Option label={t('misc.vet.occupation.options.altro')} value={'altro'} />
    </>
  );
};

export interface EmployeeCompanyOptionsProps {}

export const EmployeeCompanyOptions = (props: EmployeeCompanyOptionsProps) => {
  return (
    <>
      <b2x.Option label={t('misc.employee.company.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.employee.company.options.nestle')} value={'Nestle'} />
      <b2x.Option label={t('misc.employee.company.options.sanpellegrino')} value={'Sanpellegrino'} />
      <b2x.Option label={t('misc.employee.company.options.purina')} value={'Purina'} />
      <b2x.Option label={t('misc.employee.company.options.nespresso')} value={'Nespresso'} />
      <b2x.Option label={t('misc.employee.company.options.nestleShop')} value={'Nestleshop'} />
      <b2x.Option label={t('misc.employee.company.options.ngs')} value={'Ngs'} />
    </>
  );
};

export interface PetSizeCodeOptionsProps {}

export const PetSizeCodeOptions = (props: PetSizeCodeOptionsProps) => {
  return (
    <>
      <b2x.Option label={t('misc.PET_SIZE_CODE.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_SIZE_CODE.options.small')} value={'Piccola'} />
      <b2x.Option label={t('misc.PET_SIZE_CODE.options.medium')} value={'Media'} />
      <b2x.Option label={t('misc.PET_SIZE_CODE.options.large')} value={'Grande'} />
    </>
  );
};

export interface PetNutritionOptionsProps {}

export const PetNutritionOptions = (props: PetNutritionOptionsProps) => {
  return (
    <>
      <b2x.Option label={t('misc.PET_NUTRITION.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_NUTRITION.options.wet')} value={'Umido'} />
      <b2x.Option label={t('misc.PET_NUTRITION.options.dry')} value={'Secco'} />
      <b2x.Option label={t('misc.PET_NUTRITION.options.both')} value={'Entrambi'} />
      <b2x.Option label={t('misc.PET_NUTRITION.options.other')} value={'Altro'} />
    </>
  );
};

export interface PetWetFoodBrandOptionsProps {
  type: 'dog' | 'cat';
}

export const PetWetFoodBrandOptions = ({ type }: PetWetFoodBrandOptionsProps) => {
  return type === 'dog' ? (
    <>
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.adventuros')} value={'Adventuros'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.deliBakie')} value={'DeliBakie'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.dentalife')} value={'Dentalife'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.friskies')} value={'Friskies'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.purinaOne')} value={'Purina ONE'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.purinaProPlan')} value={'Purina PRO PLAN'} />
      <b2x.Option
        label={t('misc.PET_WET_FOOD_BRAND.options.purinaProPlanVeterinariDiets')}
        value={'Purina PRO PLAN Veterinari Diets'}
      />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.tonusDogChow')} value={'Tonus Dog Chow'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.beyond')} value={'Beyond'} />
    </>
  ) : (
    <>
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.catChow')} value={'CatChow'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.felix')} value={'Felix'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.friskies')} value={'Friskies'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.gourmet')} value={'Gourmet'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.purinaOne')} value={'Purina ONE'} />
      <b2x.Option label={t('misc.PET_WET_FOOD_BRAND.options.purinaProPlan')} value={'Purina PRO PLAN'} />
      <b2x.Option
        label={t('misc.PET_WET_FOOD_BRAND.options.purinaProPlanVeterinariDiets')}
        value={'Purina PRO PLAN Veterinari Diets'}
      />
    </>
  );
};

export interface PetBreedOptionsProps {
  type: 'dog' | 'cat';
}

export const PetBreedOptions = ({ type }: PetBreedOptionsProps) => {
  return type === 'dog' ? (
    <>
      <b2x.Option label={t('misc.PET_BREED.dog.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneNonDiRazza')} value="Cane non di razza" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.affenpinscher')} value="Affenpinscher" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.aidi')} value="Aidi" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.ainuKen')} value="Ainu-Ken" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.airedaleTerrier')} value="Airedale terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.akitaInu')} value="Akita Inu" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.alano')} value="Alano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.alaskanMalamute')} value="Alaskan Malamute" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.alpenlaendischeDachsbracke')}
        value="Alpenlaendische Dachsbracke"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.altraRazza')} value="Altra razza" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.americanStaffordshireTerrierr')}
        value="American Staffordshire Terrierr"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.americanWaterSpaniel')} value="American Water Spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.aneDaFermaTedesco')} value="ane da Ferma Tedesco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.aneDaMontagnaDeiPirenei')} value="ane da Montagna dei Pirenei" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.ariegeois')} value="Ariegeois" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.australianSilkyTerrier')} value="Australian Silky Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.australianTerrier')} value="Australian Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.azawakh')} value="Azawakh" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bBobtail')} value="Bobtail" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.barbet')} value="Barbet" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.barbone')} value="Barbone" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.barboneNano')} value="Barbone Nano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.basenji')} value="Basenji" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassetArtesienNormand')} value="Basset Artesien Normand" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassetBleuDaGascogne')} value="Basset Bleu da Gascogne" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassetFauveDaBretagne')} value="Basset Fauve da Bretagne" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassetGriffonVendeen')} value="Basset Griffon Vendeen" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassetHound')} value="Basset-Hound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bassotto')} value="Bassotto" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.beagle')} value="Beagle" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.beardedCollie')} value="Bearded Collie" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.beauceron')} value="Beauceron" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bedlingtonTerrier')} value="Bedlington Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bichonAvanese')} value="Bichon Avanese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bichonFrise')} value="Bichon Frise" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.billy')} value="Billy" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.blackAndTanCoonhound')} value="Black and Tan Coonhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.blackAndTanToyTerrier')} value="Black and Tan Toy Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bleuDeGascogne')} value="Bleu de gascogne" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.bloodhoundChienDeStHubert')}
        value="Bloodhound - Chien de St. Hubert"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bluetickCoonhound')} value="Bluetick Coonhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bolognese')} value="Bolognese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.borderCollie')} value="Border Collie" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.borderTerrier')} value="Border Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.borzoi')} value="Borzoi" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bostonTerrier')} value="Boston Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bouledogueFrancese')} value="Bouledogue Francese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bovaroDelleArdenne')} value="Bovaro delle Ardenne" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bovaroDelleFiandre')} value="Bovaro delle Fiandre" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bovaroSvizzero')} value="Bovaro svizzero" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.boxer')} value="Boxer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.boykinSpaniel')} value="Boykin spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.brabantino')} value="Brabantino" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.braccoDiBurgos')} value="Bracco di Burgos" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.braccoFrancese')} value="Bracco francese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.braccoItaliano')} value="Bracco Italiano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.braccoTedesco')} value="Bracco tedesco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.braccoUngherese')} value="Bracco Ungherese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.broholmer')} value="Broholmer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bullTerrier')} value="Bull Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bulldogAmericano')} value="Bulldog americano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bulldogInglese')} value="Bulldog Inglese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.bullmastiff')} value="Bullmastiff" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.cairnTerrier')} value="Cairn Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.canaanDog')} value="Canaan Dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDaFermaBoemo')} value="Cane da Ferma Boemo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDaFermaSlovacco')} value="Cane da Ferma Slovacco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDaOrsoDellaCarelia')} value="Cane da Orso della Carelia" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDeiPirenei')} value="Cane dei pirenei" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDellAtlas')} value="Cane dell'Atlas" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDellaSerraDiEstrela')} value="Cane della Serra di Estrela" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneDiCastroLaboreiro')} value="Cane di Castro Laboreiro" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneImperialeCinese')} value="Cane imperiale cinese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneLupoCecoslovacco')} value="Cane Lupo Cecoslovacco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneLupoDiSaarloos')} value="Cane Lupo di Saarloos" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneNudo')} value="Cane Nudo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caneTosa')} value="Cane Tosa" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caoDeAguaPortugues')} value="Cao de Agua Portugues" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caoDeCastroLaboreiro')} value="Cao de castro laboreiro" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caoDeSerraDaEstrela')} value="Cao de serra da estrela" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.caoDeSerraDeAires')} value="Cao de Serra de Aires" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.carlino')} value="Carlino" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.catahoulaLeopardDog')} value="Catahoula leopard dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.cattleDog')} value="Cattle Dog" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.cavalierKingCharlesSpaniel')}
        value="Cavalier King Charles Spaniel"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.ceskyFousek')} value="Cesky fousek" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.ceskyTerrier')} value="Cesky Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chartPolsky')} value="Chart polsky" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chesapeakeBayRetriever')} value="Chesapeake Bay Retriever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chienDArtois')} value="Chien d'Artois" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chienFranAsais')} value="Chien FranÃ§ais" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chienFrancais')} value="Chien francais" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chihuahua')} value="Chihuahua" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chineseCrestedDog')} value="Chinese Crested Dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chineseTempleDog')} value="Chinese Temple Dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.chowChow')} value="Chow Chow" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.cirnecoDellEtna')} value="Cirneco dell'Etna" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.clumberSpaniel')} value="Clumber Spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.cockerSpaniel')} value="Cocker Spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.coonhound')} value="Coonhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.corgiGallese')} value="Corgi gallese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.corso')} value="Corso" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.cotonDeTulear')} value="Coton de Tulear" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.curlyCoatedRetriever')} value="Curly-Coated Retriever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dalmata')} value="Dalmata" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dandieDinmontTerrier')} value="Dandie Dinmont Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.deerhound')} value="Deerhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.deutscherJagdTerrier')} value="Deutscher Jagd Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dobermann')} value="Dobermann" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dogoArgentino')} value="Dogo Argentino" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dogueDeBordeaux')} value="Dogue de Bordeaux" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.drahthaar')} value="Drahthaar" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.drentsePatrijshond')} value="Drentse Patrijshond" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.drever')} value="Drever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.dunker')} value="Dunker" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.epagneulFrancese')} value="Epagneul francese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.epagneulNanoContinentale')} value="Epagneul Nano Continentale" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.erdelyiKopo')} value="Erdelyi kopo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.esquimeseEskimoDog')} value="Esquimese - eskimo dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.eurasier')} value="Eurasier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.fieldSpaniel')} value="Field Spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.filaBrasileiro')} value="Fila Brasileiro" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.flatCoatedRetriever')} value="Flat-Coated Retriever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.foxTerrier')} value="Fox Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.foxhound')} value="Foxhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.gammelDanskHonssehund')} value="Gammel dansk honssehund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.germanSpaniel')} value="German Spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.glenOfImaalTerrier')} value="Glen of Imaal Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.goldenRetriever')} value="Golden Retriever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.grahund')} value="Grahund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.grandAngloFrancais')} value="Grand Anglo-Francais" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.grandBleuDeGascogne')} value="Grand Bleu de Gascogne" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.grandGascogneSaintongeois')}
        value="Grand Gascogne-Saintongeois"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.grausterMunsterlander')} value="Grauster munsterlander" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.griffoncino')} value="Griffoncino" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.griffone')} value="Griffone" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.groenlandese')} value="Groenlandese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.haldenstover')} value="Haldenstover" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.hamiltonstovare')} value="Hamiltonstovare" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.harlekinpinscher')} value="Harlekinpinscher" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.harrierDelSomerset')} value="Harrier del somerset" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.hokkaidoken')} value="Hokkaidoken" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.hovawart')} value="Hovawart" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.hygenhund')} value="Hygenhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.icelandDog')} value="Iceland dog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.icelandSheepdog')} value="Iceland Sheepdog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.irishTerrier')} value="Irish terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.irishWaterSpaniel')} value="Irish water spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.irishWolfhound')} value="Irish wolfhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.jamthund')} value="Jamthund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.juraLaufhund')} value="Jura laufhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.keeshond')} value="Keeshond" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.kelpie')} value="Kelpie" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.komondor')} value="Komondor" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.kromfohrlander')} value="Kromfohrlander" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.kurzhaar')} value="Kurzhaar" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.kuvasz')} value="Kuvasz" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.kyushu')} value="Kyushu" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.labradorRetriever')} value="Labrador Retriever" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lagottoRomagnolo')} value="Lagotto Romagnolo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.laicaDellaSiberia')} value="Laica della siberia" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lakelandTerrier')} value="Lakeland Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.landseer')} value="Landseer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.langhaar')} value="Langhaar" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lapinporokoira')} value="Lapinporokoira" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lapphund')} value="Lapphund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.leonberger')} value="Leonberger" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.levesque')} value="Levesque" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.levriero')} value="Levriero" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lhasaApso')} value="Lhasa apso" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.lunderhund')} value="Lunderhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.magyarAgar')} value="Magyar agar" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.maltese')} value="Maltese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.manchesterTerrier')} value="Manchester terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.mastiff')} value="Mastiff" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.mastinoNapoletano')} value="Mastino Napoletano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.mastinoSpagnolo')} value="Mastino spagnolo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.mudi')} value="Mudi" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.norfolkTerrier')} value="Norfolk Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.norrbottenspets')} value="Norrbottenspets" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.norskBuhund')} value="Norsk buhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.norskElghund')} value="Norsk elghund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.norwichTerrier')} value="Norwich Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.ogarPolski')} value="Ogar polski" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.otterhound')} value="Otterhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreAustraliano')} value="Pastore Australiano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreBelga')} value="Pastore Belga" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreBergamasco')} value="Pastore Bergamasco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreCatalano')} value="Pastore Catalano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreCroato')} value="Pastore Croato" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDeiPirenei')} value="Pastore dei Pirenei" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDeiTatra')} value="Pastore dei Tatra" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDelCaucaso')} value="Pastore del Caucaso" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDellAnatolia')} value="Pastore dell'Anatolia" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDellAsiaCentrale')} value="Pastore dell'Asia Centrale" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDellaBrie')} value="Pastore della Brie" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDellaPiccardia')} value="Pastore della Piccardia" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDelleShetland')} value="Pastore delle Shetland" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDiKarst')} value="Pastore di Karst" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDiPiccardia')} value="Pastore di piccardia" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDiTatra')} value="Pastore di tatra" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreDiVallee')} value="Pastore di Vallee" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreItaliano')} value="Pastore italiano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreMallorquin')} value="Pastore Mallorquin" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.pastoreMaremmanoAbruzzese')}
        value="Pastore Maremmano-Abruzzese"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreOlandese')} value="Pastore Olandese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreScozzese')} value="Pastore Scozzese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreSvizzeroBianco')} value="Pastore svizzero bianco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pastoreTedesco')} value="Pastore Tedesco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pechinese')} value="Pechinese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.perdiguero')} value="Perdiguero" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.petitAngloFrancais')} value="Petit anglo francais" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pharaonHound')} value="Pharaon Hound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.piccoloCaneLeone')} value="Piccolo Cane Leone" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pinscher')} value="Pinscher" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.plottHound')} value="Plott Hound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.podenco')} value="Podenco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.podengo')} value="Podengo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pointer')} value="Pointer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.poitevin')} value="Poitevin" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.porcelaine')} value="Porcelaine" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pudelpointer')} value="Pudelpointer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.puli')} value="Puli" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.pumi')} value="Pumi" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.rafeiroDoAlentejo')} value="Rafeiro do alentejo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.rastreadorBrasileiro')} value="Rastreador brasileiro" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.reyhound')} value="reyhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.rhodesianRidgeback')} value="Rhodesian ridgeback" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.rottweiler')} value="Rottweiler" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sabuesoEsanol')} value="Sabueso esanol" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.samoiedo')} value="Samoiedo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sanBernardo')} value="San Bernardo" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sanshu')} value="Sanshu" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sarplaninac')} value="Sarplaninac" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.schapendoes')} value="Schapendoes" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.schillerstovare')} value="Schillerstovare" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.schipperkee')} value="Schipperkee" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.schnauzer')} value="Schnauzer" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.schweizerLaufhund')} value="Schweizer laufhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.scottishTerrier')} value="Scottish Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sealyhamTerrier')} value="Sealyham terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.segugio')} value="Segugio" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.setterGordon')} value="Setter gordon" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.setterIrlandese')} value="Setter irlandese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sharPei')} value="Shar Pei" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.shetlandSheepdog')} value="Shetland Sheepdog" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.shibaInu')} value="Shiba Inu" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.shihTzu')} value="Shih-Tzu" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.siberianHusky')} value="Siberian Husky" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.silkyTerrier')} value="Silky Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.skyeTerrier')} value="Skye Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.slovenskyKopov')} value="Slovensky Kopov" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.smalandsstovare')} value="Smalandsstovare" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.softCoatedWheatenTerrier')}
        value="Soft coated wheaten terrier"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.spanielGiapponeseTchin')} value="Spaniel giapponese - tchin" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.spanielTedesco')} value="Spaniel tedesco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.spinoneItaliano')} value="Spinone Italiano" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.spitz')} value="Spitz" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.stabyhound')} value="Stabyhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.staffordshireBullTerrier')} value="Staffordshire Bull Terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.steinbracke')} value="Steinbracke" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.stichelhaar')} value="Stichelhaar" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.sussexSpaniel')} value="Sussex spaniel" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.tchiornyTerrierTerrierNeroRusso')}
        value="Tchiorny terrier - terrier nero russo"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tchouvatchSlovacco')} value="Tchouvatch slovacco" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.telomian')} value="Telomian" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tennesseeTreeingBrindle')} value="Tennessee Treeing Brindle" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.terranova')} value="Terranova" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.terrierBrasileiro')} value="Terrier brasileiro" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.terrierGiapponese')} value="Terrier Giapponese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.thailandRidgeback')} value="Thailand Ridgeback" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tibetanMastiff')} value="Tibetan Mastiff" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tibetanSpaniel')} value="Tibetan spaniel" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tibetanTerrier')} value="Tibetan terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.tirolerBracke')} value="Tiroler bracke" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.toyTerrier')} value="Toy terrier" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.treeingWalkerCoonhound')} value="Treeing Walker Coonhound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.triggHound')} value="Trigg Hound" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.vastgotaspets')} value="Vastgotaspets" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.viszla')} value="Viszla" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.volpino')} value="Volpino" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.volpinoFinlandese')} value="Volpino finlandese" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.wachtelhund')} value="Wachtelhund" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.weimaraner')} value="Weimaraner" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.welshTerrier')} value="Welsh Terrier" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.westHighlandWhiteTerrier')}
        value="West highland white terrier"
      />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.wetterhounSpanielOlandese')}
        value="Wetterhoun - spaniel olandese"
      />
      <b2x.Option label={t('misc.PET_BREED.dog.options.whippet')} value="Whippet" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.wolfspitz')} value="Wolfspitz" />
      <b2x.Option label={t('misc.PET_BREED.dog.options.yorkshireTerrier')} value="Yorkshire Terrier" />
      <b2x.Option
        label={t('misc.PET_BREED.dog.options.zwergpinscherPinscherNano')}
        value="Zwergpinscher - Pinscher nano"
      />
    </>
  ) : (
    <>
      <b2x.Option label={t('misc.PET_BREED.cat.options.selectAnOption')} value={''} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.gattoNonDiRazza')} value={'Gatto non di razza'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.abissino')} value={'Abissino'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.americanCurl')} value={'American Curl'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.americanWirehair')} value={'American Wirehair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.americanoAPeloCorto')} value={'Americano a pelo corto'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.angoraTurco')} value={'Angora turco'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.asian')} value={'Asian'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.australianMist')} value={'Australian Mist'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.balinese')} value={'Balinese'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.bengala')} value={'Bengala'} />
      <b2x.Option
        label={t('misc.PET_BREED.cat.options.bengalaLeopardoDelleNevi')}
        value={'Bengala-Leopardo delle nevi'}
      />
      <b2x.Option label={t('misc.PET_BREED.cat.options.birmano')} value={'Birmano'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.bluDiRussia')} value={'Blu di Russia'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.bobtailAmericano')} value={'Bobtail Americano'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.bombay')} value={'Bombay'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.britishLonghair')} value={'British Longhair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.britishShorthair')} value={'British Shorthair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.burmese')} value={'Burmese'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.burmilla')} value={'Burmilla'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.californiaSpangled')} value={'California Spangled'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.certosino')} value={'Certosino'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.chantilly')} value={'Chantilly (o Tiffany)'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.chausie')} value={'Chausie'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.chinchilla')} value={'Chinchilla'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.colorpointShorthair')} value={'Colorpoint Shorthair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.cornishRex')} value={'Cornish Rex'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.cymric')} value={'Cymric'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.devonRex')} value={'Devon Rex'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.egyptianMau')} value={'Egyptian Mau'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.exoticShorthair')} value={'Exotic Shorthair'} />
      <b2x.Option
        label={t('misc.PET_BREED.cat.options.gattoDelleForesteNorvegesi')}
        value={'Gatto delle foreste norvegesi'}
      />
      <b2x.Option label={t('misc.PET_BREED.cat.options.gattoEuropeo')} value={'Gatto europeo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.gattoSacroDiBirmania')} value={'Gatto Sacro di Birmania'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.german')} value={'German'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.germanRex')} value={'German Rex'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.havanaBrown')} value={'Havana Brown'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.himalayano')} value={'Himalayano'} />
      <b2x.Option
        label={t('misc.PET_BREED.cat.options.japaneseBobtailAPeloCorto')}
        value={'Japanese Bobtail a pelo corto'}
      />
      <b2x.Option
        label={t('misc.PET_BREED.cat.options.japaneseBobtailAPeloLungo')}
        value={'Japanese Bobtail a pelo lungo'}
      />
      <b2x.Option label={t('misc.PET_BREED.cat.options.khaoManee')} value={'Khao Manee'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.korat')} value={'Korat'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.kurilianBobtail')} value={'Kurilian Bobtail'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.laPermAPeloCorto')} value={'LaPerm a pelo corto'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.laPermAPeloLungo')} value={'LaPerm a pelo lungo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.maineCoon')} value={'Maine Coon'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.manx')} value={'Manx'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.munchkin')} value={'Munchkin'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.nebelung')} value={'Nebelung'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.ocicat')} value={'Ocicat'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.orientaleAPeloCorto')} value={'Orientale a pelo corto'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.orientaleAPeloLungo')} value={'Orientale a pelo lungo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.persianoAPeloLungo')} value={'Persiano a pelo lungo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.peterbald')} value={'Peterbald'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.pixieBobAPeloCorto')} value={'Pixie Bob a pelo corto'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.pixieBobAPeloLungo')} value={'Pixie Bob a pelo lungo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.ragamuffin')} value={'Ragamuffin'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.ragdoll')} value={'Ragdoll'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.savannah')} value={'Savannah'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.scottishFold')} value={'Scottish Fold'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.selkirkRexLonghair')} value={'Selkirk Rex Longhair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.selkirkRexShorthair')} value={'Selkirk Rex Shorthair'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.siamese')} value={'Siamese'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.siberiano')} value={'Siberiano'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.singapura')} value={'Singapura'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.snowshoe')} value={'Snowshoe'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.sokoke')} value={'Sokoke'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.somalo')} value={'Somalo'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.sphynx')} value={'Sphynx'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.thai')} value={'Thai'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.tonkinese')} value={'Tonkinese'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.toyger')} value={'Toyger'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.turcoVan')} value={'Turco Van'} />
      <b2x.Option label={t('misc.PET_BREED.cat.options.yorkChocolate')} value={'York Chocolate'} />
    </>
  );
};
