import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { RegistrationFormContentType, RegistrationPageContentType } from '../contentTypes';
import { ReferralBanner } from '../ReferralBanner';
import { Page } from './Page';

export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');
  const page = b2x.usePage<RegistrationPageContentType>();

  const [params] = b2x.router.useSearchParams();
  const referralCode = params.get('referralCode');
  const isReferralRegistration = !!referralCode;

  return (
    <Page noPaddingTop={isReferralRegistration} thingsToLoadBeforePageReady={[content]}>
      {isReferralRegistration && (
        <b2x.Div marginBottom={5}>
          <ReferralBanner {...page?.content?.body.referralBanner} />
        </b2x.Div>
      )}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <Box>
              {isReferralRegistration && (
                <b2x.ReferralBoxUI {...page?.content?.body.referralBox} hideBorder padding={0} />
              )}
              <div className="mb-4 text-center">
                <h1 className="h2 fw-bold mb-2">{b2x.formatHtml(content?.body.title)}</h1>
                <p className="mb-0">{b2x.formatHtml(content?.body.subTitle)}</p>
              </div>
              <b2x.CustomerForm
                className="mb-4"
                minAge={18}
                referralCode={referralCode || undefined}
                successfulRegistrationModalProps={{
                  children: b2x.formatHtml(content?.body.modal?.content),
                  title: content?.body.modal?.title,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
