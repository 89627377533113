import './ProductReviews.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Section } from './Section';

export interface ProductReviewsProps {
  innerRef?: React.RefObject<HTMLDivElement>;
  product: b2x.ProductApiDto;
}

export const ProductReviews = ({ innerRef, product }: ProductReviewsProps) => {
  const { showAccountOffcanvas } = useAppStaticContext();

  return (
    <div ref={innerRef}>
      <Section className="bg-gray-100 py-5 mb-0">
        <b2x.ProductReviews onSignInButtonClick={showAccountOffcanvas} product={product} />
      </Section>
    </div>
  );
};
