import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export const DoubleOptInOkPage = () => {
  return (
    <Page>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <Box>
              <div className="mb-4">
                <h2 className="text-primary text-center mb-3">{t('misc.doubleOptIn.title')}</h2>
                <p>{t('misc.doubleOptIn.body')} </p>
              </div>
              <div className="d-grid">
                <b2x.Cta button={{ label: t('misc.backToHome') }} href="/" />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
