import React from 'react';

import { Select } from '../form/fields/Select';
import { FormGroup } from '../form/FormGroup';
import { t } from '../i18n/i18n';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';

export interface ReviewsFiltersProps {
  onChangeFilter(rating?: number | undefined): void;
}

const ReviewsFilters = ({ onChangeFilter }: ReviewsFiltersProps) => {
  const handleFilterChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      Number.isInteger(Number(event.target.value))
        ? onChangeFilter(Number(event.target.value))
        : onChangeFilter(undefined);
    },
    [onChangeFilter]
  );

  return (
    <FormGroup label={undefined} names={['filter-by']}>
      <Select
        includeEmptyOption={false}
        name="filter-by"
        onChange={handleFilterChange}
        placeholder={t('reviews.misc.filterBy')}
      >
        {[...Array(5)].map((e, i) => (
          <option key={`filter-by-${i + 1}`} value={i + 1}>
            {`${i + 1} ${t('reviews.misc.star', { count: i + 1 })}`}
          </option>
        ))}
      </Select>
    </FormGroup>
  );
};

export type ReviewsFiltersVariants = '';

const ReviewsFiltersController = (props: PropsWithCustomComponent<ReviewsFiltersProps>) => (
  <VariantsController<ReviewsFiltersProps, ReviewsFiltersVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ReviewsFilters,
      name: 'ReviewsFilters',
    }}
  />
);
export { ReviewsFiltersController as ReviewsFilters };
