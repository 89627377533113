import { b2x } from '@b2x/react/src';

export const customerIsVet = (qualifiers: Array<string>) => qualifiers.includes('Vet');

export const customerIsEmployee = (qualifiers: Array<string>) => qualifiers.includes('Employee');

// Mostro il primo contentSection compatibile con la qualifica del customer, altrimenti in alternativa prendo quello visibile a tutti.
export const getContentSectionByCustomerType = <T extends { visibility?: string }>(
  items?: Array<T>,
  session?: b2x.SessionApiDto
) =>
  items?.find(
    ({ visibility }) =>
      session?.customer?.qualifiers &&
      ((visibility === 'vet' && customerIsVet(session.customer.qualifiers)) ||
        (visibility === 'employee' && customerIsEmployee(session.customer.qualifiers)))
  ) ?? items?.find(({ visibility }) => visibility === 'all');

export const deepFilterMenuByCustomerType = (menu: b2x.MenuApiDto, session?: b2x.SessionApiDto) => {
  const filteredMenu = b2x.deepFilterMenu(
    menu,
    (child: b2x.MenuApiDto) =>
      !(
        session?.customer?.qualifiers &&
        customerIsVet(session.customer.qualifiers) &&
        child.tags?.includes('no-vet')
      ) &&
      !(
        session?.customer?.qualifiers &&
        customerIsEmployee(session.customer.qualifiers) &&
        child.tags?.includes('no-employee')
      )
  );
  return filteredMenu;
};
