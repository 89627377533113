import { b2x } from '@b2x/react/src';

import { ErrorPageContentType } from '../contentTypes';
import { MessageBoxPage } from './MessageBoxPage';
export interface ErrorPageProps {
  contentCode: string;
}

export const ErrorPage = ({ contentCode }: ErrorPageProps) => {
  const content = b2x.useContent<ErrorPageContentType>(contentCode);
  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-end">
      <div className="px-lg-5 px-3 py-5">
        {content?.body.title && <h1 className="h3 fw-bold mb-3">{b2x.formatHtml(content.body.title)}</h1>}
        {content?.body.subTitle && <h2 className="h4 fw-bold mb-3">{b2x.formatHtml(content.body.subTitle)}</h2>}
        {content?.body.content && <p className="mb-3">{b2x.formatHtml(content.body.content)}</p>}
        {content?.body.cta?.label && (
          <b2x.DeprecatedCta className={`btn btn-${content.body.cta.variant}`} cta={content.body.cta}>
            {content.body.cta.label}
          </b2x.DeprecatedCta>
        )}
      </div>
    </MessageBoxPage>
  );
};
